import DebitSvg from 'src/assets/icons/transfer.svg'
import ExchangeColorSvg from 'src/assets/icons/exchange-color.svg'
import ExchangeSvg from 'src/assets/icons/exchange.svg'
import React, { HTMLProps } from 'react'
import ReceiptSvg from 'src/assets/icons/receipt.svg'
import styled from 'astroturf/react'

const iconList = {
    debit: <DebitSvg height={16} />,
    receipt: <ReceiptSvg height={16} />,
    exchange: <ExchangeColorSvg height={16} />,
    'exchange-no-color': <ExchangeSvg height={16} />,
}

interface Props extends HTMLProps<HTMLDivElement> {
    size?: number
    icon?: 'debit' | 'receipt' | 'exchange' | 'exchange-no-color'
    lightTheme?: boolean
    noBg?: boolean
}

const RoundIcon: React.FC<Props> = ({ children, size = 32, icon, lightTheme, ...rest }) => {
    return (
        <Container
            {...rest}
            greenIcon={icon === 'receipt'}
            blueIcon={icon !== 'receipt' && icon !== undefined}
            lightTheme={lightTheme || Boolean(icon)}
            style={{
                height: `${size}px`,
                width: `${size}px`,
                minWidth: `${size}px`,
            }}
        >
            {children}
            {icon && iconList[icon]}
        </Container>
    )
}

const Container = styled.div<{ lightTheme?: boolean; noBg?: boolean; blueIcon?: boolean; greenIcon?: boolean }>`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    border-radius: 100%;
    background: #2281ff;
    font-weight: 400;
    font-size: 18px;
    line-height: 1;
    color: #fff;

    svg {
        margin: 0 !important;
    }

    &.noBg {
        background: transparent !important;
    }

    &.blueIcon svg {
        color: #2281ff;
    }

    &.lightTheme {
        background: #f2f9fd;
        background: var(--bg-color-2);
    }

    &.greenIcon svg {
        color: #49ca1c;
    }
`

export default RoundIcon
