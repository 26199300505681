import { Button, Space, Spinner, Surface } from 'capricorn-ui'
import { FormattedMessage, useIntl } from 'react-intl'
import { ROUTE } from 'constants/routes'
import { useNavigate } from 'react-router-dom'
import { useStore } from 'src/store'
import AlertSvg from 'assets/icons/alert.svg'
import Link from 'containers/Link'
import ProgressSvg from 'src/assets/icons/progress-time.svg'
import React, { PropsWithChildren, useEffect, useState } from 'react'
import RoundIcon from 'components/atoms/RoundIcon'
import VerificationService from 'services/VerificationService'
import styled from 'astroturf/react'

const VerifyAlert: React.FC<PropsWithChildren> = ({ children, ...props }) => {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [ticket, setTicket] = useState<string>()
    const { auth } = useStore()
    const intl = useIntl()
    const isVerified = auth.user?.verificationStatus === 'verified'

    useEffect(() => {
        const getVerification = async () => {
            setLoading(true)
            try {
                const { rejectionReason } = await VerificationService.getVerificationData()
                setTicket(rejectionReason)
            } catch (e) {}
            setLoading(false)
        }

        if (auth.user?.verificationStatus === 'manager-request') {
            getVerification()
        }
    }, [auth.user?.verificationStatus])

    if (isVerified) {
        return null
    }

    return (
        <VerifyAlertStyled {...props}>
            <RoundIconWrap>
                <RoundIconStyled inProcess={auth.user?.verificationStatus === 'new'}>
                    {auth.user?.verificationStatus === 'new' ? <ProgressSvg width={16} /> : <AlertSvg width={16} />}
                </RoundIconStyled>
            </RoundIconWrap>
            <div style={{ width: '100%', display: 'flex', alignItems: 'center', gap: 4 }}>
                {children || (
                    <>
                        {auth.user?.verificationStatus === 'new' ? (
                            intl.formatMessage(
                                {
                                    defaultMessage:
                                        'We are considering your application and will send you an email with the verification results. You can use your account only in Demo mode.',
                                },
                                {
                                    a: (word) => <Link to={ROUTE.VERIFICATION}>{word}</Link>,
                                },
                            )
                        ) : auth.user?.verificationStatus === 'manager-request' ? (
                            <Space align="center" justify="space-between" style={{ width: '100%' }}>
                                <div>
                                    {intl.formatMessage(
                                        {
                                            defaultMessage:
                                                'You have received a request from manager, please go to <a>ticket</a> to read details.',
                                        },
                                        {
                                            a: (word) =>
                                                loading ? (
                                                    <Spinner size={12} />
                                                ) : (
                                                    <Link to={`${ROUTE.SUPPORT}/${ticket}`}>{word}</Link>
                                                ),
                                        },
                                    )}
                                </div>
                                <Button onClick={() => navigate(ROUTE.VERIFICATION)} size="s">
                                    <FormattedMessage defaultMessage="Edit verification" />
                                </Button>
                            </Space>
                        ) : (
                            <div>
                                {intl.formatMessage(
                                    {
                                        defaultMessage:
                                            'Вы можете использовать личный кабинет только в Demo-режиме. Для начала работы необходимо завершить процесс <a>Верификации аккаунта</a>',
                                    },
                                    {
                                        a: (word) => <Link to={ROUTE.VERIFICATION}>{word}</Link>,
                                    },
                                )}
                                .
                            </div>
                        )}
                    </>
                )}
            </div>
        </VerifyAlertStyled>
    )
}

const RoundIconWrap = styled.div`
    @media (max-width: 600px) {
        display: none;
    }
`

const RoundIconStyled = styled(RoundIcon)<{ inProcess?: boolean }>`
    width: 40px;
    height: 40px;
    margin-right: 16px;

    &:not(.inProcess) {
        background: #fff1e1;
    }
`

const VerifyAlertStyled = styled(Surface)`
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 30px;
    padding: 14px 16px;
    font-size: 14px;
    background-color: var(--bg-color-1) !important;
    color: #9ba1aa;
    box-sizing: border-box;
    transition:
        0.25s transform,
        0.25s box-shadow;
    line-height: 1.45;

    &.column {
        flex-direction: column;
    }

    @media (max-width: 600px) {
        align-items: flex-start;
        flex-direction: column;

        ${RoundIconWrap} {
            width: 36px;
            height: 36px;
            border: 3px solid #fff;
            border-radius: 100%;
            margin-top: -35px;
            margin-bottom: 5px;
        }
    }
`

export default VerifyAlert
