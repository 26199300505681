import * as React from 'react'
import * as yup from 'yup'
import { AuthButton, AuthPageTitle } from '../../templates/AuthTemplate'
import { FormattedMessage, useIntl } from 'react-intl'
import { ROUTE } from 'src/constants/routes'
import { RegistrationChildProps } from '../RegistrationPage'
import { Space } from 'capricorn-ui'
import { useQuery } from 'react-query'
import { useRef, useState } from 'react'
import { useWindowSize } from 'react-use'
import AnalyticService from 'services/AnalyticService'
import ApiService from 'services/ApiService'
import Bugsnag from '@bugsnag/js'
import ControlledPhoneInput from '../../../containers/ControlledPhoneInput'
import Link from 'src/containers/Link'
import ReCAPTCHA from 'react-google-recaptcha'
import useFormEnhanced from 'src/hooks/useFormEnhanced'

const schema = () =>
    yup.object().shape({
        phone: yup.string().required(),
    })

interface FormData {
    phone: string
}

const Step1: React.FC<RegistrationChildProps> = ({ state, setState, sendOTP }) => {
    const captcha = useQuery(['captcha'], () => ApiService.request<{ captcha: string }>('/user/settings/essential'))
    const captchaRef = useRef<any>()
    const [captchaSuccess, setCaptchaSuccess] = useState(false)

    const { control, handleSubmit, watch } = useFormEnhanced({
        schema,
        defaultValues: state,
    })
    const intl = useIntl()
    const [loading, setLoading] = useState(false)
    const windowWidth = useWindowSize().width

    const [captchaVisible, setCaptchaVisible] = useState(false)

    const onSubmit = async ({ phone }: FormData) => {
        if (captcha.data?.captcha === 'true' && !captchaSuccess) {
            setCaptchaVisible(true)
        } else {
            // if (STAGE === 'production') {
            //     notice.show({
            //         title: <FormattedMessage defaultMessage="Registration is temporarily unavailable" />,
            //         message: <FormattedMessage defaultMessage="Пожалуйста, попробуйте немного позже." />,
            //     })
            // } else {
            setLoading(true)
            await sendOTP(
                phone,
                () => {
                    Bugsnag.setUser(undefined, undefined, phone)
                    if (captchaRef.current) {
                        captchaRef.current.props.grecaptcha.reset()
                    }
                    AnalyticService.trackEvent('SIGN_UP_START')
                    setState({ ...state, phone, step: 2 })
                },
                false,
                captchaRef.current?.props?.grecaptcha.getResponse(),
            )
            setLoading(false)
            // }
        }
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Space column>
                <AuthPageTitle>
                    <FormattedMessage defaultMessage="Введите телефон" />
                </AuthPageTitle>
                <ControlledPhoneInput
                    label={intl.formatMessage({ defaultMessage: 'Номер телефона' })}
                    name="phone"
                    control={control}
                    maxWidth={600}
                    autoFocus={windowWidth > 600}
                    inputMode="tel"
                />
                <div>
                    <FormattedMessage defaultMessage="Уже зарегистрированы?" />{' '}
                    <Link mobileModalView to={ROUTE.SIGN_IN} bold>
                        <FormattedMessage defaultMessage="Авторизация" />
                    </Link>
                </div>
                {captchaVisible && (
                    <ReCAPTCHA
                        sitekey="6LcG3ZYcAAAAAG9mXgb3f0IchxlE1Vzd2sNrMjxs"
                        ref={captchaRef}
                        onChange={() => setCaptchaSuccess(true)}
                        onExpired={() => setCaptchaSuccess(false)}
                        onErrored={() => setCaptchaSuccess(false)}
                        hl="en"
                    />
                )}
                <AuthButton
                    type="submit"
                    loading={loading || captcha.isLoading}
                    disabled={
                        (watch('phone') || '').length < 11 || (captcha.isFetched && captchaVisible && !captchaSuccess)
                    }
                >
                    <FormattedMessage defaultMessage="Получить код" />
                </AuthButton>
            </Space>
        </form>
    )
}

export default Step1
