import * as React from 'react'
import LoaderSvg from 'assets/icons/loader.svg'
import LogoSvg from 'src/assets/icons/logo-vertical.svg'
import styled from 'astroturf/react'

interface Props {
    children?: React.ReactNode
    noLogo?: boolean
    delay?: number
    withTransparent?: boolean
    visibleHeader?: boolean
}

const ScreenLoader: React.FC<Props> = ({ noLogo, withTransparent, visibleHeader, children }) => {
    return (
        <Container visibleHeader={visibleHeader} withTransparent={withTransparent}>
            {!noLogo && (
                <>
                    <LogoSvg width={200} />
                    <br />
                </>
            )}
            {children}
            <LoaderSvg width={50} />
        </Container>
    )
}

const Container = styled.div<{ withTransparent?: boolean; visibleHeader?: boolean }>`
    position: fixed;
    display: flex;
    top: 0;
    left: 0;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    justify-content: center;
    align-items: center;
    background: var(--bg-color-1);
    z-index: 30;
    cursor: progress;

    &.withTransparent {
        background: rgba(255, 255, 255, 0.3);
        backdrop-filter: blur(8px);
    }

    &.visibleHeader {
        z-index: 10;
    }
`

export default ScreenLoader
