import { Theme } from 'src/store/commonStore'
import { TransferStatus } from 'src/modules/operations/transfers/types'
import { useStore } from 'src/store'
import ChevronSvg from 'assets/icons/chevron-down.svg'
import DownSvg from 'assets/icons/chevron-down.svg'
import PlusSvg from 'assets/icons/plus.svg'
import React, { useEffect, useRef, useState } from 'react'
import StatusLabel from '../atoms/StatusLabel'
import styled from 'astroturf/react'

interface IProps {
    title: React.ReactNode
    onToggle?: (isActive?: boolean) => void
    active?: boolean
    defaultActive?: boolean
    status?: TransferStatus
    statusText?: string
    view?: 'default' | 'modern' | 'text'
    noSurfacing?: boolean
    children: React.ReactNode
}

const Spoiler: React.FC<IProps> = ({
    title,
    children,
    active,
    defaultActive,
    onToggle,
    status,
    statusText,
    view = 'default',
    noSurfacing,
    ...props
}) => {
    const {
        common: { theme },
    } = useStore()
    const contentRef = useRef<HTMLDivElement>(null)
    const contentInnerRef = useRef<HTMLDivElement>(null)
    const [visible, setVisible] = useState(active || defaultActive)

    const handleToggle = () => {
        if (onToggle) {
            onToggle(!visible)
        }
        setVisible((prev) => !prev)
    }

    useEffect(() => {
        setTimeout(() => {
            if (contentRef.current && contentInnerRef.current) {
                contentRef.current.style.maxHeight = `${contentInnerRef.current.clientHeight}px`
            }
        })
    }, [contentRef.current, children])

    useEffect(() => {
        if (visible) {
            setTimeout(() => {
                if (contentRef.current) {
                    contentRef.current.style.overflow = 'visible'
                }
            }, 300)
        } else {
            if (contentRef.current) {
                contentRef.current.style.overflow = 'hidden'
            }
        }
    }, [visible])

    return (
        <SpoilerStyled theme={theme} {...props} active={visible} view={view} noSurfacing={noSurfacing}>
            <Header onClick={handleToggle}>
                {view === 'default' ? (
                    <PlusSvg width={20} color="#2281FF" />
                ) : (
                    view === 'modern' && (
                        <IconCircle>
                            <DownSvg width={13} color="#2281FF" />
                        </IconCircle>
                    )
                )}
                {view === 'text' ? title : <Title>{title}</Title>}
                {view === 'text' && <ChevronSvg width={10} color="#2281ff" />}
                {status && <StatusLabel status={status} statusText={statusText} />}
            </Header>
            <Content ref={contentRef}>
                <ContentInner ref={contentInnerRef}>{children}</ContentInner>
            </Content>
        </SpoilerStyled>
    )
}

const ContentInner = styled.div``

const IconCircle = styled.div`
    display: flex;
    width: 27px;
    height: 27px;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    border: 1px solid #2281ff;
    transform-origin: center;
    transition: 0.25s transform;

    svg {
        margin: 2px 0 0 0 !important;
    }
`

const Title = styled.div`
    flex: 1;
    margin: 0 15px;
    font-size: 16px;
    font-weight: 600;
`

const Content = styled.div`
    padding: 0 !important;
    transition: max-height 0.3s;
`

const Header = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    line-height: 1.4;

    svg {
        margin-left: 8px;
    }
`

const SpoilerStyled = styled.div<{ active?: boolean; view: string; noSurfacing?: boolean; theme?: Theme }>`
    border-radius: 6px;
    transition:
        0.2s transform,
        0.2s box-shadow;

    &.view-default {
        box-shadow: 0 18px 30px rgba(73, 90, 102, 0.07);

        ${Header} {
            padding: 20px 25px;
        }
    }

    &.view-modern {
        box-shadow: 0 6px 12px rgba(73, 90, 102, 0.07);
        border: 1px solid var(--light-bg-color);

        ${Header} {
            padding: 25px 30px;

            @media (max-width: 600px) {
                padding: 15px;
                line-height: 1.3;
            }
        }

        ${ContentInner} {
            border-top: 1px solid var(--light-bg-color);
        }

        ${Content} {
            padding: 40px;
        }

        ${Title} {
            margin-left: 20px;
            font-size: 18px;
        }
    }

    &.view-text {
        ${Content} {
            padding: 0;
        }

        ${Header} svg {
            transition: 0.3s transform;
        }

        &.active {
            ${Content} {
                padding-top: 10px;
            }
            ${Header} svg {
                transform: rotate(180deg);
            }
        }
    }

    &:not(.view-text):not(.noSurfacing):not(.active) {
        &:hover {
            transform: translateY(-3px);
            box-shadow: 0 10px 20px rgba(26, 30, 42, 0.14);
        }
    }

    &:not(.active) {
        ${Content} {
            max-height: 0 !important;
        }
    }

    &:not(.view-text) {
        ${ContentInner} {
            padding: 30px;

            @media (max-width: 600px) {
                padding: 20px 15px;
            }
        }
    }

    &.active:not(.view-text) {
        transform: none;
        box-shadow: 0 18px 30px rgba(73, 90, 102, 0) !important;

        ${IconCircle} {
            transform: rotate(180deg);
        }
    }
`

export default Spoiler
