import { User } from 'src/interfaces/User'
import { useState } from 'react'

type State = 'initial' | 'fetching' | 'fetched' | 'error'

export type VerificationStatus =
    | 'new'
    | 'declined'
    | 'verified'
    | 'none'
    | 'manager-request'
    | 'aml-request'
    | 'aml-ready'
    | 'need-review'
    | 'preverified'

export const AuthStore = () => {
    const [state, setState] = useState<State>('initial')
    const [user, setUserState] = useState<User | undefined>()

    return {
        state,
        setState,
        user,
        setUser: (user: User | undefined) => {
            if (user) {
                setUserState({
                    ...user,
                    settings: {
                        ...(user.settings || {}),
                        allowCardIssue: true,
                    },
                    // Need-review - status for AML when verified status expired
                    verificationStatus:
                        user.verificationStatus === 'need-review' ? 'verified' : user.verificationStatus,
                })
            } else {
                setUserState(undefined)
            }
        },
        setUserConfirmationOptions: (confirmationOptions: 'GOOGLECODE' | 'OTP') => {
            if (user) {
                setUserState({
                    ...user,
                    confirmationOptions,
                })
            }
        },
    }
}
