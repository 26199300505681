import { BankCard } from 'src/interfaces/BankCard'
import { UseQueryOptions, useQuery } from 'react-query'
import CardService from 'services/CardService'

interface Response {
    list: BankCard[]
    count: number
}

export default (options?: UseQueryOptions<Response>) =>
    useQuery<Response>(['cards', 'list', 5], () => CardService.getList(5, 0), options)
