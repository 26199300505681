import { LOCALE } from 'constants/locales'
import { useEffect, useState } from 'react'
import { useLocalStorage } from 'react-use'

export type Theme = 'light' | 'dark'

export const CommonStore = () => {
    const [locale, setLocale] = useLocalStorage<LOCALE>('locale', LOCALE.EN)
    const [isOpenedMenu, setIsOpenedMenu] = useState(false)
    const [theme, setTheme] = useLocalStorage<Theme>('theme', 'light')

    useEffect(() => {
        document.querySelector('html')?.setAttribute('lang', locale as string)
    }, [locale])

    useEffect(() => {
        const html = document.documentElement
        const body = document.body

        if (theme === 'dark') {
            html?.classList.remove(`theme-light`)
            html?.classList.add(`theme-dark`)
            body?.classList.remove(`theme-light`)
            body?.classList.add(`theme-dark`)
        } else {
            html?.classList.remove(`theme-dark`)
            html?.classList.add(`theme-light`)
            body?.classList.remove(`theme-dark`)
            body?.classList.add(`theme-light`)
        }
    }, [theme])

    return {
        locale,
        setLocale,
        isOpenedMenu,
        setIsOpenedMenu,
        theme,
        toggleTheme: () => {
            setTheme(theme === 'light' ? 'dark' : 'light')
        },
    }
}
