import * as store from 'store/dist/store.modern.min'
import { LOCALE } from 'constants/locales'
import { getNavigatorLanguage } from '../utils'

const LanguageService = {
    getLocale: () => store.get('locale'),

    setLocale: (locale: string) => {
        store.set('locale', locale)
    },

    getInitialLocale: () => {
        const locale = LanguageService.getLocale() || getNavigatorLanguage()
        return Object.values(LOCALE).includes(locale) ? locale : LOCALE.EN
    },
}

export default LanguageService
