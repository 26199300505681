import { UaEventOptions } from 'react-ga4/types/ga4'
import { User } from 'src/interfaces/User'
import ReactGA from 'react-ga4'

const AnalyticService = {
    init: () => {
        ReactGA.initialize('G-H8BXWZQS0K', {
            testMode: process.env.NODE_ENV !== 'production',
        })
    },
    trackPageView: (path: string, title: string) => {
        ReactGA.send({ hitType: 'pageview', page: path, title })
    },
    setUser: (user: User) => {
        ReactGA.gtag('set', 'user_properties', {
            practika_id: user.id,
            login: user.login,
            type: user.stage,
            status: user.verificationStatus,
        })
    },
    trackEvent: (optionsOrName: UaEventOptions | string, params?: unknown) => {
        ReactGA.event(optionsOrName, params)
    },
}

export default AnalyticService
