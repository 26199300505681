import * as React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Space } from 'capricorn-ui'
import { UserAccount } from 'src/interfaces/UserAccount'
import { useState } from 'react'
import { useStore } from 'src/store'
import { useWindowSize } from 'react-use'
import ApiService from 'services/ApiService'
import ErrorBoundaryContainer from 'containers/ErrorBoundaryContainer'
import FormGroupTitle from 'components/atoms/FormGroupTitle'
import Skeleton from 'react-loading-skeleton'
import styled from 'astroturf/react'
import useAccountListQuery from 'src/queries/useAccountListQuery'

const ChooseMainAccount: React.FC = () => {
    const intl = useIntl()
    const { notice, auth } = useStore()
    const windowWidth = useWindowSize().width
    const accounts = useAccountListQuery()
    const [loading, setLoading] = useState(false)

    const handleChange = (account: string) => async () => {
        if (!loading) {
            try {
                setLoading(true)
                await ApiService.request('/user/user-settings/update', {
                    name: 'main-account',
                    value: account,
                })
                notice.show({
                    title: intl.formatMessage({ defaultMessage: 'Changes saved' }),
                    skin: 'success',
                })
                auth.setUser({
                    ...auth.user,
                    settings: {
                        ...auth.user?.settings,
                        'main-account': account,
                    },
                })
            } catch (e) {
                notice.showCommonError(e)
            }
            setLoading(false)
        }
    }

    return (
        <ErrorBoundaryContainer>
            <Space size={24} column align="flex-start">
                <FormGroupTitle hideOnMobile>
                    <FormattedMessage defaultMessage="Main account currency" />
                </FormGroupTitle>
                <Tip>
                    <FormattedMessage defaultMessage="Preferred currency for the maintenance fee and payment crediting with auto-conversion." />
                </Tip>
                <Space column={windowWidth <= 600} wrap size={20}>
                    {accounts.isFetched ? (
                        accounts.data?.map((accountItem: UserAccount) => {
                            return (
                                <CurrencyItem
                                    key={accountItem.id}
                                    onClick={handleChange(accountItem.id)}
                                    active={auth.user?.settings['main-account'] === accountItem.id}
                                    loading={loading}
                                >
                                    <Radio />
                                    <CurrencyContent>{accountItem.currency}</CurrencyContent>
                                </CurrencyItem>
                            )
                        })
                    ) : (
                        <Skeleton count={1} width={500} height={64} />
                    )}
                </Space>
            </Space>
        </ErrorBoundaryContainer>
    )
}

const Radio = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 28px;
    height: 28px;
    border-radius: 100%;
    border: 1px solid var(--light-bg-color);
    transition: 0.25s border-color;

    &::before {
        width: 12px;
        height: 12px;
        border-radius: 100%;
        transform: scale(0);
        background: #2281ff;
        content: '';
        transition: 0.25s transform;
    }
`

const CurrencyContent = styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
    margin-right: 5px;
`

const CurrencyItem = styled.div<{ active?: boolean; loading?: boolean }>`
    position: relative;
    display: flex;
    gap: 15px;
    align-items: center;
    justify-content: space-between;
    padding: 14px 20px;
    border: 1px solid var(--light-bg-color);
    box-sizing: border-box;
    text-align: center;
    border-radius: 6px;
    font-size: 18px;
    font-weight: 600;
    color: var(--main-text-color);
    cursor: pointer;
    transition-property: border-color, transform;
    transition-duration: 0.25s;

    &:last-child {
        margin: 0;
    }

    &:hover {
        border-color: #b9bcc1;
    }

    &.loading {
        cursor: progress;
    }

    &.active {
        color: #2281ff;
        border-color: #2281ff;

        ${Radio} {
            border-color: #2281ff;

            &::before {
                transform: scale(1);
            }
        }
    }

    @media (max-width: 600px) {
        padding: 0;
        border: none;
        font-size: 16px;
    }
`

const Tip = styled.div`
    color: var(--main-second-text-color);
`

export default ChooseMainAccount
