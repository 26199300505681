import * as React from 'react'
import { useIntl } from 'react-intl'
import { withErrorBoundaryContainer } from 'containers/ErrorBoundaryContainer'
import CardFeesTable from 'components/organisms/CardFeesTable'
import NewModal from 'components/molecules/Modal'

const CardFeesModal: React.FC = () => {
    const intl = useIntl()

    return (
        <NewModal title={intl.formatMessage({ defaultMessage: 'Fees and limits' })} maxWidth={700} noTitleBorder>
            <CardFeesTable />
        </NewModal>
    )
}

export default withErrorBoundaryContainer(CardFeesModal)
