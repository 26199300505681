import { Store, createStore } from './store'
import React, { FC } from 'react'

const StoreContext = React.createContext<Store>({} as Store)

interface Props {
    children: React.ReactNode
}

export const StoreProvider: FC<Props> = ({ children }) => {
    const store = createStore()
    return <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
}

export const useStore = () => {
    const store = React.useContext(StoreContext)
    if (!store) {
        throw new Error('useStore must be used within a StoreProvider.')
    }
    return store
}
