import * as React from 'react'
import { withErrorBoundaryContainer } from 'containers/ErrorBoundaryContainer'
import NewModal, { IModalProps } from 'components/molecules/Modal'

interface Props extends Omit<IModalProps, 'name'> {
    onCancel: () => void
    withRouting?: boolean
}

const EmptyModal: React.FC<Props> = ({ children, onCancel, withRouting, ...props }) => (
    <NewModal noRouting={!withRouting} onDismissClick={onCancel} {...props}>
        {children}
    </NewModal>
)

export default withErrorBoundaryContainer<Props>(EmptyModal)
