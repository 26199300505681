import { AnimatePresence, motion } from 'framer-motion'
import { NoticeStore } from 'src/store/noticeStore'
import { Toast } from 'capricorn-ui'
import { callIfExist } from '../utils'
import { useLocation } from 'react-router-dom'
import React, { FC, useEffect } from 'react'
import styled from 'astroturf/react'

const Notices: FC<{ noticesStore: ReturnType<typeof NoticeStore> }> = ({ noticesStore }) => {
    const location = useLocation()

    useEffect(() => {
        noticesStore.hideAll()
    }, [location.pathname])

    return (
        <Container>
            <AnimatePresence>
                {noticesStore.opened.map((notice) => (
                    <motion.div
                        key={notice.id}
                        initial={{ opacity: 0, x: 100 }}
                        animate={{ opacity: 1, x: 0 }}
                        exit={{ opacity: 0, x: 400 }}
                    >
                        <Toast
                            {...notice}
                            onDismissClick={() => {
                                callIfExist(notice.onDismissClick)
                                noticesStore.hide(notice.id)
                            }}
                            onActionClick={() => {
                                callIfExist(notice.onActionClick)
                                noticesStore.hide(notice.id)
                            }}
                        />
                    </motion.div>
                ))}
            </AnimatePresence>
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    position: fixed;
    flex-direction: column;
    justify-content: center;
    top: 20px;
    right: 20px;
    z-index: 99999;

    @media (max-width: 600px) {
        top: auto;
        bottom: 10px;
        right: 15px;
        left: 15px;
    }
`

export default Notices
