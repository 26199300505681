import { CardIssueProgress, IssuePlasticCard, IssueUnknownCard, IssueVirtualCard } from 'src/interfaces/IssueCardModel'
import { CardTariff } from 'src/interfaces/BankCard'
import React, { useState } from 'react'

const initialCardStore = {
    IssuePlastic: { currentStep: 0, successStep: 0 } as Partial<IssuePlasticCard> & CardIssueProgress,
    IssueVirtual: { currentStep: 0, successStep: 0 } as Partial<IssueVirtualCard> & CardIssueProgress,
    currentCardTariff: 'IssuePlastic' as CardTariff,
}

export const CardStore = () => {
    const [cardIssueData, setCardIssueData] = useState(initialCardStore)
    // Separate for security reasons, it was when used local storage
    const [password, setPassword] = useState<string>()

    const currentIssueData = cardIssueData
        ? cardIssueData[cardIssueData?.currentCardTariff || 'IssuePlastic']
        : initialCardStore.IssuePlastic

    return {
        cardIssueData,
        setCardIssueData,
        currentIssueData,
        password,
        setPassword,
        reset: () => {
            setCardIssueData(initialCardStore)
        },
        setCurrentStep: (step: number) => {
            setCardIssueData({
                ...cardIssueData,
                [cardIssueData.currentCardTariff]: {
                    ...cardIssueData[cardIssueData.currentCardTariff],
                    currentStep: step,
                },
            })
        },
        finishStep: (dataToSave: IssueUnknownCard, cardTariff?: CardTariff) => {
            setCardIssueData({
                ...cardIssueData,
                currentCardTariff: cardTariff || cardIssueData.currentCardTariff,
                [cardTariff || cardIssueData.currentCardTariff]: {
                    ...cardIssueData[cardTariff || cardIssueData.currentCardTariff],
                    ...dataToSave,
                    successStep: cardIssueData[cardTariff || cardIssueData.currentCardTariff].currentStep,
                    currentStep: cardIssueData[cardTariff || cardIssueData.currentCardTariff].currentStep + 1,
                },
            })
        },
    }
}

export const CARD_ISSUE_STEPS = {
    IssuePlastic: () => ['Type of card', 'Personal data', 'Confirmation'],
    IssueVirtual: () => ['Type of card', 'Personal data', 'Confirmation'],
}

export type ICardStore = ReturnType<typeof CardStore>

export const CardStoreContext = React.createContext<ICardStore>({} as ICardStore)

export const useCardStore = () => {
    return React.useContext(CardStoreContext)
}
