import * as React from 'react'
import styled from 'astroturf/react'

interface IProps {
    posStatic?: boolean
}

const Footer: React.FC<IProps> = ({ ...props }) => {
    return (
        <Content {...props}>
            <Copyright>
                © 2020-{new Date().getFullYear()} «Practika Finance LTD» | v.
                {PACKAGE_VERSION}
            </Copyright>
        </Content>
    )
}

const Copyright = styled.div`
    font-size: 13.5px;

    @media (max-width: 600px) {
        display: none;
    }
`

const Content = styled.footer<{ posStatic?: boolean }>`
    display: flex;
    gap: 12px;
    justify-content: space-between;
    flex-wrap: wrap-reverse;
    align-items: center;
    padding: 0 0 30px 0;
    width: 100%;
    box-sizing: border-box;
    color: var(--main-second-text-color);

    &.posStatic {
        position: static !important;
        padding: 20px 28px;
    }

    @media (max-width: 768px) {
        padding: 16px 15px !important;

        & > * {
            flex: 1 0 100%;
        }
    }
`

export default Footer
