import { API } from 'constants/api'
import { UAParser } from 'ua-parser-js'
import { User } from 'src/interfaces/User'
import { VerificationStatus } from 'src/store/authStore'
import ApiService from './ApiService'

interface IAuthService {
    signIn: (login: string, password: string, otp?: string) => Promise<User>

    checkOtp: (login: string, otp: string) => Promise<void>

    // Используется при установке нового пароля
    setPassword: (signUp: ResetPasswordProps) => Promise<void>

    getCurrentUser: () => Promise<User>
    signOutUser: () => Promise<void>

    resetPassword: (login: string) => Promise<void>

    signUpPhoneNumber: (locale: string, phoneNumber: string, recaptcha?: string) => Promise<void>
    signUpEmail: (phoneNumber: string, email: string) => Promise<void>
    signUpLogin: (phoneNumber: string, login: string) => Promise<void>
    signUpPassword: (login: string, password: string, otp: string) => Promise<User>
}

export interface ResetPasswordProps {
    login?: string
    phone?: string
    password: string
    otp: string
}

const AuthService: IAuthService = {
    signIn: (login, password, otp) => {
        const data = new UAParser(navigator.userAgent)

        return ApiService.request(API.USER_SIGN_IN, {
            login,
            password,
            otp,
            browser: data.getBrowser().name,
            device: `${data.getDevice().model ? data.getDevice().model + ' / ' : ''}${data.getOS().name}`,
        })
    },

    checkOtp: (login, otp) =>
        ApiService.request(API.CHECK_OTP, {
            login,
            otp,
        }),

    resetPassword: (login) =>
        ApiService.request(API.RESET_PASSWORD, {
            login,
        }),

    setPassword: ({ login, phone, password, otp }) =>
        ApiService.request(API.SET_PASSWORD, {
            login,
            password,
            phone,
            otp,
        }),

    getCurrentUser: async () => {
        const user = await ApiService.request<User>(API.USER_FETCH)
        return {
            ...user,
            verificationStatus: user.verificationStatus.toLowerCase().replace('nonep', 'none') as VerificationStatus,
        }
    },

    signOutUser: () => ApiService.request(API.USER_SIGN_OUT),

    signUpPhoneNumber: (locale, phoneNumber, recaptcha) =>
        ApiService.request(API.USER_SIGN_UP_PHONE_NUMBER, {
            locale,
            phoneNumber,
            'g-recaptcha-response': recaptcha || undefined,
        }),

    signUpEmail: (phoneNumber, email) =>
        ApiService.request(API.USER_SIGN_UP_EMAIL, {
            email,
            phoneNumber,
        }),

    signUpLogin: (phoneNumber, login) =>
        ApiService.request(API.USER_SIGN_UP_LOGIN, {
            login,
            phoneNumber,
        }),

    signUpPassword: (login, password, otp) =>
        ApiService.request(API.SET_PASSWORD, {
            password,
            login,
            otp,
            // Email об успешной регистрации
            sendEmail: true,
        }),
}

export default AuthService
