import * as React from 'react'
import { API } from 'constants/api'
import { Dropdown } from 'capricorn-ui'
import { DropdownProps } from 'capricorn-ui/Dropdown'
import { LOCALE } from 'constants/locales'
import { languages } from 'constants/languages'
import { useOutsideClick } from 'src/hooks'
import { useRef, useState } from 'react'
import { useStore } from 'src/store'
import ApiService from 'services/ApiService'
import ChevronDownSvg from 'src/assets/icons/chevron-down.svg'
import styled from 'astroturf/react'

interface Props {
    dropdownProps?: DropdownProps
}

const SwitchLang: React.FC<Props> = ({ dropdownProps = {}, ...props }) => {
    const {
        common: { locale, setLocale },
        auth,
    } = useStore()
    const [visible, setVisible] = useState(false)
    const ref = useRef(null)

    useOutsideClick(ref, () => setVisible(false))

    const activeLang = languages[locale]

    if (!activeLang) return <></>

    const onChangeLocale = (locale: LOCALE) => () => {
        setVisible(false)
        setLocale(locale)

        if (auth.user) {
            ApiService.request(`${API.USER_CHANGE_LOCALE}?locale=${locale}`, {}, 'POST')
        }
    }

    return (
        <SwitchLangStyled {...props} ref={ref}>
            <ActiveLang onClick={() => setVisible(!visible)} visible={visible}>
                <Lang>
                    {activeLang?.icon}
                    {locale.toUpperCase()}
                </Lang>
                <ChevronDownSvg width={9} height={5} />
            </ActiveLang>
            <Dropdown {...dropdownProps} noPadding active={visible} widthAuto>
                <List>
                    {Object.keys(languages).map((lang: LOCALE) => (
                        <Lang internal key={lang} active={locale === lang} onClick={onChangeLocale(lang)}>
                            {languages[lang].icon}
                            {lang.toUpperCase()}
                        </Lang>
                    ))}
                </List>
            </Dropdown>
        </SwitchLangStyled>
    )
}

const List = styled.div`
    padding: 6px 0;
`

const SwitchLangStyled = styled.div`
    position: relative;
    display: inline-flex;
    font-size: 15px;
    font-weight: 600;
    width: 78px;
    z-index: 10;
`

const Lang = styled.div<{ active?: boolean; internal?: boolean }>`
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: 0.25s color;

    &.internal {
        padding: 5px 9px;
        font: 600 13px 'Open Sans';
    }

    svg {
        width: 15px;
        height: 15px;
        border: 2px solid transparent;
        border-radius: 100%;
        transform: translate3d(0, 0, 0);
        margin-right: 5px;
    }

    &.internal:not(.active):hover {
        opacity: 1;
    }

    &.active {
        cursor: default;

        &::after {
            width: 6px;
            height: 6px;
            background-color: #2281ff;
            border-radius: 100%;
            margin-left: 8px;
            content: '';
        }
    }
`

const ActiveLang = styled.div<{ visible?: boolean }>`
    display: flex;
    align-items: center;
    width: 100%;
    border-radius: 20px;
    padding: 7px 13px 7px 10px;
    background: #f2f9fd;
    background: var(--bg-color-2);
    cursor: pointer;
    font-size: 13px;

    svg {
        color: #2281ff;
        transform-origin: center;
        transform: scaleY(1);
        transition: 0.25s transform;
    }

    &.visible {
        & > svg {
            transform: scaleY(-1);
        }
    }

    ${Lang} {
        flex: 1;
        margin-left: 0;

        svg {
            opacity: 1;
        }
    }
`

export default SwitchLang
