import * as React from 'react'
import { useEffect } from 'react'
import { useStore } from 'src/store'
import { useWindowSize } from 'react-use'
import MoonSvg from 'assets/icons/moon.svg'
import SunSvg from 'assets/icons/sun.svg'
import styled from 'astroturf/react'

const ThemeSwitcher: React.FC = (props) => {
    const { common } = useStore()
    const windowWidth = useWindowSize().width

    useEffect(() => {
        const color = windowWidth <= 600 ? 'var(--bg-color-2)' : common.theme === 'light' ? '#fff' : '#1d2534'

        document.querySelector('meta[name="apple-mobile-web-app-status-bar-style"]')?.setAttribute('content', color)
        document.querySelector('meta[name="theme-color"]')?.setAttribute('content', color)
    }, [common.theme])

    return (
        <Switch {...props} onClick={common.toggleTheme} checked={common.theme === 'dark'}>
            <Sun width={14} />
            <Moon width={14} />
        </Switch>
    )
}

const Sun = styled(SunSvg)``
const Moon = styled(MoonSvg)``

const Switch = styled.div<{ checked?: boolean }>`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    border-radius: 20px;
    cursor: pointer;
    border: 1px solid #dfe2e8;
    transition: 0.25s background;

    &::after {
        position: absolute;
        border-radius: 100%;
        transition-property: background, transform, box-shadow;
        transition-duration: 0.25s;
        content: '';
    }

    &.checked::after {
        transform: translateX(100%);
    }

    &.checked {
        border-color: #2281ff;
    }

    &::after {
        background: #2281ff;
    }

    width: 40px;
    height: 22px;
    padding: 0 3px;

    &::after {
        width: 16px;
        height: 16px;
    }
`

export default ThemeSwitcher
