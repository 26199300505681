import { Checkbox } from 'capricorn-ui'
import { CheckboxProps } from 'capricorn-ui/lib/Checkbox'
import { ControllerProps } from 'containers/types'
import { FieldValues } from 'react-hook-form/dist/types'
import { useController } from 'react-hook-form'
import React from 'react'

type CapricornProps = Omit<
    CheckboxProps & {
        noErrorMessage?: boolean
    },
    'name' | 'onChange' | 'checked'
>

interface Props<FieldsType extends FieldValues> extends ControllerProps<FieldsType>, CapricornProps {}

const ControlledCheckbox = <TFieldsType extends FieldValues>({
    control,
    name,
    noErrorMessage,
    ...rest
}: Props<TFieldsType>) => {
    const { field, fieldState } = useController({ name, control })

    return (
        <Checkbox
            onChange={field.onChange}
            checked={field.value}
            error={noErrorMessage ? Boolean(fieldState.error?.message) : fieldState.error?.message}
            {...rest}
        />
    )
}

export default ControlledCheckbox
