import * as React from 'react'
import * as yup from 'yup'
import { AuthButton, AuthPageTitle } from '../../templates/AuthTemplate'
import { FormattedMessage, useIntl } from 'react-intl'
import { LoginChildProps } from '../LoginPage'
import { ROUTE } from 'src/constants/routes'
import { Space } from 'capricorn-ui'
import { useState } from 'react'
import { useWindowSize } from 'react-use'
import Bugsnag from '@bugsnag/js'
import ControlledTextInput from '../../../containers/ControlledTextInput'
import Link from 'containers/Link'
import LoginSvg from 'src/assets/icons/login.svg'
import useFormEnhanced from 'src/hooks/useFormEnhanced'

const schema = () =>
    yup.object().shape({
        login: yup.string().required(),
        password: yup.string().required().min(8),
    })

interface FormData {
    login: string
    password: string
}

const Step1: React.FC<LoginChildProps> = ({ state, sendOTP }) => {
    const windowWidth = useWindowSize().width
    const { control, handleSubmit } = useFormEnhanced<FormData>({
        schema,
        defaultValues: state,
    })

    const intl = useIntl()
    const [loading, setLoading] = useState(false)

    const onSubmit = async ({ login, password }: FormData) => {
        Bugsnag.setUser(undefined, undefined, login)
        setLoading(true)
        await sendOTP(login, password)
        setLoading(false)
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Space size={windowWidth < 500 ? 32 : 40} column>
                <AuthPageTitle>
                    <FormattedMessage defaultMessage="Выполните вход" />
                </AuthPageTitle>
                <ControlledTextInput
                    name="login"
                    control={control}
                    prefix={windowWidth > 600 && <LoginSvg width={32} />}
                    label={intl.formatMessage({ defaultMessage: 'Логин или номер телефона' })}
                    maxWidth={600}
                    autoFocus={windowWidth > 600}
                    disabled={loading}
                    data-testid="login-input"
                />
                <ControlledTextInput
                    type="password"
                    name="password"
                    control={control}
                    label={intl.formatMessage({ defaultMessage: 'Пароль' })}
                    maxWidth={600}
                    disabled={loading}
                    data-testid="password-input"
                />
                <Space size={40}>
                    <Link mobileModalView to={ROUTE.FORGOT} bold>
                        <FormattedMessage defaultMessage="Забыли пароль?" />
                    </Link>
                    <div>
                        {windowWidth > 500 && <FormattedMessage defaultMessage="Еще не зарегистрированы?" />}{' '}
                        <Link mobileModalView to={ROUTE.SIGN_UP} bold>
                            <FormattedMessage defaultMessage="Sign up" description="Кнопка на странице логина" />
                        </Link>
                    </div>
                </Space>
                <AuthButton data-testid="submit-button" type="submit" loading={loading}>
                    <FormattedMessage defaultMessage="Sign in" description="Кнопка на странице логина" />
                </AuthButton>
            </Space>
        </form>
    )
}

export default Step1
