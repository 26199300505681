import { TransferType } from 'src/modules/operations/transfers/types'

const IsService = {
    stringIsNumber: (str: unknown) => {
        if (typeof str !== 'string') return false
        return !isNaN(parseFloat(str))
    },
    literalObject: (some: unknown) => !!some && some.constructor === Object,
    bankTransfer: (type: TransferType) => type === 'IFX' || type === 'SEPA' || type === 'SWIFT',
    cardIssueTransfer: (type: TransferType) => type === 'PlasticCard1' || type === 'VirtualCard1',
    cardActionTransfer: (type: TransferType) =>
        type === 'PlasticCard1' ||
        type === 'VirtualCard1' ||
        type === 'TopUp1' ||
        type === 'Block1' ||
        type === 'Change3ds1' ||
        type === 'ActivatePlastic1' ||
        type === 'ChangePin1' ||
        type === 'Card2Card1',
    cardActionWithZeroAmount: (type: TransferType) =>
        type === 'Block1' || type === 'Change3ds1' || type === 'ActivatePlastic1' || type === 'ChangePin1',
    doubleAmountTransfer: (type: TransferType) => type === 'CryptoUSDT' || type === 'BANK_VND',
}

export default IsService
