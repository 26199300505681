import 'capricorn-ui/theme/index.css'
import 'capricorn-ui/theme/practika-dark.css?global=true'
import 'capricorn-ui/theme/practika-light.css?global=true'
import * as React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { ErrorBoundary } from 'react-error-boundary'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { StoreProvider } from 'src/store'
import { lazy } from 'react'
import { stylesheet } from 'astroturf/react'
import AppContainer from '../containers/AppContainer'
import LanguageProvider from './LanguageProvider'

const UpdateBrowserPage = lazy(() => import('components/pages/errors/UpdateBrowserPage'))

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 10000,
            notifyOnChangeProps: 'tracked',
        },
    },
})

const App: React.FC = () => (
    <ErrorBoundary FallbackComponent={UpdateBrowserPage}>
        <BrowserRouter>
            <StoreProvider>
                <LanguageProvider>
                    <QueryClientProvider client={queryClient}>
                        <AppContainer />
                        <ReactQueryDevtools initialIsOpen={false} />
                    </QueryClientProvider>
                </LanguageProvider>
            </StoreProvider>
        </BrowserRouter>
    </ErrorBoundary>
)

document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`)

window.addEventListener('resize', () => {
    document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`)
})

stylesheet`
    @import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800,900&display=swap&subset=cyrillic');
    @import url('https://fonts.googleapis.com/css?family=Roboto+Mono:400&display=swap');

    html {
        min-height: -webkit-fill-available;
        font-size: 20px !important;
        background: #f9fafb;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }
    
    :global(html.theme-dark) {
        background: #2f3e5b !important;
    }
    
    @media (max-width: 1024px) {
        html {
            font-size: 18px !important;
        }
    }
    
    @media (max-width: 768px) {
        html {
            font-size: 16px !important;
        }
    }
    
    @media (max-width: 512px) {
        html {
            font-size: 14px !important;
        }
    }
    
    @media (max-width: 400px) {
        html {
            font-size: 12px !important;
        }
    }

    body {
        height: 100svh;
        -webkit-font-smoothing: antialiased;
        margin: 0;
        transition: .5s opacity;
    }
    
    :global(body.ready) {
        opacity: 1 !important;
    }

    p {
        margin: 0;
    }
    
    input:-webkit-autofill {transition: background-color 5000s ease-in-out 0s;}
  
`

export default App
