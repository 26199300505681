import { useIntl } from 'react-intl'
import { useMemo } from 'react'
import FormatService from 'services/FormatService'
import useCardListQuery from 'src/queries/useCardListQuery'

const useBankCardOptions = () => {
    const intl = useIntl()
    const cards = useCardListQuery()

    const cardOptions = useMemo(
        () =>
            cards.isFetched
                ? cards.data?.list.map((card) => ({
                      value: card.cardid,
                      text:
                          card.status === 'AWAIT'
                              ? `${card.cardname} (${intl.formatMessage({ defaultMessage: 'Not activated' })})`
                              : `${card.cardname} (•• ${card.pan.slice(-4)})`,
                      suffix: `${FormatService.money(card.lastbalance)} ${card.currency}`,
                      payload: {
                          id: card.cardid,
                          balance: card.lastbalance,
                          status: card.status,
                      },
                  })) || []
                : [],
        [cards.isFetched],
    )

    return {
        options: cardOptions,
        query: cards,
    }
}

export default useBankCardOptions
