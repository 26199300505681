import * as React from 'react'
import { Button, Space, Title } from 'capricorn-ui'
import { FormattedMessage } from 'react-intl'
import { NavLink } from 'react-router-dom'
import { ROUTE } from 'constants/routes'
import ErrorBoundaryContainer from 'containers/ErrorBoundaryContainer'
import Footer from './Footer'
import Link from 'containers/Link'
import LogoSvg from 'assets/icons/logo-no-slogan.svg'
import ThemeSwitcher from 'components/molecules/ThemeSwitcher'
import styled from 'astroturf/react'

const activeStyle = { color: 'var(--main-text-color)' }

const AuthTemplate: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    return (
        <AuthPageStyled>
            {ENV === 'browser' && (
                <Header>
                    <Link href="https://practika.net">
                        <LogoSvg width={125} />
                    </Link>
                    <Space align="center" size={24}>
                        <ThemeSwitcher />
                        {/*<SwitchLang dropdownProps={{ position: 'bottom-end' }} />*/}
                    </Space>
                </Header>
            )}
            <Content isMobile={ENV !== 'browser'}>
                {ENV === 'browser' && (
                    <SwitchPage>
                        <NavLink to={ROUTE.SIGN_UP} style={({ isActive }) => (isActive ? activeStyle : {})}>
                            <FormattedMessage defaultMessage="Регистрация" />
                        </NavLink>
                        <Delimiter>/</Delimiter>
                        <NavLink to={ROUTE.SIGN_IN} style={({ isActive }) => (isActive ? activeStyle : {})}>
                            <FormattedMessage defaultMessage="Авторизация" />
                        </NavLink>
                    </SwitchPage>
                )}
                <ErrorBoundaryContainer>{children}</ErrorBoundaryContainer>
                {ENV !== 'browser' && <div />}
            </Content>
            {ENV === 'browser' && <FooterStyled posStatic />}
        </AuthPageStyled>
    )
}

const FooterStyled = styled(Footer)`
    margin-bottom: 0;
`

const Header = styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 48px;
    box-shadow: 10px 0 5px -2px rgba(0, 0, 0, 0.1);
    background: #fff;
    background: var(--bg-color-1);

    svg {
        color: var(--main-text-color);
    }

    @media (max-width: 600px) {
        padding: 8px 28px 16px;
    }
`

const AuthPageStyled = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100vw;
    min-height: 100vh;
    box-sizing: border-box;
    background: var(--bg-color-2) url('../../assets/auth-bg.image.svg') no-repeat 100% center;
    background-size: 60%;

    @media (max-width: 600px) {
        background: none;
        width: 100%;
        min-height: 100svh;
    }
`

const Content = styled.main<{ isMobile?: boolean }>`
    box-sizing: border-box;
    padding: 0 12%;

    @media (max-width: 600px) {
        padding: 24px 28px 0;
    }

    &.isMobile {
        flex-direction: column;
        display: flex;
        justify-content: space-between;
        flex: 1;
        padding: 0;
    }
`

export const AuthPageTitle = styled(Title)`
    font-size: 70px !important;
    font-weight: 900 !important;
    line-height: 1.15 !important;
    margin-bottom: 0 !important;

    @media (max-width: 768px) {
        font-size: 50px !important;
    }

    @media (max-width: 600px) {
        margin-top: 0 !important;
        font-weight: 400 !important;
        font-size: 34px !important;
    }
`

export const SwitchPage = styled.div`
    margin-bottom: 30px;
    font-size: 32px;
    font-weight: 700;

    a {
        text-decoration: none;
        color: var(--main-second-text-color);
    }

    @media (max-width: 768px) {
        font-size: 24px;
    }

    @media (max-width: 512px) {
        font-size: 18px;
    }
`

export const Delimiter = styled.div`
    display: inline-block;
    margin: 0 28px;
    color: #d9dde4;

    @media (max-width: 512px) {
        margin: 0 10px;
    }
`

export const AuthStepInfo = styled.p`
    max-width: 700px;
    font-size: 18px;

    span {
        color: #2281ff;
    }

    b {
        font-weight: 600;
    }

    a {
        text-decoration: none;
        color: #2281ff;
        transition: 0.25s color;

        &:hover {
            color: #7ccefd;
        }
    }

    @media (max-width: 500px) {
        font-size: 15px;
    }
`

export const ResendInfo = styled.p<{ visible?: boolean }>`
    font-size: 14px;
    color: #b8bdc4;
    margin: 0 !important;
    opacity: 0;
    visibility: hidden;
    transition:
        0.25s opacity,
        0.25s visibility;

    &.visible {
        opacity: 1;
        visibility: visible;
    }
`

export const AuthButton = styled(Button)`
    justify-content: center;
    font-size: 20px;

    @media (min-width: 600px) {
        align-self: flex-start;
    }
`

export default AuthTemplate
