import { DateInput } from 'capricorn-ui'
import { DateInputProps } from 'capricorn-ui/DateInput'
import { useIntl } from 'react-intl'
import Link from 'containers/Link'
import React, { FC } from 'react'
import styled from 'astroturf/react'

const DateInputWithSidebar: FC<DateInputProps> = ({ onlyCalendar, ...props }) => {
    const intl = useIntl()

    const onChangeDate = (dateSelector: string) => {
        const from = new Date()
        from.setHours(0, 0, 0, 0)
        const to = new Date()

        switch (dateSelector) {
            case 'all-time':
                from.setFullYear(2023, 0, 1)
                break
            case 'yesterday':
                from.setDate(from.getDate() - 1)
                to.setDate(to.getDate())
                to.setHours(-1, 59, 59, 999)
                break
            case 'week':
                from.setDate(from.getDate() - from.getDay() + 1)
                break
            case 'prev-month':
                from.setDate(1)
                from.setMonth(from.getMonth() - 1)
                to.setDate(1)
                to.setHours(-1, 59, 59, 999)
                break
            case 'current-month':
                from.setDate(1)
                break
            case 'last-3-months':
                from.setMonth(from.getMonth() - 3)
                to.setHours(-1, 59, 59, 999)
                break
        }

        props.onChange([from, to])
    }

    return (
        <Wrap>
            <DateInput
                {...props}
                value={props.value}
                onChange={props.onChange}
                onlyCalendar={onlyCalendar}
                locale="en"
                dropdownSidebar={
                    <DateSelectors onlyCalendar={onlyCalendar}>
                        <LinkStyled onClick={() => onChangeDate('today')}>
                            {intl.formatMessage({ defaultMessage: 'Today' })}
                        </LinkStyled>
                        <LinkStyled onClick={() => onChangeDate('yesterday')}>
                            {intl.formatMessage({ defaultMessage: 'Yesterday' })}
                        </LinkStyled>
                        <LinkStyled onClick={() => onChangeDate('week')}>
                            {intl.formatMessage({ defaultMessage: 'Current week' })}
                        </LinkStyled>
                        <LinkStyled onClick={() => onChangeDate('current-month')}>
                            {intl.formatMessage({ defaultMessage: 'Current month' })}
                        </LinkStyled>
                        <LinkStyled onClick={() => onChangeDate('prev-month')}>
                            {intl.formatMessage({ defaultMessage: 'Previous month' })}
                        </LinkStyled>
                        <LinkStyled onClick={() => onChangeDate('last-3-months')}>
                            {intl.formatMessage({ defaultMessage: 'Last 3 months' })}
                        </LinkStyled>
                        <LinkStyled onClick={() => onChangeDate('all-time')}>
                            {intl.formatMessage({ defaultMessage: 'All time' })}
                        </LinkStyled>
                    </DateSelectors>
                }
            />
        </Wrap>
    )
}

const Wrap = styled.div`
    width: 100%;

    @media (max-width: 600px) {
        position: relative;
        margin-top: 50px;
    }
`

const LinkStyled = styled(Link)`
    display: block;
    font-weight: 600;
    margin: 15px 0;

    @media (max-width: 600px) {
        background: var(--bg-color-3);
        border-radius: 6px;
        font-size: 13px;
        padding: 3px 12px;
        white-space: nowrap;
        color: var(--main-text-color);
        font-weight: 400;
    }
`

const DateSelectors = styled.div<{ onlyCalendar?: boolean }>`
    width: 160px;
    padding: 5px 20px 0;
    border-right: 1px solid var(--light-bg-color);

    button {
        width: 100%;
        margin-bottom: 10px;
    }

    &.onlyCalendar {
        padding: 0;
    }

    @media (max-width: 600px) {
        position: absolute;
        display: flex;
        gap: 10px;
        overflow: auto;
        width: calc(100% + 40px);
        top: -60px;
        left: -20px;
        right: -20px;
        box-sizing: border-box;
        padding-left: 20px !important;
        padding-right: 20px !important;

        &::-webkit-scrollbar {
            display: none;
        }
    }
`

export default DateInputWithSidebar
