import * as yup from 'yup'
import { FieldValues, UseFormReturn } from 'react-hook-form/dist/types'
import { UseFormProps, useForm } from 'react-hook-form'
import { useCallback, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useStore } from 'src/store'
import { yupResolver } from '@hookform/resolvers/yup'

export type EnhancedForm<TFieldValues extends FieldValues = FieldValues> = UseFormReturn<TFieldValues> & {
    setValues: (obj: FieldValues) => void
    updateSchema: (newSchema: yup.ObjectSchema<any>) => void
    setSchema: (newSchema: yup.ObjectSchema<any>) => void
}

export type useCustomFormSchema = yup.ObjectSchema<any>

function useFormEnhanced<TFieldValues extends FieldValues = FieldValues>({
    schema,
    ...props
}: Omit<
    UseFormProps<TFieldValues> & {
        schema?: () => yup.ObjectSchema<any>
    },
    'resolver'
>): EnhancedForm<TFieldValues> {
    const intl = useIntl()
    const { notice, common } = useStore()
    const [schemaState, setSchema] = useState<useCustomFormSchema | null>(schema ?? null)

    const formProps = useForm({
        ...props,
        resolver: schemaState ? yupResolver(schemaState) : undefined,
        defaultValues: props.defaultValues,
    })

    useEffect(() => {
        // Обновляет локализацию для ошибок в случае смены локали
        if (formProps.formState.isSubmitted && !formProps.formState.isSubmitSuccessful) {
            formProps.clearErrors()
        }
    }, [common.locale])

    const { isSubmitted, isSubmitSuccessful, submitCount } = formProps.formState

    const setValues = (obj: FieldValues) => {
        formProps.reset(obj, { keepDefaultValues: true })
    }

    const updateSchema = useCallback((newSchema: useCustomFormSchema<TFieldValues>) => {
        setSchema((oldSchema) => {
            if (oldSchema) {
                return yup.object().shape({
                    ...oldSchema.fields,
                    ...newSchema.fields,
                }) as useCustomFormSchema<TFieldValues>
            }
            return newSchema
        })
    }, [])

    useEffect(() => {
        if (isSubmitted && !isSubmitSuccessful) {
            notice.show({
                title: intl.formatMessage({ defaultMessage: 'Error' }),
                message: intl.formatMessage({
                    defaultMessage: 'Please fill in the fields according to the requirements',
                }),
            })
        }
    }, [isSubmitted, isSubmitSuccessful, submitCount])

    return {
        ...formProps,
        updateSchema,
        setSchema,
        setValues,
    }
}

export default useFormEnhanced
