import { API } from 'constants/api'
import { AccountType, UnknownMember } from '../interfaces/VerificationModel'
import { VerificationStatus } from 'src/store/authStore'
import ApiService from './ApiService'

export type GetAmlResponse = {
    // Наш статус
    status: string
    redirecturl: string
    result?: {
        documentData: {
            country: string
            dateOfBirth: string
            dateOfExpiration: string
            dateOfIssue: string
            documentNumber: string
            documentType: string
            firstName: string
            gender: string
            lastName: string
            nationality: string
        }
        identificationData?: {
            failReason?: null | string
            // Статус ондаты
            status: string
        }
    }
}

export type PrescoringRisk = 'Prohibited' | 'High Risk' | 'Very High risk' | 'Low risk' | 'Medium risk'

interface IVerificationService {
    getVerificationData: () => Promise<{
        data: UnknownMember
        stage: AccountType
        rejectionReason?: string
        version?: number
        status: VerificationStatus
    }>
    startAML: () => Promise<{
        identificationId: string
        redirectUrl: string
    }>
    getAML: () => Promise<GetAmlResponse>
    getTimeToWait: () => Promise<{
        ttwSeconds: number
        hasApprove: boolean
        canNow: boolean
    }>
    saveVerificationData: (accountType: AccountType, data: UnknownMember) => Promise<void>
    setFinalStatus: () => Promise<void>
    getPreScoring: () => Promise<PrescoringRisk>
}

const VerificationService: IVerificationService = {
    saveVerificationData: async (accountType, data) => {
        return await ApiService.request(`${API.MAKE_VERIFICATION}/update`, {
            data,
            stage: accountType,
        })
    },
    getVerificationData: async () => {
        return await ApiService.request(`${API.MAKE_VERIFICATION}/current`)
    },
    startAML: () => ApiService.request(API.START_AML, {}, 'POST'),
    getAML: () => ApiService.request(API.GET_AML),
    getTimeToWait: () => ApiService.request(API.TIME_TO_WAIT_AML),
    setFinalStatus: () => ApiService.request(`${API.MAKE_VERIFICATION}/submit`, {}, 'POST'),
    getPreScoring: () => {
        // return new Promise((resolve) => resolve('High Risk'))
        return ApiService.request('/cra/prescore')
    },
}

export default VerificationService
