import * as yup from 'yup'
import { AuthButton, AuthPageTitle, AuthStepInfo } from '../../templates/AuthTemplate'
import { FormattedMessage, useIntl } from 'react-intl'
import { ROUTE } from 'src/constants/routes'
import { RegistrationChildProps } from '../RegistrationPage'
import { Space } from 'capricorn-ui'
import { useNavigate } from 'react-router-dom'
import { useStore } from 'src/store'
import AnalyticService from 'services/AnalyticService'
import AuthService from 'src/services/AuthService'
import Bugsnag from '@bugsnag/js'
import ControlledCheckbox from '../../../containers/ControlledCheckbox'
import ControlledTextInput from '../../../containers/ControlledTextInput'
import EmptyModal from 'components/modals/EmptyModal'
import Link from 'containers/Link'
import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import Step2 from 'components/pages/registration-steps/Step2'
import styled from 'astroturf/react'
import useFormEnhanced from 'src/hooks/useFormEnhanced'

interface FormData {
    password: string
    passwordConfirmation: string
}

const Step6: React.FC<RegistrationChildProps> = ({ state, setState, sendOTP }) => {
    const navigate = useNavigate()
    const intl = useIntl()
    const [isOpenedModal, setIsOpenedModal] = useState(false)

    const schema = () =>
        yup.object().shape({
            password: yup.string().required().min(8),
            passwordConfirmation: yup
                .string()
                .oneOf([yup.ref('password'), null])
                .min(8)
                .required(),
            terms: yup
                .boolean()
                .oneOf([true], intl.formatMessage({ defaultMessage: 'Please fill out this field' }))
                .required(),
        })

    const { control, handleSubmit, getValues } = useFormEnhanced({
        schema,
    })

    const [loading, setLoading] = useState(false)
    const { notice, auth, common } = useStore()

    const finishSubmit = async (formData?: FormData) => {
        try {
            setLoading(true)

            const user = await AuthService.signUpPassword(
                state.login,
                formData?.password || getValues().password,
                formData?.code ? formData.code : state.code,
            )
            Bugsnag.setUser(user.id?.toString(), user.email, user.login)
            AnalyticService.trackEvent('SIGN_UP_FINISH')
            AnalyticService.setUser(user)

            auth.setUser(user)
            auth.setState('fetched')

            if (ENV === 'ios') {
                navigate(ROUTE.MOBILE_PIN_CODE)
            } else {
                navigate(ROUTE.HOME)
            }
        } catch (e) {
            // Долгий юзер - код протух (20 мин)
            if (e?.response?.data?.error === 'invalid-code') {
                setIsOpenedModal(true)
            } else {
                notice.showCommonError(e)
            }
        }

        setLoading(false)
    }

    const resendSuccess = (code: string) => {
        setIsOpenedModal(false)
        finishSubmit({ code })
    }

    return (
        <>
            {isOpenedModal &&
                ReactDOM.createPortal(
                    <EmptyModal
                        maxWidth={800}
                        title={<FormattedMessage defaultMessage="Сессия истекла, пожалуйста, введите код повторно." />}
                        onCancel={() => setIsOpenedModal(false)}
                    >
                        <Step2
                            state={state}
                            setState={setState}
                            sendOTP={sendOTP}
                            isModalView
                            onSuccess={resendSuccess}
                        />
                    </EmptyModal>,
                    document.getElementById('modal-portal')!,
                )}
            <form onSubmit={handleSubmit(finishSubmit)}>
                <Space column>
                    <AuthPageTitle>
                        <FormattedMessage defaultMessage="Придумайте пароль" />
                    </AuthPageTitle>
                    <AuthStepInfo>
                        <FormattedMessage defaultMessage="Пароль должен содержать не менее 8-ми символов." />
                    </AuthStepInfo>
                    <ControlledTextInput
                        type="password"
                        name="password"
                        control={control}
                        label={intl.formatMessage({ defaultMessage: 'Пароль' })}
                        maxWidth={600}
                    />
                    <ControlledTextInput
                        type="password"
                        name="passwordConfirmation"
                        control={control}
                        label={intl.formatMessage({ defaultMessage: 'Повтор пароля' })}
                        maxWidth={600}
                    />
                    <TermsCheckbox>
                        <ControlledCheckbox name="terms" control={control} style={{ alignItems: 'flex-start' }}>
                            {intl.formatMessage(
                                {
                                    defaultMessage:
                                        'I have read and agree with <a1>Terms and Conditions</a1> and <a2>Privacy Policy</a2>',
                                },
                                {
                                    a1: (word: string) => (
                                        <Link
                                            target="_blank"
                                            href={`https://practika.net/en/documents/terms-and-conditions`}
                                        >
                                            {word}
                                        </Link>
                                    ),
                                    a2: (word: string) => (
                                        <Link target="_blank" href={`https://practika.net/en/documents/privacy-policy`}>
                                            {word}
                                        </Link>
                                    ),
                                },
                            )}
                        </ControlledCheckbox>
                    </TermsCheckbox>
                    <AuthButton type="submit" loading={loading}>
                        <FormattedMessage defaultMessage="Зарегистрироваться" />
                    </AuthButton>
                </Space>
            </form>
        </>
    )
}

const TermsCheckbox = styled.div`
    max-width: 560px;
`

export default Step6
