import * as React from 'react'
import styled from 'astroturf/react'

const Delimiter: React.FC = ({ children, ...props }) => <Styled {...props}>{children}</Styled>

const Styled = styled.div`
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    height: 1px;
    margin-top: -1px;
    background: var(--light-bg-color);
`

export default Delimiter
