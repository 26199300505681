import * as React from 'react'
import * as yup from 'yup'
import { AuthPageTitle } from '../../templates/AuthTemplate'
import { Button, Space } from 'capricorn-ui'
import { ForgotPasswordChildProps } from '../ForgotPasswordPage'
import { FormattedMessage, useIntl } from 'react-intl'
import { ROUTE } from 'src/constants/routes'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { useStore } from 'src/store'
import { useWindowSize } from 'react-use'
import AuthService from 'src/services/AuthService'
import ControlledTextInput from '../../../containers/ControlledTextInput'
import useFormEnhanced from 'src/hooks/useFormEnhanced'

const schema = () =>
    yup.object().shape({
        password: yup.string().required().min(8),
        passwordConfirmation: yup
            .string()
            .oneOf([yup.ref('password'), null])
            .min(8),
    })

const Step3: React.FC<ForgotPasswordChildProps> = ({ state }) => {
    const navigate = useNavigate()
    const intl = useIntl()
    const windowWidth = useWindowSize().width
    const { control, handleSubmit, getValues } = useFormEnhanced({
        schema,
    })

    const [loading, setLoading] = useState(false)
    const { notice } = useStore()

    const finishSubmit = async () => {
        try {
            setLoading(true)

            const { password } = getValues()

            await AuthService.setPassword({
                phone: state.phone,
                otp: state.code,
                password,
            })

            navigate(`${ROUTE.SIGN_IN}?success=true`)
        } catch (e) {
            notice.showCommonError(e)
        }

        setLoading(false)
    }

    return (
        <form onSubmit={handleSubmit(finishSubmit)}>
            <Space align="flex-start" size={windowWidth < 500 ? 24 : 40} column>
                <AuthPageTitle>
                    <FormattedMessage defaultMessage="Enter new password" />
                </AuthPageTitle>
                <ControlledTextInput
                    type="password"
                    name="password"
                    control={control}
                    label={intl.formatMessage({ defaultMessage: 'Пароль' })}
                    maxWidth={600}
                />
                <ControlledTextInput
                    type="password"
                    name="passwordConfirmation"
                    control={control}
                    label={intl.formatMessage({ defaultMessage: 'Повтор пароля' })}
                    maxWidth={600}
                />
                <Button type="submit" loading={loading}>
                    <FormattedMessage defaultMessage="Установить новый пароль" />
                </Button>
            </Space>
        </form>
    )
}

export default Step3
