import { Dropdown } from 'capricorn-ui'
import { DropdownProps } from 'capricorn-ui/lib/Dropdown'
import { useCopyToClipboard } from 'react-use'
import CopySvg from 'src/assets/icons/copy.svg'
import React, { useState } from 'react'
import styled from 'astroturf/react'

interface Props {
    children: React.ReactNode
    textToCopy?: string
    successCopiedText?: React.ReactNode
    noIcon?: boolean
    innerRef?: React.RefObject<HTMLDivElement>
    highlightText?: boolean
    dropdownProps?: DropdownProps
}

const Copy: React.FC<Props> = ({
    children,
    textToCopy,
    successCopiedText,
    noIcon,
    innerRef,
    highlightText,
    dropdownProps,
    ...rest
}) => {
    const [active, setActive] = useState(false)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [state, copyToClipboard] = useCopyToClipboard()

    const onCopy = (): void => {
        if (children) {
            copyToClipboard(textToCopy || (children as string))
        }
        setActive(true)

        setTimeout(() => setActive(false), 1000)
    }

    return (
        <Container {...rest} ref={innerRef} onClick={onCopy}>
            <div>
                <Text highlight={highlightText}>{children}</Text>
                {!noIcon && <CopySvgStyled />}
                <Dropdown {...dropdownProps} active={active} position={dropdownProps?.position || 'bottom-end'}>
                    {successCopiedText || 'Copied'}
                </Dropdown>
            </div>
        </Container>
    )
}

const CopySvgStyled = styled(CopySvg)`
    color: #2281ff;
`

const Text = styled.span<{ highlight?: boolean }>`
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &.highlight {
        padding: 4px 8px;
        background: var(--bg-color-2);
        border-radius: 4px;
    }
`

const Container = styled.div`
    position: relative;
    color: inherit;
    cursor: pointer;

    & > div {
        display: inline-flex;
        position: relative;
        align-items: center;
        max-width: 100%;
    }

    ${CopySvgStyled} {
        margin-left: 8px;
        min-width: 13px;
    }
`

export default Copy
