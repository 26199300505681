import * as React from 'react'
import * as yup from 'yup'
import { AuthButton, AuthPageTitle, AuthStepInfo } from '../../templates/AuthTemplate'
import { FormattedMessage, useIntl } from 'react-intl'
import { LoginChildProps } from '../LoginPage'
import { ROUTE } from 'src/constants/routes'
import { Space, Tip } from 'capricorn-ui'
import { VERIFY_CODE_TEL_LENGTH } from 'src/constants/config'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useStore } from 'src/store'
import { useWindowSize } from 'react-use'
import AnalyticService from 'services/AnalyticService'
import AuthService from 'src/services/AuthService'
import Bugsnag from '@bugsnag/js'
import ControlledTextInput from '../../../containers/ControlledTextInput'
import Link from 'containers/Link'
import styled from 'astroturf/react'
import useFormEnhanced from 'src/hooks/useFormEnhanced'

const Step2Google2FA: React.FC<LoginChildProps> = ({ state, setState }) => {
    const navigate = useNavigate()
    const { auth } = useStore()
    const intl = useIntl()
    const windowWidth = useWindowSize().width

    const schema = () =>
        yup.object().shape({
            code: yup
                .string()
                .required()
                .min(
                    VERIFY_CODE_TEL_LENGTH,
                    intl.formatMessage(
                        { defaultMessage: 'Must be exactly {count} digits' },
                        { count: VERIFY_CODE_TEL_LENGTH },
                    ),
                ),
        })

    const { control, handleSubmit, watch } = useFormEnhanced({
        schema,
        defaultValues: state,
    })

    const [loading, setLoading] = useState(false)
    const { notice } = useStore()

    const handleToLoginChange = () => {
        setState({
            ...state,
            step: 1,
        })
    }
    const handleCheckOtp = async () => {
        setLoading(true)

        try {
            const user = await AuthService.signIn(state.login, state.password, watch('code') || '')
            Bugsnag.setUser(user.id?.toString(), user.email, user.login)
            AnalyticService.setUser(user)

            auth.setUser(user)
            auth.setState('fetched')

            const search = new URLSearchParams(window.location.search)

            navigate(search.get('from') || ROUTE.HOME)
        } catch (e) {
            if (e?.response?.data?.error === 'otp-required-or-invalid-gc') {
                notice.show({
                    title: <FormattedMessage defaultMessage="Недействительный код" />,
                    message: <FormattedMessage defaultMessage="Проверьте правильность введенных данных." />,
                })
            } else {
                notice.showCommonError(e)
            }
        }

        setLoading(false)
    }

    useEffect(() => {
        if (watch('code')?.length === VERIFY_CODE_TEL_LENGTH) {
            handleCheckOtp()
        }
    }, [watch('code')])

    return (
        <form onSubmit={handleSubmit(handleCheckOtp)}>
            <Space column size={windowWidth < 500 ? 24 : 40}>
                <AuthPageTitle>
                    <FormattedMessage defaultMessage="Подтвердите вход" />
                    {/*<AuthTypeTip>*/}
                    {/*    <AuthTypeTipTitle>*/}
                    {/*        <FormattedMessage defaultMessage="Что такое двухфакторная аутентификация?" />*/}
                    {/*    </AuthTypeTipTitle>*/}
                    {/*    <p>*/}
                    {/*        <FormattedMessage*/}
                    {/*            defaultMessage="Двухфакторная аутентификация на для того-то, чтобы то-то. {br}{br}Вы можете выбрать для себя удобный способ аутентификации в <b>Мой профиль / Настройки безопасности / Аутентификация</b>"*/}
                    {/*            values={{*/}
                    {/*                b: (word: string) => <b>{word}</b>,*/}
                    {/*                br: <br />,*/}
                    {/*            }}*/}
                    {/*        />*/}
                    {/*    </p>*/}
                    {/*</AuthTypeTip>*/}
                </AuthPageTitle>
                <AuthStepInfo>
                    <FormattedMessage
                        defaultMessage="Get a verification code from the <b>Google Authenticator app</b>"
                        values={{ b: (word: string) => <b>{word}</b> }}
                    />
                </AuthStepInfo>
                <StyledSpace column={windowWidth < 768} size={40}>
                    <ControlledTextInput
                        name="code"
                        type="code"
                        control={control}
                        autoFocus
                        codeLength={VERIFY_CODE_TEL_LENGTH}
                        inputMode="numeric"
                        data-testid="code-input"
                    />
                    <AuthButton data-testid="submit-button" type="submit" loading={loading}>
                        <FormattedMessage defaultMessage="Подтвердить" />
                    </AuthButton>
                </StyledSpace>
                <Space column={windowWidth < 768} size={windowWidth < 768 ? 12 : 40}>
                    <Tip
                        customButton={<span />}
                        title={
                            <Link bold>
                                {' '}
                                <FormattedMessage defaultMessage="Нет доступа к Google Authenticator?" />
                            </Link>
                        }
                    >
                        <CodeNotCome>
                            <FormattedMessage
                                defaultMessage="Свяжитесь с тех. поддержкой <b>support@practika.net</b>"
                                values={{ b: (word: string) => <b>{word}</b> }}
                            />
                        </CodeNotCome>
                    </Tip>
                    <Link bold onClick={handleToLoginChange}>
                        <FormattedMessage defaultMessage="Вернуться" />
                    </Link>
                </Space>
            </Space>
        </form>
    )
}

const StyledSpace = styled(Space)`
    @media (max-width: 768px) {
        margin-top: -20px !important;
    }
`

const CodeNotCome = styled.div`
    font-size: 12px;

    ol {
        margin: 10px 0 0 0;
        padding: 0 0 0 15px;
    }
`

export default Step2Google2FA
