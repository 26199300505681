import * as React from 'react'
import * as yup from 'yup'
import { AuthButton, AuthPageTitle } from '../../templates/AuthTemplate'
import { FormattedMessage, useIntl } from 'react-intl'
import { RegistrationChildProps } from '../RegistrationPage'
import { Space } from 'capricorn-ui'
import { useState } from 'react'
import { useStore } from 'src/store'
import AuthService from 'src/services/AuthService'
import Bugsnag from '@bugsnag/js'
import ControlledTextInput from '../../../containers/ControlledTextInput'
import useFormEnhanced from 'src/hooks/useFormEnhanced'

interface FormData {
    email: string
}

const Step3: React.FC<Omit<RegistrationChildProps, 'sendOTP'>> = ({ state, setState }) => {
    const { notice } = useStore()
    const intl = useIntl()

    const schema = () =>
        yup.object().shape({
            email: yup
                .string()
                .email(intl.formatMessage({ defaultMessage: 'Invalid email address' }))
                .required(),
        })

    const { control, handleSubmit } = useFormEnhanced({
        schema,
        defaultValues: state,
    })

    const [loading, setLoading] = useState(false)

    const onSubmit = async ({ email }: FormData) => {
        setLoading(true)

        AuthService.signUpEmail(state.phone, email)
            .then(() => {
                setState({ ...state, email, step: 4 })
            })
            .catch((e) => {
                switch (e?.response?.data?.error) {
                    case 'email-already-taken':
                        notice.show({
                            title: (
                                <FormattedMessage defaultMessage="Пользователь с указанным e-mail уже зарегистрирован" />
                            ),
                            message: <FormattedMessage defaultMessage="Пожалуйста, введите другой email" />,
                        })
                        break
                    // Регистрация не завершена
                    case 'another-email-already-specified':
                        notice.show({
                            title: <FormattedMessage defaultMessage="Another email already specified" />,
                        })
                        break
                    default:
                        notice.showCommonError(e)
                }
            })
            .finally(() => {
                Bugsnag.setUser(undefined, email, state.phone)
                setLoading(false)
            })
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Space column>
                <AuthPageTitle>
                    <FormattedMessage defaultMessage="Введите E-mail" />
                </AuthPageTitle>
                <ControlledTextInput
                    name="email"
                    control={control}
                    label="E-mail"
                    maxWidth={600}
                    autoFocus
                    inputMode="email"
                    fieldTip={
                        <FormattedMessage defaultMessage="Мы вышлем на него ссылку для подтверждения, которая будет доступна в течение 24 часов" />
                    }
                />
                <AuthButton type="submit" loading={loading}>
                    <FormattedMessage defaultMessage="Продолжить" />
                </AuthButton>
            </Space>
        </form>
    )
}

export default Step3
