import * as React from 'react'
import { createRoot } from 'react-dom/client'
import AnalyticService from 'services/AnalyticService'
import App from './components/App'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

AnalyticService.init()

Bugsnag.start({
    apiKey: '6112f855e2bef6fd1e452907371d4cce',
    plugins: [new BugsnagPluginReact()],
    appVersion: PACKAGE_VERSION,
    releaseStage: STAGE,
    collectUserIp: false,
    enabledBreadcrumbTypes: ['error', 'log', 'navigation', 'user'],
    enabledReleaseStages: ['test', 'production'],
    onBreadcrumb: function (breadcrumb) {
        if (breadcrumb.message === 'UI click') {
            breadcrumb.message = `UI click: ${breadcrumb.metadata.targetText || breadcrumb.metadata.targetSelector}`
            breadcrumb.metadata.targetText = undefined
            breadcrumb.metadata.targetSelector = undefined
        }
        // discard Yandex.Metrika and build.json - checking for frontend updates
        return !(
            (breadcrumb.type === 'request' &&
                typeof breadcrumb.metadata?.request === 'string' &&
                breadcrumb.metadata?.request?.includes('yandex')) ||
            breadcrumb?.message?.includes('build.json')
        )
    },
})

const BugsnagErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)

const container = document.getElementById('root')
const root = createRoot(container!)

root.render(
    <BugsnagErrorBoundary>
        <App />
    </BugsnagErrorBoundary>,
)
