import { Button } from 'capricorn-ui'
import { ROUTE } from 'constants/routes'
import { useNavigate } from 'react-router-dom'
import React, { FC } from 'react'
import styled from 'astroturf/react'

const NotFoundPage: FC = () => {
    const navigate = useNavigate()

    return (
        <NotFound>
            404 <br /> <span>Page not found</span>
            <br />
            <Button onClick={() => navigate(ROUTE.HOME)}>To home page</Button>
        </NotFound>
    )
}

const NotFound = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 60px;
    height: 100vh;

    span {
        font-size: 20px;
    }
`

export default NotFoundPage
