import { Space } from 'capricorn-ui'
import styled from 'astroturf/react'

export default styled(Space)<{ inverse?: boolean }>`
    @media (max-width: 600px) {
        width: 100%;
        gap: 12px !important;
        flex-direction: column;

        button {
            width: 100%;
            height: 48px;
            justify-content: center;
        }

        &.inverse {
            button:nth-child(2) {
                order: 2;
                background: transparent;
                color: #2281ff;
            }
        }

        &:not(.inverse) {
            button:nth-child(1) {
                order: 1;
                background: transparent;
                color: #2281ff;
            }
        }
    }
`
