import { FormattedMessage } from 'react-intl'
import ErrorSvg from 'src/assets/icons/notice-error.svg'
import Link from 'containers/Link'
import React, { FC, ReactNode } from 'react'
import styled from 'astroturf/react'

export interface LoadingErrorProps {
    onUpdate?: () => void
    errorId?: string
    children?: ReactNode
}

export const LoadingError: FC<LoadingErrorProps> = ({ onUpdate, errorId, children, ...rest }) => {
    return (
        <Content {...rest}>
            <ErrorSvg width={32} />
            {children}
            {onUpdate && (
                <div>
                    <FormattedMessage
                        defaultMessage="Please <a>try again</a> later or contact support team."
                        values={{
                            a: (word: string) => (
                                <Link onClick={onUpdate} bold>
                                    {word}
                                </Link>
                            ),
                        }}
                    />
                </div>
            )}
            {/*{errorId && (*/}
            {/*    <ErrorId>*/}
            {/*        <FormattedMessage defaultMessage="Код ошибки" />:*/}
            {/*        <Copy textToCopy={errorId} highlightText>*/}
            {/*            {errorId}*/}
            {/*        </Copy>*/}
            {/*    </ErrorId>*/}
            {/*)}*/}
        </Content>
    )
}
//
// const ErrorId = styled.div`
//     display: flex;
//     align-items: center;
//     flex-wrap: wrap;
//     font-size: 12px;
// `

const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #ff5c21;
    border-radius: 4px;
    padding: 30px 15px 15px;
    box-sizing: border-box;
    text-align: center;
    line-height: 1.6;
    font-size: 14px;
    width: 100%;

    svg {
        color: #ff5c21;
    }

    & > * {
        margin-bottom: 20px;
    }
`
