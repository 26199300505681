import * as React from 'react'
import * as yup from 'yup'
import { AuthPageTitle, AuthStepInfo, ResendInfo } from '../../templates/AuthTemplate'
import { Button, Link, Space, Tip } from 'capricorn-ui'
import { ForgotPasswordChildProps } from '../ForgotPasswordPage'
import { FormattedMessage, useIntl } from 'react-intl'
import { RESEND_VERIFY_CODE_DELAY, VERIFY_CODE_TEL_LENGTH } from 'src/constants/config'
import { secondsToDigitsTime } from 'src/utils'
import { useEffect, useState } from 'react'
import { useStore } from 'src/store'
import { useTimer } from 'src/hooks'
import { useWindowSize } from 'react-use'
import AuthService from 'src/services/AuthService'
import ControlledTextInput from '../../../containers/ControlledTextInput'
import styled from 'astroturf/react'
import useFormEnhanced from 'src/hooks/useFormEnhanced'

const Step2: React.FC<ForgotPasswordChildProps & { sendOTP: (phone: string) => void }> = ({
    state,
    setState,
    sendOTP,
}) => {
    const intl = useIntl()

    const schema = () =>
        yup.object().shape({
            code: yup
                .string()
                .required()
                .min(
                    VERIFY_CODE_TEL_LENGTH,
                    intl.formatMessage(
                        { defaultMessage: 'Must be exactly {count} digits' },
                        { count: VERIFY_CODE_TEL_LENGTH },
                    ),
                ),
        })

    const windowWidth = useWindowSize().width
    const { control, handleSubmit, getValues, watch, setValue } = useFormEnhanced({
        schema,
    })

    const [resendCodeTimer, startResendCodeTimer, resetResendCodeTimer] = useTimer(RESEND_VERIFY_CODE_DELAY)
    const [loading, setLoading] = useState(false)
    const { notice } = useStore()

    useEffect(() => {
        startResendCodeTimer()

        return () => {
            resetResendCodeTimer()
        }
    }, [])

    const handleCheckOtp = async () => {
        setLoading(true)

        try {
            const { code } = getValues()

            await AuthService.checkOtp(state.phone, code)

            setState({ ...state, code, step: 3 })
        } catch (e) {
            if (e?.response?.data['check-otp'] && e.response?.data['check-otp'] === 'invalid') {
                notice.show(
                    {
                        title: <FormattedMessage defaultMessage="Недействительный код" />,
                        message: (
                            <FormattedMessage defaultMessage="Проверьте правильность введенных данных или попробуйте пройти процедуру подтверждения заново." />
                        ),
                    },
                    'infinity',
                )
            } else {
                notice.showCommonError(e)
            }
        }

        setLoading(false)
    }

    const resendCode = async () => {
        resetResendCodeTimer()
        startResendCodeTimer()
        setValue('code', '')
        setLoading(true)
        await sendOTP(state.phone)
        setLoading(false)
    }

    useEffect(() => {
        if (watch('code')?.length === VERIFY_CODE_TEL_LENGTH) {
            handleCheckOtp()
        }
    }, [watch('code')])

    return (
        <form onSubmit={handleSubmit(handleCheckOtp)}>
            <Space size={windowWidth < 500 ? 32 : 40} column>
                <AuthPageTitle>
                    <FormattedMessage defaultMessage="Восстановление пароля" />
                </AuthPageTitle>
                <AuthStepInfo>
                    <FormattedMessage
                        defaultMessage="На телефон <b>{phone}</b> мы отправили смс с кодом для подтверждения. Введите код в поле ниже."
                        values={{
                            phone: `${state.phone[0] + state.phone[1]}*******${state.phone[state.phone.length - 3]}${
                                state.phone[state.phone.length - 2]
                            }${state.phone[state.phone.length - 1]}`,
                            b: (word: string) => <b style={{ color: '#2281ff' }}>{word}</b>,
                        }}
                    />
                </AuthStepInfo>
                <SpaceStyled column={windowWidth < 768} align="flex-start">
                    <ControlledTextInput
                        type="code"
                        name="code"
                        control={control}
                        codeLength={VERIFY_CODE_TEL_LENGTH}
                        autoFocus
                        inputMode="numeric"
                    />
                    <Button type="submit" loading={loading}>
                        <FormattedMessage defaultMessage="Подтвердить" />
                    </Button>
                </SpaceStyled>
                <Space column={windowWidth < 500} size={windowWidth < 500 ? 12 : 32}>
                    <Link bold onClick={() => setState({ ...state, step: 1 })}>
                        <FormattedMessage defaultMessage="Изменить номер" />
                    </Link>
                    <Tip
                        customButton={<span />}
                        title={
                            <Link bold>
                                <FormattedMessage defaultMessage="Не приходит код подтверждения?" />
                            </Link>
                        }
                    >
                        <CodeNotCome>
                            <FormattedMessage
                                defaultMessage="Свяжитесь с тех. поддержкой <b>support@practika.net</b>"
                                values={{ b: (word: string) => <b>{word}</b> }}
                            />
                        </CodeNotCome>
                    </Tip>
                    <Link bold onClick={resendCode} disabled={resendCodeTimer !== 0 || loading}>
                        <FormattedMessage defaultMessage="Отправить еще раз" />
                    </Link>
                </Space>
                <ResendInfo visible={resendCodeTimer !== 0}>
                    <FormattedMessage defaultMessage=" Если код не пришёл, вы сможете отправить его ещё раз через" />{' '}
                    {secondsToDigitsTime(resendCodeTimer)}
                </ResendInfo>
            </Space>
        </form>
    )
}

const SpaceStyled = styled(Space)`
    @media (max-width: 768px) {
        margin-top: -20px !important;
    }
`

const CodeNotCome = styled.div`
    font-size: 12px;

    ol {
        margin: 10px 0 0 0;
        padding: 0 0 0 15px;
    }
`

export default Step2
