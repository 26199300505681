import { API } from 'constants/api'
import { SERVER_TIMEOUT } from 'constants/config'
import { v4 as uuidv4 } from 'uuid'
import Bugsnag from '@bugsnag/js'
import LanguageService from './LanguageService'
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse, Method } from 'axios'

const base =
    ENV === 'ios'
        ? STAGE === 'development'
            ? '/server/direct'
            : `https://${SERVER_URL}/server/direct`
        : '/server/direct'

interface IApiService {
    request: <T>(
        path: string,
        data?: unknown,
        method?: Method,
        headers?: Record<string, string>,
        body?: AxiosRequestConfig,
        noBasePath?: boolean,
    ) => Promise<T>
}

const ApiService: IApiService = {
    request: async (path, data, method, headers, body, noBasePath) => {
        const url = path.includes(API.GOOGLE_2FA)
            ? STAGE === 'development'
                ? '/direct' + path
                : path
            : noBasePath
              ? path
              : base + path
        const locale = LanguageService.getLocale()

        const fetchOptions: AxiosRequestConfig = {
            url,
            data,
            method: method ? method : data ? 'POST' : 'GET',
            timeout: path === API.UPLOAD_FILE ? 60 * 10 * 1000 : SERVER_TIMEOUT,
            headers: {
                Accept: 'application/json',
                'Accept-Language': locale,
                'jwt-token': ENV === 'ios' ? window.localStorage.getItem('jwt-token') : undefined,
                ...headers,
            },
            withCredentials: true,
            ...body,
        }

        return axios(fetchOptions)
            .then((response: AxiosResponse) => {
                Bugsnag.leaveBreadcrumb(
                    `${fetchOptions.method} ${fetchOptions.url}`,
                    {
                        request: fetchOptions.data && {
                            data: fetchOptions.data,
                        },
                        response: fetchOptions.url?.includes('country/mini')
                            ? '[REDACTED LIST OF COUNTRIES]'
                            : {
                                  data: response.data,
                                  status: response.status,
                              },
                    },
                    'request',
                )

                if (ENV === 'ios' && response.headers['set-jwt-token']) {
                    window.localStorage.setItem('jwt-token', response.headers['set-jwt-token'])
                }

                return response.data
            })
            .catch((requestError) => {
                if (requestError?.response?.status === undefined) {
                    throw requestError
                }

                if (requestError?.response?.status === 403 && STAGE !== 'development') {
                    window.location.href = `/sign-in?from=${window.location.pathname}`
                    return
                }

                const error = new Error(
                    `${requestError?.response?.status} Server error: ${
                        requestError?.response?.data?.error || 'undefined error in response'
                    }`,
                ) as Error & {
                    id: string
                    response: AxiosError
                }
                error.response = requestError.response
                error.id = uuidv4()

                if (
                    STAGE !== 'development' &&
                    requestError?.response?.status !== 403 &&
                    !disabledBugsnagErrors.includes(requestError?.response?.data?.error)
                ) {
                    Bugsnag.notify(error, (event) => {
                        const response = requestError.response

                        if (response.config.data) {
                            let parsedConfigData

                            try {
                                parsedConfigData = JSON.parse(response.config.data)
                                if (parsedConfigData.hasOwnProperty('password')) {
                                    response.config.data = {
                                        ...parsedConfigData,
                                        password: 'DELETED',
                                    }
                                }
                            } catch (e) {}
                        }

                        event.addMetadata('Error info', {
                            errorTrackId: error.id,
                            response,
                        })
                    })
                }

                throw error
            })
    },
}

const disabledBugsnagErrors = [
    'otp-required-or-invalid',
    'otp-required-or-invalid-gc',
    'wrong-login-or-password',
    'no-application-found',
]

export default ApiService
