import { ROUTE } from 'constants/routes'
import { Spinner } from 'capricorn-ui'
import { createRoot } from 'react-dom/client'
import ApiService from 'services/ApiService'
import EnvelopeSvg from 'src/assets/icons/envelope.svg'
import React, { useEffect, useState } from 'react'
import styled, { stylesheet } from 'astroturf/react'

interface Props {
    isAuth: boolean
}

const LiveChat = ({ isAuth }: Props) => {
    useEffect(() => {
        ;(function (w, d, v3) {
            w.chaportConfig = {
                appId: '6582ef8f50465178651ec96d',
                language: {
                    source: 'html',
                },
            }

            if (w.chaport) return
            v3 = w.chaport = {}
            v3._q = []
            v3._l = {}
            v3.q = function () {
                v3._q.push(arguments)
            }
            v3.on = function (e, fn) {
                if (!v3._l[e]) v3._l[e] = []
                v3._l[e].push(fn)
            }
            const s = d.createElement('script')
            s.type = 'text/javascript'
            s.async = true
            s.src = 'https://app.chaport.com/javascripts/insert.js'
            const ss = d.getElementsByTagName('script')[0]
            ss.parentNode.insertBefore(s, ss)
        })(window, document)
    }, [])

    useEffect(() => {
        if (isAuth) {
            setTimeout(() => {
                try {
                    const target = document.createElement('div')
                    target.className = 'chaport-tickets'
                    document.querySelector('.chaport-inner').append(target)
                    const root = createRoot(target)

                    root.render(<Ticket />)
                } catch (e) {}
            }, 2000)
        }
    }, [isAuth])

    return <></>
}

const Ticket = () => {
    const [count, setCount] = useState<number>()

    useEffect(() => {
        const fetchCount = async () => {
            try {
                const count = await ApiService.request<number>('/user/help-desk/tickets/ticketsWithUnreadAnswersCount')
                setCount(count)
            } catch (e) {}
        }

        fetchCount()
    }, [])

    return (
        <TicketContainer onClick={() => window.open(ROUTE.SUPPORT)}>
            <TicketHeader>
                <TicketIcon>
                    <EnvelopeSvg width={20} />
                </TicketIcon>
                <span>Tickets</span>
                <TicketCount gray={!count}>
                    {!!count && (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={2}
                            stroke="currentColor"
                        >
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                        </svg>
                    )}
                    {count === undefined ? <Spinner size={10} /> : count}
                </TicketCount>
            </TicketHeader>
            {/*<TicketCounters>*/}
            {/*    <TicketCount>*/}
            {/*        <svg*/}
            {/*            xmlns="http://www.w3.org/2000/svg"*/}
            {/*            fill="none"*/}
            {/*            viewBox="0 0 24 24"*/}
            {/*            strokeWidth={2}*/}
            {/*            stroke="currentColor"*/}
            {/*        >*/}
            {/*            <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />*/}
            {/*        </svg>*/}
            {/*        5*/}
            {/*    </TicketCount>*/}
            {/*    /!*<TicketCount gray>10</TicketCount>*!/*/}
            {/*</TicketCounters>*/}
        </TicketContainer>
    )
}

const TicketCounters = styled.div`
    display: flex !important;
    gap: 4px !important;
    margin-top: 10px !important;
`

const TicketCount = styled.div<{ gray?: boolean }>`
    display: inline-flex !important;
    align-items: center !important;
    color: white !important;
    background: #49ca1c !important;
    border-radius: 20px !important;
    padding: 2px 4px 2px 3px !important;
    font-size: 12px !important;
    line-height: 1 !important;

    &.gray {
        background: #ccc !important;
    }

    svg {
        min-width: 9px !important;
    }
`

const TicketHeader = styled.div`
    display: flex !important;
    align-items: center !important;
    gap: 10px !important;
`

const TicketIcon = styled.div`
    display: flex !important;
    width: 32px !important;
    height: 32px !important;
    justify-content: center !important;
    align-items: center !important;
    border-radius: 100% !important;
    color: #fff !important;
    background: #2281ff !important;
`

const TicketContainer = styled.div`
    position: absolute !important;
    background: white !important;
    box-shadow: rgba(0, 0, 0, 0.15) 0 5px 38px !important;
    border-radius: 10px !important;
    padding: 10px !important;
    min-width: 130px !important;
    margin-right: 10px !important;
    right: 100% !important;
    cursor: pointer !important;

    span {
        font-weight: 600 !important;
    }
`

stylesheet`
    :global(.chaport-window) {
        overflow: visible !important;
    }
    
    :global(.chaport-inner) {
        background: transparent !important
    }
    
    :global(.chaport-inner-iframe) {
        border-radius: 10px !important;
        overflow: hidden !important;
        background: rgb(255, 255, 255) !important;
    }
    
    @media (max-width: 768px) {
        :global(.chaport-launcher-button) {
            right: 15px !important;
            bottom: 70px !important;
        }
    }
    
`

export default LiveChat
