import * as React from 'react'
import { Button, Empty, Space, Tip, Title } from 'capricorn-ui'
import {
    ContentSurface,
    OperationAmount,
    OperationComment,
    OperationListHeader,
    OperationName,
} from '../templates/HistoryOperationsTemplate'
import { FormattedDate, FormattedMessage } from 'react-intl'
import { LoadingError } from '../molecules/LoadingError'
import { ROUTE } from 'constants/routes'
import { Theme } from 'src/store/commonStore'
import { UnionProcessedTransfer } from 'src/modules/operations/transfers/types'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useInfiniteQuery } from 'react-query'
import { useLocation, useNavigate } from 'react-router-dom'
import { useStore } from 'src/store'
import { useWindowSize } from 'react-use'
import CardHistoryOperationsFilters from 'components/organisms/CardHistoryOperationsFilters'
import CardService from 'services/CardService'
import ClockSvg from 'assets/icons/clock.svg'
import Delimiter from '../atoms/Delimiter'
import ErrorBoundaryContainer from 'containers/ErrorBoundaryContainer'
import FormatService from 'services/FormatService'
import RoundIcon from '../atoms/RoundIcon'
import Skeleton from 'react-loading-skeleton'
import StatusLabel from '../atoms/StatusLabel'
import currencies from 'src/constants/currencies'
import styled from 'astroturf/react'
import useBankCardOptions from 'src/hooks/operations/useBankCardOptions'

interface Props {
    noFilters?: boolean
}

const HistoryCardTransfersTab: React.FC<Props> = () => {
    const windowWidth = useWindowSize().width
    const navigate = useNavigate()
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    const containerRef = useRef<HTMLDivElement>(null)
    const {
        common: { theme },
    } = useStore()
    const cards = useBankCardOptions()

    const [filters, setFilters] = useState({})

    const setSearchParams = (newFilters: any) => {
        Object.entries(newFilters).forEach(([name, value]) => {
            if (value) {
                searchParams.set(name, typeof value === 'string' ? value : value.value)
            } else {
                searchParams.delete(name)
            }
        })
        navigate({
            pathname: location.pathname,
            search: searchParams.toString(),
        })
    }

    useEffect(() => {
        if (cards.query.isFetched) {
            if (!!searchParams.get('card')) {
                setFilters({
                    from: searchParams.get('from'),
                    to: searchParams.get('to'),
                    card: !!searchParams.get('card')
                        ? cards.options.find((item) => item.payload.id === Number(searchParams.get('card')))
                        : null,
                })
            } else {
                setFilters({
                    from: searchParams.get('from'),
                    to: searchParams.get('to'),
                    card: cards.options[0],
                })
            }
        }
    }, [cards.query.isFetched, searchParams.toString()])

    const getCardTransferHistory = useMemo(
        () =>
            async ({ pageParam: offset = 0 }) => {
                const data = await CardService.getHistory(
                    Number(filters.card?.value),
                    filters.from?.split('T')[0],
                    filters.to?.split('T')[0],
                    20,
                    offset,
                )
                return {
                    data,
                    offset,
                }
            },
        [filters.card?.value, filters.from, filters.to],
    )

    const operations = useInfiniteQuery<{ data: any[]; count: number; offset: number }>(
        ['operations', 'all-cards', `id:${filters.card?.value}`, `from:${filters.from}`, `to:${filters.to}`],
        getCardTransferHistory,
        {
            retry: false,
            enabled: !!filters.card?.value && filters.card?.payload?.status !== 'AWAIT',
            getNextPageParam: (lastPage) => {
                const nextOffset = lastPage.offset + 10
                return lastPage.data.length <= 1 ? false : nextOffset
            },
        },
    )

    return (
        <Space column>
            <ErrorBoundaryContainer>
                <CardHistoryOperationsFilters innerRef={containerRef} filters={filters} onChange={setSearchParams} />
            </ErrorBoundaryContainer>
            <ContentSurface noSurfacing>
                {windowWidth > 600 && (
                    <>
                        <OperationListHeader>
                            <TitleStyled level={5}>
                                <FormattedMessage defaultMessage="Card transfers" />
                            </TitleStyled>
                            {/*{!operations.isError && (*/}
                            {/*    <PiecesShown loading={operations.isLoading}>*/}
                            {/*        <b>Операции</b> {currentPage * countOnPage + 1}—*/}
                            {/*        {currentPage * countOnPage + countOnPage > operations.totalCount!*/}
                            {/*            ? operations.totalCount*/}
                            {/*            : currentPage * countOnPage + countOnPage}{' '}*/}
                            {/*        из {operations.totalCount}*/}
                            {/*    </PiecesShown>*/}
                            {/*)}*/}
                        </OperationListHeader>
                        <Delimiter />
                    </>
                )}
                {!operations.isError ? (
                    (!operations.isLoading && operations?.data?.pages[0]?.data?.length === 0) ||
                    filters.card?.payload?.status === 'AWAIT' ? (
                        <EmptyStyled>
                            {Object.keys(filters).length === 0 ? (
                                <FormattedMessage defaultMessage="Вы еще не совершили ни одной операции." />
                            ) : (
                                <FormattedMessage defaultMessage="Не найдено операций с указанными фильтрами, попробуйте изменить параметры поиска." />
                            )}
                        </EmptyStyled>
                    ) : (
                        <ErrorBoundaryContainer>
                            <OperationsTable theme={theme}>
                                <tbody>
                                    <RenderList pages={operations.data?.pages} loading={!operations.isFetched} />
                                </tbody>
                            </OperationsTable>
                            {!operations.isLoading && operations.hasNextPage && (
                                <Space justify="center">
                                    <Button
                                        skin="border"
                                        onClick={operations.fetchNextPage}
                                        loading={operations.isFetchingNextPage}
                                    >
                                        <FormattedMessage defaultMessage="Load more" />
                                    </Button>
                                </Space>
                            )}
                        </ErrorBoundaryContainer>
                    )
                ) : (
                    <LoadingErrorStyled onUpdate={operations.refetch} errorId={operations.error?.id}>
                        <FormattedMessage defaultMessage="Не удалось загрузить историю операций." />
                    </LoadingErrorStyled>
                )}
            </ContentSurface>
        </Space>
    )
}

const RenderList: React.FC = ({ pages, loading }) => {
    const navigate = useNavigate()
    const location = useLocation()
    const windowWidth = useWindowSize().width

    const onTransferClick = (transfer: any) => () => {
        if (!loading) {
            navigate(
                {
                    pathname: `${ROUTE.OPERATION_CARDS}/details/${transfer.id}`,
                    search: location.search,
                },
                {
                    state: {
                        backgroundLocation: location,
                        transfer,
                    },
                },
            )
        }
    }

    return (loading ? [{}] : pages).map((group, i) => (
        <React.Fragment key={i}>
            {(loading ? [{}, {}, {}] : group?.data).map((transfer: UnionProcessedTransfer) => {
                const transferFoundType = Number(transfer.amount) > 0 ? 'receipt' : 'debit'

                const getAmount = () => {
                    return (
                        <OperationAmountValue isDeclined={transfer.state === 'DECLINED'}>
                            <div>
                                {transferFoundType === 'receipt' ? (
                                    <b>+ {transfer.amount && FormatService.money(Number(transfer.amount) / 100)}</b>
                                ) : (
                                    <span>
                                        - {transfer.amount && FormatService.money(Number(transfer.amount) / 100)}
                                    </span>
                                )}{' '}
                                {transfer.currency && currencies[transfer.currency.toLowerCase()].name}
                            </div>
                        </OperationAmountValue>
                    )
                }

                return (
                    <Row onClick={onTransferClick(transfer)} key={transfer.id}>
                        <Column>
                            {loading ? (
                                <Skeleton width={42} height={42} circle />
                            ) : (
                                <OperationIcon icon={transferFoundType} />
                            )}
                        </Column>
                        <Date>
                            {loading ? (
                                <Skeleton height={32} width={100} />
                            ) : (
                                <>
                                    <FormattedDate value={transfer.created} day="numeric" month="long" year="numeric" />
                                    <br />
                                    <FormattedDate value={transfer.created} hour="numeric" minute="numeric" />
                                </>
                            )}
                        </Date>
                        <Column>
                            {loading ? (
                                <div>
                                    <Skeleton height={28} />
                                    <br />
                                    <Skeleton height={18} width={300} />
                                </div>
                            ) : (
                                <>
                                    <OperationName>
                                        <FormattedMessage
                                            defaultMessage="From"
                                            description="История операций (надпись перед номером счета)"
                                        />
                                        :{' '}
                                        <b>{transfer?.details?.description || transfer?.online_message?.descriptor}</b>
                                    </OperationName>
                                    {transfer.accountTo && transfer.type !== 'SERVICE' && (
                                        <OperationName>
                                            <FormattedMessage
                                                defaultMessage="To"
                                                description="История операций (надпись перед номером счета)"
                                            />
                                            : <b>{FormatService.transfer2toAccount(transfer)}</b>
                                        </OperationName>
                                    )}
                                    <OperationComment>
                                        <FormattedMessage defaultMessage="No." /> {transfer.id}
                                    </OperationComment>
                                </>
                            )}
                        </Column>
                        <Column>
                            {loading ? (
                                <Skeleton width={100} height={42} />
                            ) : (
                                <>
                                    <OperationAmount>
                                        {transfer.online_message ? (
                                            <Tip
                                                dropdownProps={{ position: 'bottom-end', widthAuto: true }}
                                                customButton={
                                                    <>
                                                        {getAmount()}
                                                        <ClockSvgStyled width={12} />
                                                    </>
                                                }
                                            >
                                                <FormattedMessage defaultMessage="В обработке" />
                                            </Tip>
                                        ) : (
                                            getAmount()
                                        )}
                                    </OperationAmount>
                                    {windowWidth < 1000 && <StatusLabelStyled status={transfer.state} />}
                                </>
                            )}
                        </Column>
                        {/*{!loading && windowWidth >= 1000 && (*/}
                        {/*    <Column>*/}
                        {/*        <StatusLabelStyled status={transfer.state} />*/}
                        {/*    </Column>*/}
                        {/*)}*/}
                    </Row>
                )
            })}
        </React.Fragment>
    ))
}

const EmptyStyled = styled(Empty)`
    margin: 60px 30px 0;

    @media (max-width: 600px) {
        margin: 0 28px;
        text-align: center;
    }
`

const ClockSvgStyled = styled(ClockSvg)`
    position: relative;
    top: -1px;
    margin-left: 6px;
    color: #b8bdc4;
`

const LoadingErrorStyled = styled(LoadingError)`
    margin: 60px 30px 0;
    width: calc(100% - 60px);
`

const StatusLabelStyled = styled(StatusLabel)``

const Row = styled.div`
    display: table-row;
`

const Column = styled.div`
    display: table-cell;
    vertical-align: middle;
`

const Date = styled.div`
    display: table-cell;
    vertical-align: middle;
    font-size: 14px;
    color: var(--main-third-text-color);
`

const TitleStyled = styled(Title)`
    margin-right: 10px !important;
`

const SystemIcon = styled(RoundIcon)`
    min-width: 42px;
    height: 42px;
    margin-right: 15px;
    color: #2281ff;

    svg {
        height: 42px;
    }
`

const OperationIcon = styled(RoundIcon)`
    margin-right: 14px;

    @media (max-width: 500px) {
        display: none;
    }
`

const OperationAmountValue = styled.div<{ isDeclined?: boolean }>`
    & > div {
        margin-bottom: 2px;
    }

    &.isDeclined {
        font-weight: 400;
        color: #ff5c21;
    }
`

const OperationsTable = styled.div<{ loading?: boolean; theme?: Theme }>`
    display: table;
    width: 100%;
    border-spacing: 0;
    margin-bottom: 30px;

    ${Row}:hover {
        ${Column}, ${Date} {
            background: var(--bg-color-4);
        }
    }

    &.theme-dark {
        ${Column}, ${Date} {
            border-bottom: 1px solid var(--bg-color-2);
        }
    }

    ${Column}, ${Date} {
        padding: 25px 10px 25px 0;
        border-bottom: 1px solid var(--bg-color-3);
        transition: 0.25s background-color;
        cursor: pointer;

        &:last-child {
            padding-right: 32px;
            text-align: right;
        }

        &:first-child {
            padding-left: 32px;
        }
    }

    @media screen and (max-width: 1100px) {
        ${StatusLabelStyled} {
            height: 30px;
            width: auto;
            padding: 0;
            margin-top: 5px;
            border: none;

            svg {
                margin-right: 7px;
            }
        }
        ${OperationAmount} {
            justify-content: flex-end;
        }
        ${Column} {
            &:last-child {
                padding-right: 20px;
            }

            &:first-child {
                padding-left: 20px;
            }
        }
        ${Date} {
            display: none !important;
        }
        ${Column}, ${Date} {
            padding: 15px 10px 13px 0;
        }
        ${SystemIcon} {
            margin-right: 0;
        }
    }

    @media screen and (max-width: 500px) {
        ${OperationName},
        ${OperationAmount} {
            font-size: 12px;
        }
        ${OperationIcon} {
            height: auto;
            background: none !important;
            margin-right: 0;
        }
        ${OperationComment} {
            margin-top: 0;
            font-size: 13px;
        }
        ${Column} {
            padding-right: 0;

            &:last-child {
                padding: 20px 15px 20px 0;
            }

            &:first-child {
                padding-left: 15px;
            }
        }
        ${SystemIcon} {
            min-width: 38px;
            margin-right: 10px;
        }
        ${StatusLabelStyled} {
            height: auto;
            margin-top: 0;
        }
    }
`

export default HistoryCardTransfersTab
