import { FormattedMessage, useIntl } from 'react-intl'
import { MOTION_TRANSITION, PAGE_VARIANTS_FADE_RIGHT } from 'src/constants'
import { motion } from 'framer-motion'
import { useSearchParam, useTitle } from 'react-use'
import { useStore } from 'src/store'
import AuthService from 'src/services/AuthService'
import AuthTemplate from 'components/templates/AuthTemplate'
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import Step1 from './login-steps/Step1'
import Step2Google2FA from 'components/pages/login-steps/Step2Google2FA'
import Step2OTP from './login-steps/Step2OTP'

const initialState = {
    step: 1,
    login: '',
    phone: '',
    password: '',
    code: '',
}

export type LoginState = typeof initialState

export interface LoginChildProps {
    state: LoginState
    setState: Dispatch<SetStateAction<Partial<LoginState>>>
    sendOTP: (phone: string, password: string) => void
}

const LoginPage: React.FC = () => {
    const intl = useIntl()
    useTitle(`Practika | ${intl.formatMessage({ defaultMessage: 'Авторизация' })}`)
    const ifFromPasswordRestoration = useSearchParam('success')
    const [state, setState] = useState<LoginState>(initialState)
    const { notice } = useStore()

    const sendOTP = async (login: string, password: string) => {
        try {
            await AuthService.signIn(login, password)
        } catch (e) {
            const errorName = e?.response?.data.error

            if (e?.response?.status === 502 || e?.response?.status === 503) {
                notice.show({
                    title: <FormattedMessage defaultMessage="На сервере ведутся технические работы" />,
                    message: <FormattedMessage defaultMessage="Пожалуйста, попробуйте немного позже." />,
                })

                return
            }

            switch (errorName) {
                case 'wrong-login-or-password':
                case 'user-not-found':
                    notice.show({
                        title: <FormattedMessage defaultMessage="Неправильный логин или пароль" />,
                        message: <FormattedMessage defaultMessage="Проверьте правильность введенных данных" />,
                    })
                    break
                case 'otp-required-or-invalid-gc':
                    setState({ ...state, step: 2, login, password })
                    break
                case 'otp-required-or-invalid':
                    setState({ ...state, step: 2, phone: e?.response?.data?.phoneNumber, login, password })
                    break
                case 'ip-whitelist-mismatch':
                    notice.show({
                        title: <FormattedMessage defaultMessage="Данный IP-адрес не найден в белом списке IP" />,
                        message: <FormattedMessage defaultMessage="Попробуйте войти с другого IP-адреса" />,
                    })
                    break
                case 'password-not-set':
                    notice.show(
                        {
                            title: (
                                <FormattedMessage defaultMessage="Вы не можете войти в систему, так как не завершили регистрацию" />
                            ),
                            message: (
                                <FormattedMessage defaultMessage="Пожалуйста, пройдите процедуру регистрации заново." />
                            ),
                        },
                        'infinity',
                    )
                    break
                case 'max-login-attempts-exceeded':
                    notice.show({
                        title: <FormattedMessage defaultMessage="Вы превысили количество попыток входа" />,
                        message: <FormattedMessage defaultMessage="Пожалуйста, попробуйте войти немного позже." />,
                    })
                    break
                default:
                    notice.showCommonError(e)
            }
        }
    }

    useEffect(() => {
        if (ifFromPasswordRestoration) {
            notice.show(
                {
                    title: <FormattedMessage defaultMessage="Пароль успешно изменен" />,
                    skin: 'success',
                    message: <FormattedMessage defaultMessage="Вы можете войти в систему с новым паролем." />,
                },
                'infinity',
            )
        }
    }, [ifFromPasswordRestoration])

    return (
        <AuthTemplate>
            {ENV !== 'browser' && <div />}
            <motion.div
                key={state.step}
                initial="initial"
                animate="in"
                variants={PAGE_VARIANTS_FADE_RIGHT}
                transition={MOTION_TRANSITION}
            >
                {state.step === 1 && <Step1 state={state} setState={setState} sendOTP={sendOTP} />}
                {state.step === 2 &&
                    (state.phone ? (
                        <Step2OTP state={state} setState={setState} sendOTP={sendOTP} />
                    ) : (
                        <Step2Google2FA state={state} setState={setState} sendOTP={sendOTP} />
                    ))}
            </motion.div>
        </AuthTemplate>
    )
}

export default LoginPage
