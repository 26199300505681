import { Button, Space, Surface, Tabs } from 'capricorn-ui'
import { FormattedMessage, useIntl } from 'react-intl'
import { Option } from 'capricorn-ui/lib/SelectInput'
import { useStore } from 'src/store'
import { useWindowSize } from 'react-use'
import CardInput from 'components/molecules/CardInput'
import DateInputWithSidebar from 'containers/DateInputWithSidebar'
import EmptyModal from 'components/modals/EmptyModal'
import FilterSvg from 'src/assets/icons/filter.svg'
import Link from 'containers/Link'
import React, { RefObject, useState } from 'react'
import ReactDOM from 'react-dom'
import styled from 'astroturf/react'

interface IProps {
    filters: {
        card: Option
        from: string
        to: string
    }
    onChange: (filters: Record<string, any>) => void
    innerRef?: RefObject<HTMLDivElement> | null
}

const CardOperationsFilters: React.FC<IProps> = ({ innerRef, filters, onChange }) => {
    const windowWidth = useWindowSize().width
    const { common } = useStore()
    const intl = useIntl()

    const resetFilters = () => {
        onChange({ card: null, from: null, to: null })
    }

    // const handleSavePDF = async () => {
    //     if (auth?.user?.verificationStatus === 'verified') {
    //         setLoading(true)
    //         try {
    //             const response = await ApiService.request(`${API.HISTORY_OPERATIONS}?limit=9999&offset=0`, {
    //                 cardid: filters.card?.value,
    //                 from: filters.from ? filters.from : new Date(1546304400000).toISOString(),
    //                 to: filters.to ? filters.to : new Date().toISOString(),
    //             })
    //             downloadPdf(response.data)
    //         } catch (e) {
    //             notice.showCommonError(e)
    //         } finally {
    //             setLoading(false)
    //         }
    //     } else {
    //         notice.showVerificationRequired()
    //     }
    // }

    // const downloadPdf = async (data: any[], print?: boolean) => {
    //     setLoading(true)
    //
    //     try {
    //         await PdfService.generateStatement(data, intl, filters, print)
    //     } catch (e) {
    //         notice.showCommonError(e)
    //     }
    //
    //     setLoading(false)
    // }

    const changeDate = (newDate: [Date, Date]) => {
        onChange({
            ...filters,
            from: newDate ? newDate[0].toISOString() : null,
            to: newDate ? newDate[1].toISOString() : null,
        })
    }

    const changeFilters = (name: string) => (value: any) => {
        onChange({
            ...filters,
            [name]: value,
        })
    }

    const [isOpenedModal, setIsOpenedModal] = useState(false)

    const showFiltersModal = () => {
        setIsOpenedModal(true)
    }

    const getFilterBody = () => {
        return (
            <div>
                <FiltersRow column={windowWidth <= 600} size={windowWidth <= 600 ? 24 : 32}>
                    <CardInput
                        onChange={changeFilters('card')}
                        value={filters.card}
                        label={intl.formatMessage({ defaultMessage: 'Card' })}
                        fullWidth
                    />
                    <DateInputWithSidebar
                        value={filters.from && [new Date(filters.from), new Date(filters.to)]}
                        onChange={changeDate}
                        label={intl.formatMessage({ defaultMessage: 'Date' })}
                        isRange
                        clearable
                        fullWidth
                        locale={common.locale}
                        style={{ height: 50, minWidth: 300 }}
                    />
                </FiltersRow>
            </div>
        )
    }

    const getFiltersFooter = () => {
        return (
            <FiltersFooter>
                {/*<div>*/}
                {/*    <FormattedMessage defaultMessage="Get statement" />:*/}
                {/*    <FileLink disabled size="s" skin="link" onClick={handleSavePDF} loading={loading}>*/}
                {/*        PDF*/}
                {/*    </FileLink>*/}
                {/*    <CsvButton disabled filters={filters} />*/}
                {/*</div>*/}
                <Actions>
                    <Link onClick={resetFilters}>
                        <FormattedMessage defaultMessage="Reset filters" />
                    </Link>
                </Actions>
            </FiltersFooter>
        )
    }

    return (
        <>
            <ShowFiltersButtonWrap>
                <ShowFiltersButton size="m" onClick={showFiltersModal}>
                    <FilterSvg /> <FormattedMessage defaultMessage="Filters" />
                </ShowFiltersButton>
            </ShowFiltersButtonWrap>
            {windowWidth > 1200 && (
                <Filters noSurfacing innerRef={innerRef}>
                    {getFilterBody()}
                    {getFiltersFooter()}
                </Filters>
            )}
            {isOpenedModal &&
                ReactDOM.createPortal(
                    <EmptyModal maxWidth={1200} footer={getFiltersFooter()} onCancel={() => setIsOpenedModal(false)}>
                        {getFilterBody()}
                    </EmptyModal>,
                    document.getElementById('modal-portal')!,
                )}
        </>
    )
}

const QuickDateTab = styled(Tabs)``

const ShowFiltersButtonWrap = styled.div`
    position: fixed;
    display: none;
    z-index: 4;
    margin: auto;
    transition: 0.25s transform;

    @media (max-width: 1200px) {
        display: block;
        right: 50%;
        bottom: 18px;
        transform: translateX(125%);
    }

    @media (max-width: 768px) {
        transform: translateX(50%);
    }
`

const ShowFiltersButton = styled(Button)`
    box-shadow: 0 8px 22px rgba(34, 129, 255, 0.5);

    svg {
        height: 50% !important;
    }
`

const FiltersRow = styled(Space)`
    font-size: 13px;

    @media (max-width: 1200px) {
        overflow: visible !important;
        max-height: 100% !important;
    }
`

const FiltersFooter = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 10px;

    @media (max-width: 1200px) {
        padding-top: 0;
    }
`

// const FileLink = styled(Button)`
//     margin-left: 12px;
//     font-size: 14px;
//     font-weight: bold;
// `

const Actions = styled.div`
    svg {
        margin-left: 8px;
    }
`

const ToggleFiltersView = styled(Link)<{ fullView?: boolean }>`
    svg {
        margin-right: 20px;
        transform: scale(1);
        transition: 0.25s transform;
    }

    &.fullView {
        svg {
            transform: scale(-1);
        }
    }

    @media (max-width: 1200px) {
        display: none;
    }
`

const Filters = styled(Surface)`
    padding: 32px;

    @media (max-width: 1100px) {
        ${ToggleFiltersView} {
            display: none;
        }

        ${FiltersRow} {
            display: flex !important;
            flex-direction: column;

            & > * {
                width: auto;
            }
        }

        ${QuickDateTab} {
            display: none;
        }
    }

    @media (max-width: 600px) {
        ${FiltersRow} {
            & > * {
                width: 100%;
            }
        }
    }
`

// const CsvButton: FC<IProps> = ({ filters }) => {
//     const [loading, setLoading] = useState(false)
//     const intl = useIntl()
//     const csvColumns = [
//         {
//             id: '№',
//             displayName: '№',
//         },
//         {
//             id: 'Date',
//             displayName: intl.formatMessage({ defaultMessage: 'Date' }),
//         },
//         {
//             id: 'From',
//             displayName: intl.formatMessage({ defaultMessage: 'From' }),
//         },
//         {
//             id: 'To',
//             displayName: intl.formatMessage({ defaultMessage: 'To' }),
//         },
//         {
//             id: 'Amount',
//             displayName: intl.formatMessage({ defaultMessage: 'Amount' }),
//         },
//         {
//             id: 'Tax',
//             displayName: intl.formatMessage({ defaultMessage: 'Tax' }),
//         },
//         {
//             id: 'State',
//             displayName: intl.formatMessage({ defaultMessage: 'Status' }),
//         },
//         {
//             id: 'Type',
//             displayName: intl.formatMessage({ defaultMessage: 'Type' }),
//         },
//     ]
//
//     const getCsvData = async () => {
//         setLoading(true)
//         const csv = await CsvService.generateStatement(filters)
//         setLoading(false)
//         return csv
//     }
//
//     return (
//         <Button disabled size="s" skin="link" style={{ fontWeight: 'bold' }} loading={loading}>
//             <CsvDownloader filename="practika_account_statement" separator=";" datas={getCsvData} columns={csvColumns}>
//                 CSV
//             </CsvDownloader>
//         </Button>
//     )
// }

export default CardOperationsFilters
