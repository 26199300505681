import { ROUTE } from 'src/constants/routes'
import { Route, Routes } from 'react-router-dom'
import { Spinner } from 'capricorn-ui'
import CardFeesModal from 'components/modals/cards/CardFeesModal'
import EmptyModal from 'components/modals/EmptyModal'
import ForgotPasswordPage from 'components/pages/ForgotPasswordPage'
import LoginPage from 'components/pages/LoginPage'
import React, { FC, Suspense, lazy } from 'react'
import RegistrationPage from 'components/pages/RegistrationPage'

const AccountStatementModal = lazy(() => import('./components/modals/account/AccountStatementModal'))
const TransferDetails = lazy(() => import('./components/modals/TransferDetails'))
const MassTransferDetailsModal = lazy(() => import('components/modals/MassTransferDetailsModal'))
const AddIPWhiteListModal = lazy(() => import('./components/modals/AddIPWhiteListModal'))
const Google2FAModal = lazy(() => import('./components/modals/Google2FAModal'))

const ActivateCardModal = lazy(() => import('./components/modals/cards/ActivateCardModal'))
const ChangeCardPasswordModal = lazy(() => import('./components/modals/cards/ChangeCardPasswordModal'))
const RenameCardModal = lazy(() => import('./components/modals/cards/RenameCardModal'))
const BlockCardModal = lazy(() => import('./components/modals/cards/BlockCardModal'))
const TopUpCardModal = lazy(() => import('./components/modals/cards/TopUpCardModal'))
const CardDetailsModal = lazy(() => import('./components/modals/cards/CardDetailsModal'))
const ChangeCardPinModal = lazy(() => import('./components/modals/cards/ChangeCardPinModal'))
const Card2CardTransferModal = lazy(() => import('./components/modals/cards/Card2CardTransferModal'))

export const ModalRoutes: FC = () => (
    <Suspense fallback={<Spinner size="l" />}>
        <Routes>
            <Route path={`${ROUTE.HISTORY_OPERATIONS}/details/:id`} element={<TransferDetails />} />
            <Route path={`${ROUTE.OPERATION_MASS}/details/:id`} element={<MassTransferDetailsModal />} />
            <Route path={`${ROUTE.HOME}/transfer-details/:id`} element={<TransferDetails />} />
            <Route path={`${ROUTE.OPERATION_TRANSFERS}/details/:id`} element={<TransferDetails />} />
            <Route path={`${ROUTE.OPERATION_CARDS}/details/:id`} element={<TransferDetails />} />
            <Route path={`${ROUTE.SECURITY_IP_WHITE_LIST}/add`} element={<AddIPWhiteListModal />} />
            <Route path={`${ROUTE.SECURITY_AUTH}/set-google-2fa`} element={<Google2FAModal />} />
            {/* ACCOUNT */}
            <Route path={`${ROUTE.HOME}/:id/statement`} element={<AccountStatementModal />} />
            {/* CARDS */}
            <Route path={`${ROUTE.CARDS}/:id/activate`} element={<ActivateCardModal />} />
            <Route path={`${ROUTE.CARDS}/:id/details`} element={<CardDetailsModal />} />
            <Route path={`${ROUTE.CARDS}/:id/transfer`} element={<Card2CardTransferModal />} />
            <Route path={`${ROUTE.CARDS}/:id/fees`} element={<CardFeesModal />} />
            <Route path={`${ROUTE.CARDS_ISSUE}/fees`} element={<CardFeesModal />} />
            <Route path={`${ROUTE.CARDS}/:id/rename`} element={<RenameCardModal />} />
            <Route path={`${ROUTE.CARDS}/:id/block`} element={<BlockCardModal />} />
            <Route path={`${ROUTE.CARDS}/:id/top-up`} element={<TopUpCardModal />} />
            <Route path={`${ROUTE.CARDS}/:id/change-password`} element={<ChangeCardPasswordModal />} />
            <Route path={`${ROUTE.CARDS}/:id/change-pin`} element={<ChangeCardPinModal />} />
            {ENV === 'ios' && [
                <Route
                    key={ROUTE.SIGN_IN}
                    path={ROUTE.SIGN_IN}
                    element={
                        <EmptyModal withRouting title="Authorization">
                            <LoginPage />
                        </EmptyModal>
                    }
                />,
                <Route
                    key={ROUTE.SIGN_UP}
                    path={ROUTE.SIGN_UP}
                    element={
                        <EmptyModal withRouting title="Registration">
                            <RegistrationPage />
                        </EmptyModal>
                    }
                />,
                <Route
                    key={ROUTE.FORGOT}
                    path={ROUTE.FORGOT}
                    element={
                        <EmptyModal withRouting title="Restore password">
                            <ForgotPasswordPage />
                        </EmptyModal>
                    }
                />,
            ]}
        </Routes>
    </Suspense>
)
