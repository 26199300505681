import { SelectInput } from 'capricorn-ui'
import { SelectInputProps } from 'capricorn-ui/SelectInput'
import React, { FC, useEffect } from 'react'
import useBankCardOptions from 'src/hooks/operations/useBankCardOptions'

interface Props extends Omit<SelectInputProps, 'options' | 'autoSelect'> {
    onlyActiveCards?: boolean
    defaultCardId?: number
}

const CardInput: FC<Props> = ({ value, onChange, onlyActiveCards, defaultCardId, ...rest }) => {
    const { options, query } = useBankCardOptions()
    const filtered = onlyActiveCards ? options.filter((item) => item.payload.status === 'ACTIVE') : options

    useEffect(() => {
        if (defaultCardId && query.isSuccess) {
            const defaultCard = filtered.find((item) => item.value === defaultCardId)
            if (defaultCard) {
                onChange(defaultCard)
            }
        }
    }, [defaultCardId, query.isSuccess])

    return (
        <SelectInput
            options={filtered}
            loading={query.isLoading}
            onChange={onChange}
            value={value}
            dropdownProps={{ maxHeight: 'auto' }}
            noFormatBalance
            {...rest}
        />
    )
}

export default CardInput
