import { FormattedMessage } from 'react-intl'
import { LoadingError, LoadingErrorProps } from 'components/molecules/LoadingError'
import React, { FC } from 'react'
import styled from 'astroturf/react'

interface Props extends LoadingErrorProps {
    title?: string
    message?: string
}

export const RenderError: FC<Props> = ({ title, message, ...props }) => {
    return (
        <LoadingError {...props}>
            <Title>
                <b>{title || <FormattedMessage defaultMessage="Не удалось отобразить данные" />}</b>
            </Title>
            <Message>
                {message || (
                    <FormattedMessage
                        defaultMessage="Мы получили информацию об ошибке и уже работаем над ее исправлением. Приносим извинения за доставленные
                неудобства"
                    />
                )}
                .
            </Message>
        </LoadingError>
    )
}

const Title = styled.div`
    font-size: 16px;
`

const Message = styled.div`
    font-size: 13px;
`
