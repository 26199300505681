import { Link as CapricornLink } from 'capricorn-ui/esm'
import { LinkProps } from 'capricorn-ui/esm/Link'
import { Link as ReactLink, useLocation, useNavigate } from 'react-router-dom'
import React, { FC } from 'react'

interface Props extends LinkProps {
    to?: string
    mobileModalView?: boolean
}

const Link: FC<Props> = ({ to, mobileModalView, ...props }) => {
    const navigate = useNavigate()
    const location = useLocation()
    const resolveMobileModalView = mobileModalView && ENV === 'ios'

    const onClick = () => {
        if (resolveMobileModalView) {
            navigate(to as string, { state: { backgroundLocation: location.state?.backgroundLocation || location } })
        }
    }

    return (
        <CapricornLink
            component={(to ? (resolveMobileModalView ? 'a' : ReactLink) : 'a') as any}
            to={!resolveMobileModalView && to}
            onClick={onClick}
            {...props}
        />
    )
}

export default Link
