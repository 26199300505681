import { AuthStore } from './authStore'
import { CommonStore } from './commonStore'
import { NoticeStore } from './noticeStore'

export function createStore() {
    return {
        notice: NoticeStore(),
        auth: AuthStore(),
        common: CommonStore(),
    }
}

export type Store = ReturnType<typeof createStore>
