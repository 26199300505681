import { RefObject, useEffect, useRef, useState } from 'react'

export const useTimer = (initialTimeInSeconds: number): [number, (initialTime?: number) => void, () => void] => {
    const [time, setTime] = useState(initialTimeInSeconds)
    const timerRef = useRef()

    const start = (initialTime?: number) => {
        if (time === 0 || !timerRef.current) {
            setTime(initialTime || initialTimeInSeconds)
        }
        ;(timerRef.current as any) = setInterval(
            () =>
                setTime((prevTime) => {
                    if (prevTime > 1) {
                        return prevTime - 1
                    } else {
                        reset()
                        return 0
                    }
                }),
            1000,
        )
    }

    const reset = () => {
        if (timerRef.current) {
            clearInterval(timerRef.current)
            timerRef.current = undefined
        }
    }

    return [time, start, reset]
}

export const useOutsideClick = (ref: RefObject<HTMLDivElement | undefined>, callback: (event: MouseEvent) => void) => {
    function handleClickOutside(event: MouseEvent) {
        if (ref.current && !ref.current.contains(event.target as any)) {
            callback(event)
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside)
        return function cleanup() {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [])
}

export default function useScrollDirection(element: any) {
    const scrollPos = useRef(0)
    const [direction, setDirection] = useState(false)

    useEffect(() => {
        const handleScroll = () => {
            setDirection(element && element.scrollTop < scrollPos.current)
            scrollPos.current = element && element.scrollTop
        }

        element && element.addEventListener('scroll', handleScroll)
        return () => element && element.removeEventListener('scroll', handleScroll)
    }, [element])

    return direction
}
