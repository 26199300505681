import * as React from 'react'
import { Dispatch, SetStateAction, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { MOTION_TRANSITION, PAGE_VARIANTS_FADE_RIGHT } from 'src/constants'
import { motion } from 'framer-motion'
import { useStore } from 'src/store'
import { useTitle } from 'react-use'
import AuthService from 'src/services/AuthService'
import AuthTemplate from 'components/templates/AuthTemplate'
import Step1 from './forgot-password-steps/Step1'
import Step2 from './forgot-password-steps/Step2'
import Step3 from './forgot-password-steps/Step3'

const initialState = {
    step: 1,
    phone: '',
    code: '',
    password: '',
    passwordConfirmation: '',
}

export type ForgotPasswordState = typeof initialState

export interface ForgotPasswordChildProps {
    state: ForgotPasswordState
    setState: Dispatch<SetStateAction<Partial<ForgotPasswordState>>>
}

const ForgotPasswordPage: React.FC = () => {
    const intl = useIntl()
    useTitle(`Practika | ${intl.formatMessage({ defaultMessage: 'Восстановление пароля' })}`)
    const [state, setState] = useState<ForgotPasswordState>(initialState)
    const { notice } = useStore()

    const sendOTP = async (phone: string) => {
        try {
            await AuthService.resetPassword(phone)
            setState({ ...state, step: 2, phone })
        } catch (e) {
            if (e?.response?.data.error === 'user-not-found') {
                notice.show({
                    title: <FormattedMessage defaultMessage="Указанного пользователя не существует" />,
                    message: <FormattedMessage defaultMessage="Проверьте правильность введенных данных" />,
                })
            } else {
                notice.showCommonError(e)
            }
        }
    }

    return (
        <AuthTemplate>
            {ENV !== 'browser' && <div />}
            <motion.div
                key={state.step}
                initial="initial"
                animate="in"
                variants={PAGE_VARIANTS_FADE_RIGHT}
                transition={MOTION_TRANSITION}
            >
                {state.step === 1 && <Step1 state={state} setState={setState} sendOTP={sendOTP} />}
                {state.step === 2 && <Step2 state={state} setState={setState} sendOTP={sendOTP} />}
                {state.step === 3 && <Step3 state={state} setState={setState} />}
            </motion.div>
        </AuthTemplate>
    )
}

export default ForgotPasswordPage
