import * as React from 'react'
import * as yup from 'yup'
import { AuthButton, AuthPageTitle, AuthStepInfo, ResendInfo } from '../../templates/AuthTemplate'
import { FormattedMessage, useIntl } from 'react-intl'
import { LoginChildProps } from '../LoginPage'
import { RESEND_VERIFY_CODE_DELAY, VERIFY_CODE_TEL_LENGTH } from 'src/constants/config'
import { ROUTE } from 'src/constants/routes'
import { Space, Tip } from 'capricorn-ui'
import { secondsToDigitsTime } from 'src/utils'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useStore } from 'src/store'
import { useTimer } from 'src/hooks'
import { useWindowSize } from 'react-use'
import AnalyticService from 'services/AnalyticService'
import AuthService from 'src/services/AuthService'
import Bugsnag from '@bugsnag/js'
import ControlledTextInput from '../../../containers/ControlledTextInput'
import Link from 'containers/Link'
import styled from 'astroturf/react'
import useFormEnhanced from 'src/hooks/useFormEnhanced'

const Step2OTP: React.FC<LoginChildProps> = ({ state, setState, sendOTP }) => {
    const navigate = useNavigate()
    const { auth } = useStore()
    const intl = useIntl()
    const windowWidth = useWindowSize().width

    const schema = () =>
        yup.object().shape({
            code: yup
                .string()
                .required()
                .min(
                    VERIFY_CODE_TEL_LENGTH,
                    intl.formatMessage(
                        { defaultMessage: 'Must be exactly {count} digits' },
                        { count: VERIFY_CODE_TEL_LENGTH },
                    ),
                ),
        })

    const { control, handleSubmit, watch, setValue } = useFormEnhanced({
        schema,
        defaultValues: state,
    })

    const [resendCodeTimer, startResendCodeTimer, resetResendCodeTimer] = useTimer(RESEND_VERIFY_CODE_DELAY)
    const [loading, setLoading] = useState(false)
    const { notice } = useStore()

    const handleToLoginChange = () => {
        setState({
            ...state,
            step: 1,
        })
    }

    useEffect(() => {
        startResendCodeTimer()

        return function cleanup() {
            resetResendCodeTimer()
        }
    }, [])

    const handleCheckOtp = async () => {
        setLoading(true)

        try {
            const user = await AuthService.signIn(state.login, state.password, watch('code') || '')
            Bugsnag.setUser(user.id?.toString(), user.email, user.login)
            AnalyticService.setUser(user)

            auth.setUser(user)
            auth.setState('fetched')

            const search = new URLSearchParams(window.location.search)

            navigate(search.get('from') || ROUTE.HOME)
        } catch (e) {
            if (e?.response?.data?.error === 'otp-required-or-invalid') {
                notice.show(
                    {
                        title: <FormattedMessage defaultMessage="Недействительный код" />,
                        message: <FormattedMessage defaultMessage="Проверьте правильность введенных данных." />,
                    },
                    'infinity',
                )
                setValue('code', '')
            } else {
                notice.showCommonError(e)
            }
        }

        setLoading(false)
    }

    const resendCode = async () => {
        setValue('code', '')
        setLoading(true)
        await sendOTP(state.login, state.password)
        setLoading(false)
        resetResendCodeTimer()
        startResendCodeTimer()
    }

    useEffect(() => {
        if (watch('code')?.length === VERIFY_CODE_TEL_LENGTH) {
            handleCheckOtp()
        }
    }, [watch('code')])

    return (
        <form onSubmit={handleSubmit(handleCheckOtp)}>
            <Space column size={windowWidth < 500 ? 24 : 40}>
                <AuthPageTitle>
                    <FormattedMessage defaultMessage="Подтвердите вход" />
                    {/*<AuthTypeTip>*/}
                    {/*    <AuthTypeTipTitle>*/}
                    {/*        <FormattedMessage defaultMessage="Что такое двухфакторная аутентификация?" />*/}
                    {/*    </AuthTypeTipTitle>*/}
                    {/*    <p>*/}
                    {/*        <FormattedMessage*/}
                    {/*            defaultMessage="Двухфакторная аутентификация на для того-то, чтобы то-то. {br}{br}Вы можете выбрать для себя удобный способ аутентификации в <b>Мой профиль / Настройки безопасности / Аутентификация</b>"*/}
                    {/*            values={{*/}
                    {/*                b: (word: string) => <b>{word}</b>,*/}
                    {/*                br: <br />,*/}
                    {/*            }}*/}
                    {/*        />*/}
                    {/*    </p>*/}
                    {/*</AuthTypeTip>*/}
                </AuthPageTitle>
                <AuthStepInfo>
                    <FormattedMessage
                        defaultMessage="Мы отправили смс с кодом для подтверждения на номер <b>{number}</b> {br}Введите код в поле ниже."
                        values={{
                            number: state.phone,
                            br: <br />,
                            b: (word: string) => <b style={{ color: '#2281ff' }}>{word}</b>,
                        }}
                    />
                </AuthStepInfo>
                <StyledSpace column={windowWidth < 768} size={40}>
                    <ControlledTextInput
                        name="code"
                        type="code"
                        control={control}
                        autoFocus
                        codeLength={VERIFY_CODE_TEL_LENGTH}
                        inputMode="numeric"
                    />
                    <AuthButton type="submit" loading={loading}>
                        <FormattedMessage defaultMessage="Подтвердить" />
                    </AuthButton>
                </StyledSpace>
                <ButtonGroup column={windowWidth < 768} size={windowWidth < 768 ? 12 : 40}>
                    <Link bold onClick={resendCode} disabled={resendCodeTimer !== 0 || loading}>
                        <FormattedMessage defaultMessage="Отправить еще раз?" />
                    </Link>
                    <Tip
                        customButton={<span />}
                        title={
                            <Link bold>
                                {' '}
                                <FormattedMessage defaultMessage="Не приходит код подтверждения?" />
                            </Link>
                        }
                    >
                        <CodeNotCome>
                            <FormattedMessage
                                defaultMessage="<b>Возможные причины:</b>{brake}
                            <ol>
                                <li>
                                    Вы не предоставили в Practika новый номер мобильного телефона, и мы отправили код на
                                    старый. Обратитесь в службу поддержки.
                                </li>
                                <li>
                                    Проблемы могут быть со стороны вашего сотового оператора. Обратитесь в их службу
                                    поддержки.
                                </li>
                            </ol>"
                                values={{
                                    b: (word: string) => <b>{word}</b>,
                                    ol: (word: string) => <ol>{word}</ol>,
                                    li: (word: string) => <li>{word}</li>,
                                    brake: <br />,
                                }}
                            />
                        </CodeNotCome>
                    </Tip>
                    <Link bold disabled={loading} onClick={handleToLoginChange}>
                        <FormattedMessage defaultMessage="Back" />
                    </Link>
                </ButtonGroup>
                <ResendInfo visible={resendCodeTimer !== 0}>
                    <FormattedMessage defaultMessage="Если код не пришёл, вы сможете отправить его ещё раз через" />{' '}
                    {secondsToDigitsTime(resendCodeTimer)}
                </ResendInfo>
            </Space>
        </form>
    )
}

const ButtonGroup = styled(Space)`
    @media (max-width: 768px) {
        margin-top: -20px !important;
    }

    @media (max-width: 500px) {
        margin-top: 20px !important;
    }
`

const StyledSpace = styled(Space)`
    @media (max-width: 768px) {
        margin-top: -20px !important;
    }
`

const CodeNotCome = styled.div`
    font-size: 12px;

    ol {
        margin: 10px 0 0 0;
        padding: 0 0 0 15px;
    }
`

// const AuthTypeTip = styled(Tip)`
//     position: relative;
//     bottom: 2px;
//     margin-left: 15px;
//     vertical-align: super;
//     font-size: 13px;
//     font-weight: 400;
//
//     b {
//         font-weight: 600;
//     }
// `

// const AuthTypeTipTitle = styled.div`
//     margin-bottom: 15px;
//     font-size: 16px;
//     font-weight: 600;
// `

export default Step2OTP
