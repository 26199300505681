import { API } from 'constants/api'
import { BankCard } from 'src/interfaces/BankCard'
import ApiService from 'services/ApiService'

const CardService = {
    getList: async (limit: number, offset: number) => {
        const response = await ApiService.request<{
            data: BankCard[]
            count: number
        }>(`${API.CARDS}/cardlist?limit=${limit}&offset=${offset}&filterFolder=false`)
        return {
            list: [...response.data],
            count: response.count,
        }
    },
    getHistory: (cardId: number, from: string, to: string, limit: number, offset: number) =>
        ApiService.request(
            `${API.CARDS}/history?cardid=${cardId}&from=${from || '2023-01-01'}&to=${
                to || new Date().toISOString().split('T')[0]
            }&limit=${limit}&offset=${offset}`,
        ),
    getAuths: (cardId: number, from: string, to: string, limit: number, offset: number) =>
        ApiService.request(`${API.CARDS}/auths?cardid=${cardId}&from=${from}&to=${to}&limit=${limit}&offset=${offset}`),
    getBalance: (cardId: number) => ApiService.request(`${API.CARDS}/getBalance?cardid=${cardId}`),
    getCVV: (cardId: number) => ApiService.request(`${API.CARDS}/getFullCvv?cardid=${cardId}`),
    getPan: (cardId: number) => ApiService.request(`${API.CARDS}/getFullPan?cardid=${cardId}`),
    refreshTrackNumber: (cardId: number) => ApiService.request(`${API.CARDS}/refreshTrackNumber?cardid=${cardId}`),
    renameCard: (cardId: number, newName: string) =>
        ApiService.request(`${API.CARDS}/renameCard?cardid=${cardId}&newname=${newName}`, {}, 'POST'),
    checkPermit: () => ApiService.request(`${API.CARDS}/needPermit`),
}

export default CardService
