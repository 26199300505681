import * as React from 'react'
import { Title } from 'capricorn-ui'
import styled from 'astroturf/react'

const PageTitle: React.FC<{ children: React.ReactNode; sub?: boolean }> = ({ children, level, ...props }) => (
    <Styled level={level || 4} {...props}>
        {children}
    </Styled>
)

const Styled = styled(Title)`
    margin-bottom: 0;
    line-height: 1.3;

    @media (max-width: 600px) {
        margin-top: 0;
        font-weight: 400;
        font-size: 28px;

        &.sub {
            font-size: 20px;
        }
    }
`

export default PageTitle
