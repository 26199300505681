import { AuthButton } from 'components/templates/AuthTemplate'
import { FormattedMessage, useIntl } from 'react-intl'
import { ROUTE } from 'constants/routes'
import { Space } from 'capricorn-ui'
import { motion, useAnimation } from 'framer-motion'
import { useLocation, useNavigate } from 'react-router-dom'
import { useWindowSize } from 'react-use'
import ArrowSvg from 'assets/icons/arrow-left.svg'
import EmptyModal from 'components/modals/EmptyModal'
import Link from 'containers/Link'
import LogoSvg from 'assets/icons/logo-only-p.svg'
import React, { useMemo, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import Svg1 from 'assets/icons/onboard-1.svg'
import Svg2 from 'assets/icons/onboard-2.svg'
import Svg3 from 'assets/icons/onboard-3.svg'
import styled from 'astroturf/react'

const MobileHomePage: React.FC = () => {
    const xPos = useRef(0)
    const intl = useIntl()
    const navigate = useNavigate()
    const [isOpenedModal, setIsOpenedModal] = useState(false)
    const windowWidth = useWindowSize().width
    const [slide, setSlide] = useState(0)
    const animation = useAnimation()
    const location = useLocation()

    const slides = useMemo(
        () => [
            [
                <Svg1 key="dedicated" width={138} />,
                intl.formatMessage({ defaultMessage: 'Dedicated{br}IBAN account' }, { br: <br /> }),
                intl.formatMessage(
                    { defaultMessage: 'For Business and Individuals{br} in EUR, USD, GBP' },
                    { br: <br /> },
                ),
            ],
            [
                <Svg2 key="remote" width={138} />,
                intl.formatMessage({ defaultMessage: 'Remote account{br}opening' }, { br: <br /> }),
                intl.formatMessage(
                    { defaultMessage: 'European IBAN account online,{br} without visiting the bank' },
                    { br: <br /> },
                ),
            ],
            [
                <Svg3 key="instant" width={138} />,
                intl.formatMessage({ defaultMessage: 'Instant transfers{br}between Practika clients' }, { br: <br /> }),
                intl.formatMessage({ defaultMessage: 'Free instant transfers within{br} the system' }, { br: <br /> }),
            ],
        ],
        [],
    )

    function onUpdate(latest: { x: number }) {
        xPos.current = latest.x
    }

    const onNext = () => {
        const newXPosition = xPos.current - windowWidth
        const newSlide = slide + 1
        setSlide(newSlide)

        if (newSlide === 3) {
            goToReg()
        } else {
            animation.start({
                x: newXPosition < -windowWidth * 3 ? -windowWidth * 3 : newXPosition,
            })
        }
    }

    const goToReg = () => {
        setIsOpenedModal(false)
        setSlide(0)
        animation.start({
            x: 0,
        })
        onUpdate({ x: 0 })
        navigate(ROUTE.SIGN_UP, { state: { backgroundLocation: location } })
    }

    const onBack = () => {
        setSlide(0)

        animation.start({
            x: 0,
        })
    }

    return (
        <Wrap>
            <Link style={{ alignSelf: 'flex-end' }} href="mailto:support@practika.net">
                <FormattedMessage defaultMessage="Support" />
            </Link>
            <Space column size={32}>
                <LogoSvg width={160} />
                <Space size={6} column>
                    <Welcome>
                        <FormattedMessage defaultMessage="Welcome to" />
                    </Welcome>
                    <Practika>Practika</Practika>
                </Space>
                <Moto>
                    <FormattedMessage defaultMessage="Ideal solution for personal and business accounts" />
                </Moto>
            </Space>
            <Space size={20} column>
                <AuthButton onClick={() => setIsOpenedModal(true)}>
                    <FormattedMessage defaultMessage="Get started" />
                    <ArrowStyled width={20} />
                </AuthButton>
                <AuthButton
                    onClick={() =>
                        navigate(ROUTE.SIGN_IN, {
                            state: { backgroundLocation: location },
                        })
                    }
                    skin="border"
                >
                    <FormattedMessage defaultMessage="Sign in" />
                </AuthButton>
            </Space>
            {isOpenedModal &&
                ReactDOM.createPortal(
                    <EmptyModal title=" " onCancel={() => setIsOpenedModal(false)}>
                        <Space justify="space-between" column>
                            <Space column size={40}>
                                <CarouselWrap>
                                    <motion.div onUpdate={onUpdate} animate={animation}>
                                        <CarouselInner>
                                            {slides.map((slide) => (
                                                <Slide key={slide[2]}>
                                                    <Img>{slide[0]}</Img>
                                                    <Space column size={20}>
                                                        <PageTitleStyled>{slide[1]}</PageTitleStyled>
                                                        <Desc>{slide[2]}</Desc>
                                                    </Space>
                                                </Slide>
                                            ))}
                                        </CarouselInner>
                                    </motion.div>
                                </CarouselWrap>
                                <Space justify="center" size={9} onClick={onBack}>
                                    <Dot active={slide === 0} />
                                    <Dot active={slide === 1} />
                                    <Dot active={slide === 2} />
                                </Space>
                            </Space>
                            <Space column size={20}>
                                <AuthButton onClick={onNext}>
                                    {slide !== 2 ? (
                                        <FormattedMessage defaultMessage="Next" />
                                    ) : (
                                        <FormattedMessage defaultMessage="Open an account" />
                                    )}
                                </AuthButton>
                                {slide !== 2 ? (
                                    <AuthButton onClick={goToReg} skin="link">
                                        <FormattedMessage defaultMessage="Skip" />
                                    </AuthButton>
                                ) : (
                                    <div style={{ height: 54 }} />
                                )}
                            </Space>
                        </Space>
                    </EmptyModal>,
                    document.getElementById('modal-portal')!,
                )}
        </Wrap>
    )
}

const PageTitleStyled = styled.div`
    line-height: 34px;
    font-size: 28px;
`

const CarouselWrap = styled.div`
    display: flex;
    align-items: center;
    margin: 0 -15px;
    overflow: hidden;
`

const Desc = styled.div`
    font-size: 17px;
    line-height: 24px;
`

const CarouselInner = styled.div`
    display: flex;
    flex: 1;
`

const Img = styled.div`
    border: 15px solid #f2f9fd;
    border-radius: 100%;
    background: #d2edff;
`

const Slide = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 100vw;
    height: 100%;
    gap: 32px;
    color: #1d2534;
    text-align: center;
    box-sizing: border-box;
`

const Dot = styled.div<{ active?: boolean }>`
    width: 6px;
    height: 6px;
    border-radius: 6px;
    background-color: #dfe2e8;
    transition: 0.25s width;

    &.active {
        width: 20px;
    }
`

const ArrowStyled = styled(ArrowSvg)`
    transform: rotate(180deg);
    margin: 0 0 0 24px !important;
`

const Moto = styled.div`
    font-weight: 400;
    font-size: 24px;
`

const Practika = styled.div`
    font-weight: 600;
    font-size: 44px;
`

const Welcome = styled.div`
    font-size: 17px;
    color: #2281ff;
`

const Wrap = styled.div`
    position: relative;
    display: flex;
    gap: 10px;
    height: 100svh;
    overflow: hidden;
    flex-direction: column;
    justify-content: space-between;
    background: #1d2534;
    padding: calc(env(safe-area-inset-top) + 28px) 28px calc(env(safe-area-inset-bottom) + 28px);
    box-sizing: border-box;
    line-height: 1.2;
    color: #fff;
`

export default MobileHomePage
