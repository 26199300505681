import { AxiosError } from 'axios'
import { Empty, Space } from 'capricorn-ui'
import { FormattedDate, FormattedMessage } from 'react-intl'
import { Notice } from 'src/interfaces/Notice'
import { ROUTE } from 'src/constants/routes'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useOutsideClick } from 'src/hooks'
import { useStore } from 'src/store'
import { useWindowSize } from 'react-use'
import AdaptiveDropdown from 'containers/AdaptiveDropdown'
import ErrorBoundaryContainer from 'containers/ErrorBoundaryContainer'
import IconBlock from '../templates/IconBlock'
import Link from 'containers/Link'
import LogoSvg from 'assets/icons/logo-circle.svg'
import NoticeService from 'services/NoticeService'
import NoticeSvg from 'src/assets/icons/notice.svg'
import React, { useEffect, useRef, useState } from 'react'
import styled from 'astroturf/react'

const NoticeDropDown: React.FC = () => {
    const windowWidth = useWindowSize().width
    const [active, setActive] = useState(false)
    const ref = useRef(null)
    const queryClient = useQueryClient()
    const { notice } = useStore()

    const toggleContent = (): void => {
        setActive((active) => !active)
    }

    useOutsideClick(ref, () => windowWidth >= 600 && setActive(false))

    const notices = useQuery(['notices'], () => NoticeService.getNoticesList('all', windowWidth > 600 ? 5 : 30, 0))

    const markOnServer = useMutation<void, AxiosError, number>(NoticeService.markAsRead, {
        onError: (e) => {
            notice.showCommonError(e)
        },
        onSettled: () => {
            queryClient.invalidateQueries(['notices'])
        },
    })

    const markAllOnServer = useMutation(
        async () => {
            await NoticeService.markAllAsRead()
        },
        {
            onError: (e) => {
                notice.showCommonError(e)
            },
            onSettled: () => {
                queryClient.invalidateQueries(['notices'])
            },
        },
    )

    const markAsRead = (id: number) => {
        markOnServer.mutate(id)
    }

    useEffect(() => {
        if (active) {
            markAllOnServer.mutate()
        }
    }, [active])

    const areUnreadNoticesExist = notices.data?.notices.some((item) => item.new)

    const notFound = notices.data?.count === 0 || notices.data?.notices?.length === 0 || !notices.data?.notices

    return (
        <Container ref={ref}>
            {notices.isFetched && (
                <>
                    <NoticeIcon onClick={toggleContent}>
                        <NoticeSvg width={16} />
                        {areUnreadNoticesExist && <NoticeIndicator />}
                    </NoticeIcon>
                    <DropdownStyled
                        onChange={setActive}
                        position="bottom-end"
                        noPadding
                        noArrow
                        maxHeight="auto"
                        active={active}
                        modalProps={{ title: <FormattedMessage defaultMessage="Уведомления" /> }}
                    >
                        <Header>
                            <FormattedMessage defaultMessage="Уведомления" />
                            {/*{!notFound && (*/}
                            {/*    <Link onClick={markAllAsRead}>*/}
                            {/*        <FormattedMessage defaultMessage="Удалить все" />*/}
                            {/*    </Link>*/}
                            {/*)}*/}
                        </Header>
                        <ErrorBoundaryContainer>
                            {notFound ? (
                                <Empty style={{ border: 'none', color: 'var(--main-second-text-color)' }}>
                                    <FormattedMessage defaultMessage="У вас пока нет ни одного уведомления" />
                                </Empty>
                            ) : (
                                <NoticeList
                                    list={notices.data?.notices}
                                    markAsRead={markAsRead}
                                    setActive={setActive}
                                />
                            )}
                        </ErrorBoundaryContainer>
                        {notices.data?.count !== 0 && windowWidth > 600 && (
                            <Footer>
                                <Link
                                    to={ROUTE.NOTICES}
                                    onClick={() => {
                                        setActive(false)
                                    }}
                                >
                                    <FormattedMessage defaultMessage="Show all" />
                                </Link>
                            </Footer>
                        )}
                    </DropdownStyled>
                </>
            )}
        </Container>
    )
}

interface NoticeListProps {
    list?: Notice[]
    markAsRead: (id: number) => void
    setActive: any
}

const NoticeList: React.FC<NoticeListProps> = ({ list, markAsRead, setActive }) => {
    return list?.map((item) => (
        <NoticeWrap size={12} align="flex-end" key={item.id}>
            <PractikaLogo width={26} />
            <NoticeItem isRead={!item.new} onClick={() => markAsRead(item.id)}>
                <ReadIndicator>
                    <span />
                </ReadIndicator>
                {item.notice === 'new-wallet' ? (
                    <div>
                        <Text>
                            <FormattedMessage defaultMessage="You have new IBAN address" />: <br />
                            {item?.payload?.wallet}
                        </Text>
                    </div>
                ) : item.notice === 'ticket-answer' ? (
                    <div>
                        <Text>
                            <FormattedMessage defaultMessage="You received a response from support" />
                            {item.payload?.ticketId && (
                                <Link
                                    to={`${ROUTE.SUPPORT}/${item.payload?.ticketId}`}
                                    onClick={() => setActive(false)}
                                >
                                    <FormattedMessage defaultMessage="To the ticket" />
                                </Link>
                            )}
                        </Text>
                    </div>
                ) : (
                    <div>
                        <Text>{item.notice}</Text>
                        {item.payload?.ticketId && (
                            <Link to={`${ROUTE.SUPPORT}/${item.payload?.ticketId}`} onClick={() => setActive(false)}>
                                <FormattedMessage defaultMessage="To the ticket" />
                            </Link>
                        )}
                    </div>
                )}
                <Date>
                    <FormattedDate
                        value={item.date}
                        day="numeric"
                        month="long"
                        year="numeric"
                        hour="numeric"
                        minute="numeric"
                    />
                </Date>
            </NoticeItem>
        </NoticeWrap>
    ))
}

const NoticeWrap = styled(Space)`
    @media (max-width: 600px) {
        margin-bottom: 10px;
    }
`

const PractikaLogo = styled(LogoSvg)`
    display: none;

    @media (max-width: 600px) {
        display: block;
        min-width: 26px;
    }
`

const Date = styled.div`
    display: none;
    margin-top: 10px;
    font-size: 13px;
    color: var(--main-second-text-color);

    @media (max-width: 600px) {
        display: block;
    }
`

const DropdownStyled = styled(AdaptiveDropdown)`
    width: 296px;
    padding: 0;

    @media (max-width: 768px) {
        width: 100%;
        margin-top: -15px;
        margin-left: 0;
    }
`

const Container = styled.div`
    position: relative;
    z-index: 4;

    @media (max-width: 768px) {
        position: static;
        display: flex;
        align-items: center;
    }
`

const NoticeIndicator = styled.div`
    position: absolute;
    top: 7px;
    right: 9px;
    width: 8px;
    height: 8px;
    background: rgba(255, 82, 82, 1);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
    border: 2px solid var(--bg-color-2);
    border-radius: 100%;
    transition: 0.25s border-color;
    animation: pulse-red 2s infinite;

    @keyframes pulse-red {
        0% {
            transform: scale(0.9);
            box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
        }

        70% {
            transform: scale(1);
            box-shadow: 0 0 0 6px rgba(255, 82, 82, 0);
        }

        100% {
            transform: scale(0.9);
            box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
        }
    }

    @media (max-width: 500px) {
        top: -3px;
        right: 0;
        border-width: 1px;
    }
`

const NoticeIcon = styled(IconBlock)`
    cursor: pointer;
    transition:
        0.25s background,
        0.25s color;

    &:hover {
        background: #2281ff;
        color: #fff;

        ${NoticeIndicator} {
            border-color: #2281ff;
        }
    }

    @media (max-width: 600px) {
        width: 20px;
        height: 20px;
        background: none !important;
        color: #2281ff !important;

        ${NoticeIndicator} {
            border-color: #fff !important;
        }

        svg {
            width: 100%;
        }
    }
`

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding: 12px 20px;
    font-size: 15px;
    color: var(--main-text-color);
    border-bottom: 1px solid var(--light-bg-color);

    a,
    button {
        font-size: 12px;
        color: #9ba1aa !important;

        &:hover {
            color: #2281ff !important;
        }
    }

    @media (max-width: 600px) {
        display: none;
    }
`

const Text = styled.div`
    flex: 1;
`

const ReadIndicator = styled.div`
    margin-right: 15px;

    span {
        display: block;
        width: 10px;
        height: 10px;
        margin-top: 7px;
        border-radius: 100%;
        background: #2281ff;
    }

    @media (max-width: 600px) {
        display: none;
    }
`

const NoticeItem = styled.div<{ isRead?: boolean }>`
    display: flex;
    width: 100%;
    padding: 20px;
    text-align: left;
    border-bottom: 1px solid var(--light-bg-color);
    font-size: 14px;
    font-weight: 600;
    color: var(--main-text-color);
    transition: 0.25s background-color;

    &.isRead {
        font-weight: 400;
        color: var(--main-third-text-color);

        ${ReadIndicator} {
            span {
                background: var(--main-second-text-color);
            }
        }
    }

    &:hover {
        background-color: var(--bg-color-2);
    }

    @media (max-width: 600px) {
        flex-direction: column;
        padding: 12px 15px;
        border-bottom: none;
        background: var(--loader-bg-color-1);
        border-radius: 20px;
    }
`

const Footer = styled.div`
    display: flex;
    justify-content: center;
    padding: 12px;
    font-size: 12px;

    @media (max-width: 600px) {
        padding-bottom: 0;
    }
`

export default NoticeDropDown
