import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { ROUTE } from 'constants/routes'
import { Space } from 'capricorn-ui'
import { useNavigate } from 'react-router-dom'
import { useStore } from 'src/store'
import ChevronSvg from 'assets/icons/chevron-right.svg'
import MobilePageHeader from 'components/mobile/MobilePageHeader'
import PageTitle from 'components/atoms/PageTitle'
import ThemeSwitcher from 'components/molecules/ThemeSwitcher'
import styled from 'astroturf/react'

const MobileProfilePage = () => {
    const { auth } = useStore()
    const navigate = useNavigate()

    return (
        <Space style={{ flex: 1, display: 'flex', flexDirection: 'column' }} column size={32}>
            <MobilePageHeader titleAlignLeft noBack rightContent={<ThemeSwitcher />}>
                <PageTitle>
                    <FormattedMessage defaultMessage="Profile" />
                </PageTitle>
            </MobilePageHeader>
            <PageTitle sub>
                <FormattedMessage defaultMessage="Settings" />
            </PageTitle>
            <MobileTabs>
                <MobileTab onClick={() => navigate(ROUTE.SETTINGS_PERSONAL_DATA)}>
                    <FormattedMessage defaultMessage="Personal data" />
                    <ChevronSvg width={8} />
                </MobileTab>
                <MobileTab onClick={() => navigate(ROUTE.SETTINGS_PASSWORD)}>
                    <FormattedMessage defaultMessage="Password" />
                    <ChevronSvg width={8} />
                </MobileTab>
                <MobileTab onClick={() => navigate(ROUTE.SETTINGS_MAIN_ACCOUNT)}>
                    <FormattedMessage defaultMessage="Main account currency" />
                    <ChevronSvg width={8} />
                </MobileTab>
            </MobileTabs>
            <PageTitle sub>
                <FormattedMessage defaultMessage="Security" />
            </PageTitle>
            <MobileTabs>
                <MobileTab onClick={() => navigate(ROUTE.SECURITY_AUTH)}>
                    <FormattedMessage defaultMessage="Authentification" />
                    <ChevronSvg width={8} />
                </MobileTab>
                <MobileTab onClick={() => navigate(ROUTE.SECURITY_LOGIN_HISTORY)}>
                    <FormattedMessage defaultMessage="Login history" />
                    <ChevronSvg width={8} />
                </MobileTab>
                <MobileTab onClick={() => navigate(ROUTE.SECURITY_IP_WHITE_LIST)}>
                    <FormattedMessage defaultMessage="IP white list" />
                    <ChevronSvg width={8} />
                </MobileTab>
                <MobileTab onClick={() => navigate(ROUTE.SECURITY_API_TOKENS)}>
                    <FormattedMessage defaultMessage="API Tokens" />
                    <ChevronSvg width={8} />
                </MobileTab>
            </MobileTabs>
            <PageTitle onClick={() => navigate(ROUTE.FEES)} sub>
                <FormattedMessage defaultMessage="Fees" />
            </PageTitle>
            <MobileTabs>
                <MobileTab onClick={() => navigate(`${ROUTE.FEES}?defaultTab=business`)}>
                    <FormattedMessage defaultMessage="Business" />
                    <ChevronSvg width={8} />
                </MobileTab>
                <MobileTab onClick={() => navigate(`${ROUTE.FEES}?defaultTab=individual`)}>
                    <FormattedMessage defaultMessage="Individual" />
                    <ChevronSvg width={8} />
                </MobileTab>
                {auth.user?.settings?.allowCardIssue && (
                    <MobileTab onClick={() => navigate(`${ROUTE.FEES}?defaultTab=cards`)}>
                        <FormattedMessage defaultMessage="Cards" />
                        <ChevronSvg width={8} />
                    </MobileTab>
                )}
            </MobileTabs>
            <PageTitle onClick={() => navigate(ROUTE.SUPPORT)} sub>
                <FormattedMessage defaultMessage="Support" />
            </PageTitle>
            <MobileTabs>
                <MobileTab onClick={() => navigate(ROUTE.SUPPORT)}>
                    <FormattedMessage defaultMessage="My requests" />
                    <ChevronSvg width={8} />
                </MobileTab>
            </MobileTabs>
        </Space>
    )
}

const MobileTabs = styled.div``

const MobileTab = styled(Space)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--bg-color-1);
    padding: 16px 20px;
    margin-bottom: 5px;
    font-size: 16px;
    border-radius: 6px;

    & > div {
        flex: 1;
        border-bottom: 1px solid var(--bg-color-6);
        padding-bottom: 16px;
    }

    svg {
        color: #2281ff;
    }
`

export default MobileProfilePage
