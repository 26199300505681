import { IModalProps } from 'components/molecules/Modal'
import { useWindowSize } from 'react-use'
import Dropdown, { DropdownProps } from 'capricorn-ui/esm/Dropdown'
import EmptyModal from 'components/modals/EmptyModal'
import React, { FC } from 'react'
import ReactDOM from 'react-dom'

interface Props extends Omit<DropdownProps, 'onChange'> {
    onChange: (active: boolean) => void
    modalProps?: IModalProps
}

const AdaptiveDropdown: FC<Props> = ({ active, onChange, modalProps, ...props }) => {
    const windowWidth = useWindowSize().width

    return windowWidth > 600 ? (
        <Dropdown active={active} {...props} />
    ) : (
        active &&
            ReactDOM.createPortal(
                <EmptyModal {...modalProps} onCancel={() => onChange(false)}>
                    {props.children}
                </EmptyModal>,
                document.getElementById('modal-portal')!,
            )
    )
}

export default AdaptiveDropdown
