import { LOCALE } from 'constants/locales'
import { MASS_TRANSFER_FORMAT } from 'src/interfaces'

export const callIfExist = (func: unknown) => {
    if (typeof func === 'function') {
        func()
    }
}

export async function asyncForEach<T>(array: T[], callback: (item: T, index: number, array: T[]) => Promise<void>) {
    for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array)
    }
}

export const getNavigatorLanguage = () => {
    if (!window.navigator) {
        return LOCALE.EN
    }

    const language =
        Array.isArray(navigator.languages) && navigator.languages[0] ? navigator.languages[0] : navigator.language

    return language.substring(0, 2)
}

export const padZero = (time: number) => (time < 10 ? '0' + time : String(time))

export const secondsToDigitsTime = (val: number) => {
    const value = Math.round(val)
    const minutes = padZero((value / 60) | 0)
    const seconds = padZero(value % 60)

    return `${minutes}:${seconds}`
}

// Превращает массив-строк в json-объект с нужными полями, которые зависят от названия системы
const massTransferToFormat = (massTransfer: string[]): Record<string, string> => {
    const transferName = massTransfer[0].toLowerCase()
    const transferJson: Record<string, string> = {}
    massTransfer.forEach((value, index) => {
        if (value !== '') {
            const key = MASS_TRANSFER_FORMAT[transferName.toUpperCase()][index]
            transferJson[key] = value
        }
    })

    return transferJson
}

export const csvToMassPaymentJson = (csv: string) => {
    const lines = csv.split('\n')
    const result = []

    for (let i = 0; i < lines.length; i++) {
        const currentLine = lines[i].split(';')
        const currentObj = massTransferToFormat(currentLine)

        result.push(currentObj)
    }

    return JSON.stringify(result)
}

export function capitalizeFirstLetter(string: string) {
    return string && string.charAt(0).toUpperCase() + string.slice(1)
}
