export const SERVER_TIMEOUT = STAGE === 'development' ? 999999 : 20000

export const VERIFY_CODE_TEL_LENGTH = 6

export const RESEND_VERIFY_CODE_DELAY = 30
//24 * 60 * 60 * 1000
// export const USER_AUTO_LOGOUT_WARNING_TIME = STAGE === 'development' ? 24 * 60 * 60 * 1000 : 10 * 60 * 1000
// // Time after warning
// export const USER_AUTO_LOGOUT_TIME_IN_SECONDS = 2 * 60 * 1000

export const USER_AUTO_LOGOUT_WARNING_TIME = 6 * 1000
// Time after warning
export const USER_AUTO_LOGOUT_TIME = 4 * 1000
