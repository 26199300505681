import * as React from 'react'
import { useWindowSize } from 'react-use'
import styled from 'astroturf/react'

interface Props {
    children: React.ReactNode
    hideOnMobile?: boolean
    asPageTitleOnMobile?: boolean
}

const FormGroupTitle: React.FC<Props> = ({ children, hideOnMobile, ...rest }) => {
    const windowWidth = useWindowSize().width

    return windowWidth <= 600 && hideOnMobile ? null : <Container {...rest}>{children}</Container>
}

const Container = styled.div<{ asPageTitleOnMobile?: boolean }>`
    font-weight: 600;
    font-size: 18px;
    line-height: 1;

    @media (max-width: 600px) {
        &.asPageTitleOnMobile {
            font-weight: 400;
            font-size: 28px;
        }
    }
`

export default FormGroupTitle
