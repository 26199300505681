import { Controller } from 'react-hook-form'
import { ControllerProps } from 'containers/types'
import { FieldValues } from 'react-hook-form/dist/types'
import { PhoneInput } from 'capricorn-ui/esm'
import { TextInputProps } from 'capricorn-ui/esm/TextInput'
import { useWindowSize } from 'react-use'
import React from 'react'

type CapricornProps = Omit<TextInputProps, 'name' | 'onChange' | 'value' | 'label'>

interface Props<FieldsType extends FieldValues> extends ControllerProps<FieldsType>, CapricornProps {
    label: string
}

const ControlledPhoneInput = <FieldTypes extends FieldValues>({
    control,
    name,
    textInputStyles,
    view,
    ...rest
}: Props<FieldTypes>) => {
    const windowWidth = useWindowSize().width

    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState }) => (
                <PhoneInput
                    view={windowWidth <= 600 ? 'underscore' : view}
                    staticLabel={windowWidth <= 600}
                    data-cy={name}
                    onChange={field.onChange}
                    value={field.value}
                    error={fieldState.error?.message}
                    placeholder={windowWidth <= 600 ? `Enter ${rest.label?.replace('*', '').toLowerCase()}` : undefined}
                    fullWidth={!rest.maxWidth}
                    style={{ flex: 1, minWidth: 300 }}
                    textInputStyles={{
                        ...textInputStyles,
                        fontSize: textInputStyles?.fontSize || (windowWidth <= 600 ? 17 : undefined),
                    }}
                    {...rest}
                />
            )}
        />
    )
}

export default ControlledPhoneInput
