import { FormattedMessage, useIntl } from 'react-intl'
import { MOTION_TRANSITION, PAGE_VARIANTS_FADE_RIGHT } from 'src/constants'
import { motion } from 'framer-motion'
import { useLocation, useTitle } from 'react-use'
import { useStore } from 'src/store'
import ApiService from 'services/ApiService'
import AuthService from 'src/services/AuthService'
import AuthTemplate from 'components/templates/AuthTemplate'
import React, { Dispatch, DispatchWithoutAction, SetStateAction, useState } from 'react'
import Step1 from './registration-steps/Step1'
import Step2 from './registration-steps/Step2'
import Step3 from './registration-steps/Step3'
import Step4 from 'components/pages/registration-steps/Step4'
import Step5 from './registration-steps/Step5'
import Step6 from './registration-steps/Step6'

const initialState = {
    step: 1,
    login: '',
    phone: '',
    email: '',
    password: '',
    passwordConfirmation: '',
    code: '',
}

export type RegistrationState = typeof initialState

export interface RegistrationChildProps {
    state: RegistrationState
    setState: Dispatch<SetStateAction<Partial<RegistrationState>>>
    sendOTP: (
        phone: string,
        onSuccess?: DispatchWithoutAction,
        forExpiredSession?: boolean,
        recaptcha?: string,
    ) => Promise<unknown>
}

const RegistrationPage: React.FC = () => {
    const { notice, common } = useStore()
    const intl = useIntl()

    useTitle(`Practika | ${intl.formatMessage({ defaultMessage: 'Регистрация' })}`)

    const location = useLocation()
    const params = new URLSearchParams(location.search)

    const [state, setState] = useState<RegistrationState>(initialState)

    const sendOTP = async (
        phone: string,
        onSuccess?: DispatchWithoutAction,
        forExpiredSession?: boolean,
        recaptcha?: string,
    ) => {
        try {
            const response = await (forExpiredSession
                ? ApiService.request(`/user/send-otp?login=${state.login}&email=${state.email}`)
                : AuthService.signUpPhoneNumber(common.locale as string, phone, recaptcha))

            if (response['user-already-exists'] === 'with-password') {
                notice.show({
                    title: <FormattedMessage defaultMessage="Пользователь уже существует" />,
                    message: (
                        <FormattedMessage defaultMessage="Пользователь с указанным номером телефона уже зарегистрирован, попробуйте войти." />
                    ),
                })
            } else {
                if (onSuccess) {
                    onSuccess()
                }
            }
        } catch (e) {
            notice.showCommonError(e)
        }
    }

    const getStep = () => {
        if (params.get('email') || state.step === 4) {
            return <Step4 state={state} setState={setState} />
        } else if (state.step === 1) {
            return <Step1 state={state} setState={setState} sendOTP={sendOTP} />
        } else if (state.step === 2) {
            return <Step2 state={state} setState={setState} sendOTP={sendOTP} />
        } else if (state.step === 3) {
            return <Step3 state={state} setState={setState} />
        } else if (state.step === 5) {
            return <Step5 state={state} setState={setState} sendOTP={sendOTP} />
        } else if (state.step === 6) {
            return <Step6 state={state} setState={setState} sendOTP={sendOTP} />
        }
        return false
    }

    return (
        <AuthTemplate>
            {ENV !== 'browser' && <div />}
            <motion.div
                key={state.step}
                initial="initial"
                animate="in"
                variants={PAGE_VARIANTS_FADE_RIGHT}
                transition={MOTION_TRANSITION}
            >
                {getStep()}
            </motion.div>
        </AuthTemplate>
    )
}

export default RegistrationPage
