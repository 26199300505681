import * as yup from 'yup'
import { API } from 'src/constants/api'
import { AuthButton, AuthPageTitle, AuthStepInfo } from 'components/templates/AuthTemplate'
import { FormattedMessage, useIntl } from 'react-intl'
import { ROUTE } from 'constants/routes'
import { RegistrationChildProps } from 'components/pages/RegistrationPage'
import { Space, Tip } from 'capricorn-ui'
import { VERIFY_CODE_TEL_LENGTH } from 'src/constants/config'
import { useLocation, useNavigate } from 'react-router-dom'
import { useStore } from 'src/store'
import { useWindowSize } from 'react-use'
import ApiService from 'services/ApiService'
import ControlledTextInput from 'containers/ControlledTextInput'
import Link from 'containers/Link'
import React, { useEffect, useState } from 'react'
import styled from 'astroturf/react'
import useFormEnhanced from 'src/hooks/useFormEnhanced'

interface FormData {
    code: string | null
}

const Step4: React.FC<Omit<RegistrationChildProps, 'sendOTP'>> = ({ state, setState }) => {
    const location = useLocation()
    const navigate = useNavigate()
    const { notice } = useStore()
    const intl = useIntl()
    const windowWidth = useWindowSize().width
    const params = new URLSearchParams(location.search)

    const schema = () =>
        yup.object().shape({
            code: yup
                .string()
                .nullable()
                .required()
                .min(
                    VERIFY_CODE_TEL_LENGTH,
                    intl.formatMessage(
                        { defaultMessage: 'Must be exactly {count} digits' },
                        { count: VERIFY_CODE_TEL_LENGTH },
                    ),
                ),
        })

    const { control, watch, handleSubmit } = useFormEnhanced<FormData>({
        schema,
        defaultValues: { code: params.get('token') },
    })

    const [loading, setLoading] = useState(false)

    const checkCode = () => {
        const email = state.email || params.get('email') || ''
        const code = watch('code')
        setLoading(true)

        ApiService.request(`${API.USER_VERIFY_EMAIL}?email=${encodeURIComponent(email)}&code=${code}`, {})
            .then((response: { phoneLogin: string }) => {
                notice.show({
                    title: <FormattedMessage defaultMessage="Спасибо! E-mail успешно подтвержден." />,
                    skin: 'success',
                })
                // phoneLogin - номер телефона, в случае если юзер закрыл окно и перешел прямиком с мыла
                setState({
                    ...state,
                    email,
                    phone: state.phone || response.phoneLogin,
                    step: 5,
                })
                params.delete('email')

                navigate(ROUTE.SIGN_UP, {
                    replace: true,
                    state: { search: params.toString() },
                })
            })
            .catch((e) => {
                switch (e?.response?.data?.error) {
                    case 'invalid-code':
                        notice.show({
                            title: <FormattedMessage defaultMessage="Недействительный код" />,
                            message: (
                                <FormattedMessage defaultMessage="Проверьте правильность введенных данных или попробуйте пройти процедуру подтверждения заново." />
                            ),
                        })
                        break
                    default:
                        notice.showCommonError(e)
                }
            })
            .finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        if (watch('code')?.length === VERIFY_CODE_TEL_LENGTH) {
            checkCode()
        }
    }, [watch('code')])

    return (
        <form onSubmit={handleSubmit(checkCode)}>
            <Space column>
                <AuthPageTitle>
                    <FormattedMessage defaultMessage="Подтверждение E-mail" />
                </AuthPageTitle>
                <AuthStepInfo>
                    {' '}
                    <FormattedMessage
                        defaultMessage="Enter the verification code that we sent you to <b>{email}</b>."
                        values={{
                            b: (word: string) => <b>{word}</b>,
                            email: state.email || params.get('email'),
                        }}
                    />
                </AuthStepInfo>
                <StyledSpace column={windowWidth < 768} size={40}>
                    <ControlledTextInput
                        name="code"
                        type="code"
                        control={control}
                        autoFocus
                        codeLength={VERIFY_CODE_TEL_LENGTH}
                    />
                    <AuthButton type="submit" loading={loading}>
                        <FormattedMessage defaultMessage="Подтвердить" />
                    </AuthButton>
                </StyledSpace>
                <Space wrap size="16px 40px">
                    <Tip
                        customButton={<span />}
                        title={
                            <Link bold>
                                <FormattedMessage defaultMessage="Не приходит код подтверждения?" />
                            </Link>
                        }
                    >
                        <TipContent>
                            <FormattedMessage
                                defaultMessage="<b>Возможные причины:</b>{brake}
                            <ol>
                                <li>
                                    Письмо попало в папку спам.
                                </li>
                                <li>
                                    Вы ввели неверный E-mail. Обратитесь в службу поддержки Practika.
                                </li>
                            </ol>"
                                values={{
                                    b: (word: string) => <b>{word}</b>,
                                    ol: (word: string) => <ol>{word}</ol>,
                                    li: (word: string) => <li>{word}</li>,
                                    brake: <br />,
                                }}
                            />
                        </TipContent>
                    </Tip>
                    <Tip
                        closeOnBlur
                        onlyTitle
                        title={
                            <Link bold>
                                <FormattedMessage defaultMessage="Изменить e-mail" />
                            </Link>
                        }
                    >
                        <TipContent>
                            <FormattedMessage defaultMessage="Обратитесь в службу поддержки Practika" />
                        </TipContent>
                    </Tip>
                </Space>
            </Space>
        </form>
    )
}

const StyledSpace = styled(Space)`
    @media (max-width: 768px) {
        margin-top: 0 !important;
    }
`

const TipContent = styled.div`
    font-size: 12px;

    ol {
        margin: 10px 0 0 0;
        padding: 0 0 0 15px;
    }
`

export default Step4
