import { LOCALE } from './locales'
// import DeSvg from 'src/assets/icons/flag/germany.svg'
// import ESSvg from 'src/assets/icons/flag/spain.svg'
import EnSvg from 'src/assets/icons/flag/en.svg'
// import FrSvg from 'src/assets/icons/flag/france.svg'
import React, { ReactElement } from 'react'
import RuSvg from 'src/assets/icons/flag/ru.svg'

export const languages: Record<
    LOCALE,
    {
        name: string
        icon: ReactElement
    }
> = {
    [LOCALE.EN]: {
        name: 'English',
        icon: <EnSvg />,
    },
    [LOCALE.RU]: {
        name: 'Русский',
        icon: <RuSvg />,
    },
    // [LOCALE.DE]: {
    //     name: 'Deutsch',
    //     icon: <DeSvg />,
    // },
    // [LOCALE.FR]: {
    //     name: 'Français',
    //     icon: <FrSvg />,
    // },
    // [LOCALE.ES]: {
    //     name: 'Español',
    //     icon: <ESSvg />,
    // },
}
