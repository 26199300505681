export enum ROUTE {
    SIGN_IN = '/sign-in',
    SIGN_UP = '/sign-up',
    PHONE_VERIFICATION = '/sign-up/phone-verification',
    EMAIL_VERIFICATION = '/email-verification',
    FORGOT = '/forgot',
    SIGN_OUT = '/sign-out',

    MOBILE_HOME = '/home',
    MOBILE_PIN_CODE = '/pin-code',
    HOME = '/s/accounts',
    TOP_UP = '/s/top-up',
    CARDS = '/s/cards',
    NOTICES = '/s/notifications',

    CARDS_ISSUE = '/c/issue-card',

    VERIFICATION = '/v/verification',

    TRANSFERS = '/s/transfers',
    TRANSFERS_CRYPTO = '/s/transfers/crypto',
    TRANSFERS_BANK = '/s/transfers/bank',
    TRANSFERS_INTERNAL = '/s/transfers/internal',
    TRANSFERS_CARD = '/s/transfers/card',
    TRANSFERS_WALLET = '/s/transfers/wallet',
    TRANSFERS_MASS = '/s/transfers/mass',

    OPERATION_TRANSFERS = '/s/history/transfers',
    OPERATION_MASS = '/s/history/mass',
    OPERATION_CARDS = '/s/history/cards',

    HISTORY_OPERATIONS = '/s/history',
    EXCHANGE = '/s/exchange',
    TEMPLATES = '/s/templates',
    DRAFTS = '/s/drafts',
    SUPPORT = '/s/support',
    FEES = '/s/fees',

    SETTINGS = '/s/settings',
    SETTINGS_PERSONAL_DATA = '/s/settings/personal-data',
    SETTINGS_PASSWORD = '/s/settings/password',
    SETTINGS_MAIN_ACCOUNT = '/s/settings/main-account',

    SECURITY = '/s/security',
    SECURITY_AUTH = '/s/security/auth',
    SECURITY_LOGIN_HISTORY = '/s/security/login-history',
    SECURITY_IP_WHITE_LIST = '/s/security/ip-white-list',
    SECURITY_API_TOKENS = '/s/security/tokens',
    SECURITY_NOTICES = '/s/security/notices',

    REFERRAL = '/s/referral',
    REFERRAL_LIST = '/s/referral/list',
    REFERRAL_REPORTS = '/s/referral/reports',
    REFERRAL_PROMO = '/s/referral/promo',

    BACKOFFICE_PRODUCTION_URL = 'https://b2.practika.cometa.biz',

    AGENTS_DASHBOARD = '/s/agents/dashboard',
    MERCHANTS = '/s/merchants',

    MOBILE_PROFILE = '/s/mobile-profile',
}
