import { Surface } from 'capricorn-ui'
import RoundIcon from 'components/atoms/RoundIcon'
import styled from 'astroturf/react'

export const OperationListHeader = styled.div<{ noMarginBottom?: boolean }>`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 20px;
    padding: 0 32px;

    &.noMarginBottom {
        margin-bottom: 0;
    }

    @media (max-width: 1100px) {
        padding: 0 20px;
    }

    @media (max-width: 420px) {
        padding: 0 15px;
    }
`

export const ContentSurface = styled(Surface)`
    position: relative;
    padding: 32px 0;
    transition: none;

    @media (max-width: 600px) {
        padding: 0;
        background: transparent !important;
        margin: 0 -15px;
        width: calc(100% + 30px);
        box-shadow: none !important;
    }
`

export const ExchangeIcons = styled.div`
    position: relative;
    height: 50px;
    width: 42px;
    margin-right: 15px;
`

export const ExchangeIcon = styled(RoundIcon)<{ from?: boolean; to?: boolean; smallSize?: boolean }>`
    position: absolute;
    background: var(--bg-color-2);
    border: 1px solid var(--bg-color-3);
    font-weight: 600;

    &.from {
        top: 0;
        left: 0;
        color: #2281ff;
    }

    &.to {
        bottom: 0;
        right: 0;
        color: #49ca1c;
    }

    &.smallSize {
        min-width: 22px !important;
        width: 22px !important;
        height: 22px !important;
        font-size: 13px;
    }

    @media (max-width: 600px) {
        background: #2281ff;
        color: #fff !important;
    }
`

export const TransferIcon = styled(RoundIcon)`
    min-width: 42px !important;
    height: 42px !important;
    margin-right: 15px !important;
    color: #2281ff;

    svg {
        height: 42px;
    }

    @media (max-width: 600px) {
        min-width: 35px !important;
        height: 35px !important;
        background: #2281ff !important;
        color: #fff !important;

        svg {
            height: auto !important;
            width: 20px !important;
            color: #fff;
        }
    }
`

export const OperationComment = styled.div`
    font-size: 12px;
    max-width: 340px;
    color: var(--main-third-text-color);
    line-height: 1.3;
    margin-top: 7px !important;

    @media (max-width: 600px) {
        margin-top: 4px !important;
    }
`

export const OperationName = styled.div<{ isExchange?: boolean }>`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    line-height: 1.5;
    color: var(--main-text-color);

    b {
        font-weight: 600;
    }

    @media (max-width: 600px) {
        line-height: 1.15;
        max-width: 170px;

        &.isExchange {
            margin-top: 4px;
        }

        b {
            font-weight: 400;
        }
    }
`

export const OperationAmount = styled.div<{ hide?: boolean }>`
    display: flex;
    align-items: center;
    font-size: 14px;
    color: var(--main-text-color);
    white-space: nowrap;

    &.hide {
        display: none;
    }
`
