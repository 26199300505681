import * as React from 'react'
import { MOTION_TRANSITION, PAGE_VARIANTS_FADE_UP } from 'src/constants'
import { Outlet, useLocation } from 'react-router-dom'
import { ROUTE } from 'constants/routes'
import { memo } from 'react'
import { motion } from 'framer-motion'
import { useStore } from 'src/store'
import { useWindowSize } from 'react-use'
import ErrorBoundaryContainer from 'containers/ErrorBoundaryContainer'
import Footer from './Footer'
import Header from './Header'
import Sidebar from './Sidebar'
import VerifyAlert from 'components/molecules/VerifyAlert'
import styled from 'astroturf/react'

const PrivateTemplate: React.FC = () => {
    const { common } = useStore()
    const location = useLocation()
    const windowWidth = useWindowSize().width
    const isHomePage = location.pathname === ROUTE.HOME

    return (
        <Container>
            {(!location.search.includes('hideMenu') || windowWidth > 600) && <Sidebar />}
            <Content
                id="page-content"
                mobileDisableScroll={location.pathname.includes(`${ROUTE.HOME}/`)}
                hideMenu={location.search.includes('hideMenu')}
                openedMenu={common.isOpenedMenu}
            >
                {windowWidth > 600 && <Header withMenuIcon />}
                <Page
                    isHomePage={isHomePage}
                    openedMenu={common.isOpenedMenu}
                    mobileGradientBg={
                        location.pathname.includes(`${ROUTE.HOME}/`) || location.pathname.includes(`${ROUTE.CARDS}/`)
                    }
                    mobileGradientDarkBg={location.pathname.includes(`${ROUTE.CARDS}/`)}
                >
                    {/* FOR HOME PAGE SHOWING IN OTHER COMPONENT */}
                    {!isHomePage && <VerifyAlert />}
                    <PageContentWrap>
                        {windowWidth > 600 ? (
                            <>
                                <motion.div
                                    key={location.pathname.split('/')[2]}
                                    initial={false}
                                    animate="in"
                                    variants={PAGE_VARIANTS_FADE_UP}
                                    transition={MOTION_TRANSITION}
                                    style={{ display: 'flex', flexDirection: 'column', flex: 1 }}
                                >
                                    <ErrorBoundaryContainer>
                                        <Outlet />
                                    </ErrorBoundaryContainer>
                                </motion.div>
                                <FooterStyled isHomePage={isHomePage} />
                            </>
                        ) : (
                            <ErrorBoundaryContainer>
                                <Outlet />
                            </ErrorBoundaryContainer>
                        )}
                    </PageContentWrap>
                </Page>
            </Content>
            {/*<RightSidebar />*/}
        </Container>
    )
}

const Container = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    min-height: 100vh;
    max-width: 100vw;
    overflow: hidden;

    @media (max-width: 600px) {
        padding-bottom: calc(env(safe-area-inset-bottom) + 60px);
        box-sizing: border-box;
        overflow: auto;
    }
`

const Content = styled.div<{ openedMenu?: boolean; mobileDisableScroll?: boolean; hideMenu?: boolean }>`
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
    max-height: 100vh;
    overflow: auto;

    @media (max-width: 600px) {
        height: calc(100vh - 55px - env(safe-area-inset-bottom)) !important;
        overflow: auto;

        &.hideMenu ${Container} {
            padding-bottom: 0 !important;
        }
    }

    &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.5);
        content: '';
        opacity: 0;
        transition:
            0.25s opacity,
            0.25s visibility;
        visibility: hidden;
        z-index: 9;
    }

    &.openedMenu::after {
        opacity: 1;
        visibility: visible;
    }
`

const Page = styled.div<{
    isHomePage?: boolean
    openedMenu?: boolean
    mobileGradientBg?: boolean
    mobileGradientDarkBg?: boolean
}>`
    box-sizing: border-box;
    flex: 1;
    padding: 38px 46px;
    background: var(--bg-color-2);

    &.isHomePage {
        padding: 0;
    }

    &.openedMenu {
        overflow: hidden;
    }

    @media (max-width: 600px) {
        padding: calc(env(safe-area-inset-top) + 10px) 15px 48px;

        &.mobileGradientBg {
            background: linear-gradient(150deg, #0367df, #1ba9f8 50%);
        }

        &.mobileGradientDarkBg {
            background: linear-gradient(150deg, #141c2c, #3c4a65 50%);
        }

        &.isHomePage {
            padding: calc(env(safe-area-inset-top) + 10px) 15px 0 !important;
        }
    }
`

const PageContentWrap = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    min-height: 100%;
`

const FooterStyled = styled(Footer)<{ isHomePage?: boolean }>`
    background: var(--bg-color-2);
    margin-top: 48px;

    &.isHomePage {
        position: absolute;
        bottom: -90px;
        left: 48px;
        right: 48px;
        margin: 0;
        padding: 0;
        background: none;

        @media (max-width: 600px) {
            position: static;
            margin: -10px 0 20px;
            padding: 0 15px;
        }
    }
`

export default memo(PrivateTemplate)
