import { Button, Space } from 'capricorn-ui'
import { FormattedMessage } from 'react-intl'
import { Link, useNavigate } from 'react-router-dom'
import { ROUTE } from 'src/constants/routes'
import { Theme } from 'src/store/commonStore'
import { useStore } from 'src/store'
import { useWindowSize } from 'react-use'
import LogoSvg from 'src/assets/icons/logo-no-slogan.svg'
import NoticeDropDown from '../molecules/NoticeDropDown'
import React, { memo } from 'react'
import SwitchLang from '../molecules/SwitchLang'
import ThemeSwitcher from 'components/molecules/ThemeSwitcher'
import UserDropDown from '../molecules/UserDropDown'
import styled from 'astroturf/react'

interface Props {
    withLogo?: boolean
    withMenuIcon?: boolean
    noVerifyButton?: boolean
    button?: React.ReactNode
}

const Header: React.FC<Props> = ({ withLogo, withMenuIcon, noVerifyButton, button }) => {
    const { common, auth } = useStore()
    const windowWidth = useWindowSize().width
    const navigate = useNavigate()

    const needVerify = auth.user?.verificationStatus === 'none'

    const toggleMenu = () => {
        common.setIsOpenedMenu(!common.isOpenedMenu)
    }

    return (
        <Container theme={common.theme}>
            {withMenuIcon && (
                <MenuIcon id="burger-menu-icon" opened={common.isOpenedMenu} onClick={toggleMenu}>
                    <span />
                    <span />
                </MenuIcon>
            )}
            {withLogo && (
                <LinkStyled to={ROUTE.HOME}>
                    <LogoSvgStyled />
                </LinkStyled>
            )}
            <div style={{ flex: 1 }}>{/*<SwitchLangStyled dropdownProps={{ position: 'bottom-end' }} />*/}</div>
            <Space size={24} align="center">
                {windowWidth >= 600 && <ThemeSwitcherStyled />}
                <NoticeDropDown />
                <UserDropDown />
                {needVerify && !noVerifyButton && windowWidth > 720 && (
                    <VerifyButton data-cy="pass-verify" size="m" onClick={() => navigate(ROUTE.VERIFICATION)}>
                        <FormattedMessage defaultMessage="Complete Verification" />
                    </VerifyButton>
                )}
                {button}
            </Space>
        </Container>
    )
}

const ThemeSwitcherStyled = styled(ThemeSwitcher)`
    margin-right: 40px !important;

    @media (max-width: 768px) {
        margin-right: 20px !important;
        margin-left: 20px !important;
    }
`

const VerifyButton = styled(Button)`
    @media (max-width: 500px) {
        padding: 0 10px !important;
        height: 32px !important;
        font-size: 14px !important;
    }
`

const SwitchLangStyled = styled(SwitchLang)``

const MenuIcon = styled.div<{ opened?: boolean }>`
    display: none;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    box-sizing: border-box;
    width: 30px;
    height: 32px;
    margin: 0 15px 0 -8px;
    padding: 10px 6px;
    cursor: pointer;

    &.opened {
        span {
            &:first-child {
                transform: rotate(45deg);
            }

            &:last-child {
                transform: rotate(-45deg);
            }
        }
    }

    span {
        display: block;
        background: #2281ff;
        width: 100%;
        height: 2px;
        transition: transform 0.1596s cubic-bezier(0.52, 0.16, 0.52, 0.84) 0.1008s;

        &:first-child {
            transform-origin: 2px 4px;
        }

        &:last-child {
            transform-origin: 4px -1px;
        }
    }

    @media (max-width: 768px) {
        display: flex;
    }

    @media (max-width: 600px) {
        margin-left: 0;
    }
`

// relative - нужен для дродаунов для top: 100%
const Container = styled.div<{ theme?: Theme }>`
    position: relative;
    z-index: 11;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 50px;
    background: var(--bg-color-1);
    box-shadow: 10px 0 5px -2px rgba(0, 0, 0, 0.1);

    @media (max-width: 600px) {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        height: 50px;
        padding: 0 15px;
        backdrop-filter: saturate(180%) blur(20px);
        z-index: 20;

        &.theme-light {
            background: rgba(255, 255, 255, 0.72);
        }

        &.theme-dark {
            background: rgba(0, 0, 0, 0.72);
        }
    }

    @media (max-width: 512px) {
        box-shadow: 0 6px 12px rgba(73, 90, 102, 0.07);

        ${SwitchLangStyled} {
            display: none;
        }
    }
`

const LogoSvgStyled = styled(LogoSvg)`
    width: 125px;
    margin-right: 50px;
    color: var(--main-text-color);

    @media (max-width: 500px) {
        width: 110px;
        margin-right: 25px;
    }
`

const LinkStyled = styled(Link)`
    display: flex;
    align-items: center;
`

export default memo(Header)
