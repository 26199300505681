import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { Space } from 'capricorn-ui'
import Table from '../atoms/Table'
import styled from 'astroturf/react'

const CardFeesTable: React.FC = (props) => {
    return (
        <Space {...props} column>
            <TableStyled>
                <thead>
                    <tr>
                        <td colSpan={2}>
                            <FormattedMessage defaultMessage="Debit card" />
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <FormattedMessage defaultMessage="Debit card issue, price per card" />
                        </td>
                        <td>50.00 EUR</td>
                    </tr>
                    <tr>
                        <td>
                            <FormattedMessage defaultMessage="Debit card replacement, price per card" />
                        </td>
                        <td>50.00 EUR</td>
                    </tr>
                    <tr>
                        <td>
                            <FormattedMessage defaultMessage="Card delivery" />
                        </td>
                        <td>15.00 EUR</td>
                    </tr>
                    <tr>
                        <td>
                            <FormattedMessage defaultMessage="Monthly service fee per plastic card" />
                        </td>
                        <td>10.00 EUR</td>
                    </tr>
                    <tr>
                        <td>
                            <FormattedMessage defaultMessage="ATM disbursement fee" />
                        </td>
                        <td>2.50 EUR + 2.0%</td>
                    </tr>
                    <tr>
                        <td>
                            <FormattedMessage defaultMessage="Card TopUp from eWallet" />
                        </td>
                        <td>0.50%</td>
                    </tr>
                    <tr>
                        <td>
                            <FormattedMessage defaultMessage="Card withdrawal" />
                        </td>
                        <td>2.50 EUR + 2.0%</td>
                    </tr>
                    <tr>
                        <td>
                            <FormattedMessage defaultMessage="ATM balance inquiry / ATM decline fee" />
                        </td>
                        <td>2.00 EUR</td>
                    </tr>
                    <tr>
                        <td>
                            <FormattedMessage defaultMessage="FX" />
                        </td>
                        <td>3.0%</td>
                    </tr>
                    <tr>
                        <td>
                            <FormattedMessage defaultMessage="Chargeback processing fee" />
                        </td>
                        <td>50.00 EUR</td>
                    </tr>
                    <tr>
                        <td>
                            <FormattedMessage defaultMessage="POS transaction" />
                        </td>
                        <td>Free</td>
                    </tr>
                </tbody>
            </TableStyled>
            <TableStyled>
                <thead>
                    <tr>
                        <td colSpan={2}>
                            <FormattedMessage defaultMessage="Virtual card" />
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <FormattedMessage defaultMessage="Virtual card issue, price per card" />
                        </td>
                        <td>2.50 EUR</td>
                    </tr>
                    <tr>
                        <td>
                            <FormattedMessage defaultMessage="Monthly service fee per virtual card" />
                        </td>
                        <td>4.00 EUR</td>
                    </tr>
                    <tr>
                        <td>
                            <FormattedMessage defaultMessage="Card TopUp from eWallet" />
                        </td>
                        <td>0.50%</td>
                    </tr>
                    <tr>
                        <td>
                            <FormattedMessage defaultMessage="POS transaction" />
                        </td>
                        <td>0.20 EUR/Trx</td>
                    </tr>
                    <tr>
                        <td>
                            <FormattedMessage defaultMessage="FX" />
                        </td>
                        <td>3.0%</td>
                    </tr>
                </tbody>
            </TableStyled>
            <TableStyled>
                <thead>
                    <tr>
                        <td colSpan={2}>
                            <FormattedMessage defaultMessage="Usage Restrictions" />
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <FormattedMessage defaultMessage="ATM cash withdrawal, Daily limit" />
                        </td>
                        <td>1,000 EUR</td>
                    </tr>
                    <tr>
                        <td>
                            <FormattedMessage defaultMessage="ATM cash withdrawal, Monthly limit" />
                        </td>
                        <td>10,000 EUR</td>
                    </tr>
                    <tr>
                        <td>
                            <FormattedMessage defaultMessage="POS purchase + E-comm purchase, Daily limit" />
                        </td>
                        <td>20,000 EUR</td>
                    </tr>
                    <tr>
                        <td>
                            <FormattedMessage defaultMessage="POS purchase + E-comm purchase, Monthly limit" />
                        </td>
                        <td>40,000 EUR</td>
                    </tr>
                </tbody>
            </TableStyled>
        </Space>
    )
}

const TableStyled = styled(Table)`
    margin: 0;

    thead td {
        padding-top: 0 !important;
    }

    td {
        padding: 10px 15px !important;
        font-size: 1em;

        &:last-child {
            white-space: nowrap;
            width: 100px;
        }
    }

    @media (max-width: 600px) {
        width: calc(100% + 30px);
        margin: 0 -15px;
    }
`

export default CardFeesTable
