export type Currency = 'USD' | 'EUR' | 'GBP'

export const MASS_TRANSFER_FORMAT: Record<string, string[]> = {
    PRACTIKA: ['operationCode', 'accountNumber', 'amount', 'currency', 'paymentNumber', 'paymentTarget'],
    WORLDCARD: [
        'operationCode',
        'cardNumber',
        'amount',
        'currency',
        'paymentNumber',
        'paymentTarget',
        'cardFirstName',
        'cardLastName',
        'birthday',
        'address',
        'countryCode',
        'city',
        'expiryMonth',
        'expiryYear',
    ],
    CryptoUSDT: ['operationCode', 'address', 'network', 'amount', 'currency', 'paymentNumber'],
}

export type TAnalyticPeriod = '24h' | 'week' | 'month'
