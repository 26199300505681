import * as React from 'react'
import * as yup from 'yup'
import { AuthButton, AuthPageTitle, AuthStepInfo } from '../../templates/AuthTemplate'
import { FormattedMessage, useIntl } from 'react-intl'
import { RegistrationChildProps } from '../RegistrationPage'
import { Space } from 'capricorn-ui'
import { useState } from 'react'
import { useStore } from 'src/store'
import AuthService from 'src/services/AuthService'
import Bugsnag from '@bugsnag/js'
import ControlledTextInput from '../../../containers/ControlledTextInput'
import EmptyModal from 'components/modals/EmptyModal'
import LoginSvg from 'src/assets/icons/login.svg'
import ReactDOM from 'react-dom'
import Step2 from 'components/pages/registration-steps/Step2'
import useFormEnhanced from 'src/hooks/useFormEnhanced'

const schema = () =>
    yup.object().shape({
        login: yup.string().required(),
    })

interface FormData {
    login: string
}

const Step5: React.FC<RegistrationChildProps> = ({ state, setState, sendOTP }) => {
    const { notice } = useStore()
    const intl = useIntl()
    const [isOpenedModal, setIsOpenedModal] = useState(false)
    const { control, handleSubmit } = useFormEnhanced({
        schema,
    })

    const [loading, setLoading] = useState(false)

    const onSubmit = ({ login }: FormData) => {
        setLoading(true)

        AuthService.signUpLogin(state.phone, login)
            .then(() => {
                setState({ ...state, login, step: 6 })
            })
            .catch((e) => {
                switch (e?.response?.data.error) {
                    case 'login-already-taken':
                        notice.show({
                            title: (
                                <FormattedMessage defaultMessage="Пользователь с указанным логином уже зарегистрирован" />
                            ),
                            message: <FormattedMessage defaultMessage="Введите другой логин" />,
                        })
                        break
                    // Долгий юзер - код протух (20 мин)
                    case 'invalid-code':
                        setIsOpenedModal(true)
                        break
                    // Регистрация не завершена
                    case 'another-login-already-specified':
                        setState({ ...state, login, step: 6 })
                        break
                    default:
                        notice.showCommonError(e)
                }
            })
            .finally(() => {
                Bugsnag.setUser(undefined, state.email, login)
                setLoading(false)
            })
    }

    return (
        <>
            {isOpenedModal &&
                ReactDOM.createPortal(
                    <EmptyModal
                        maxWidth={800}
                        title={<FormattedMessage defaultMessage="Сессия истекла, пожалуйста, введите код повторно." />}
                        onCancel={() => setIsOpenedModal(false)}
                    >
                        <Step2
                            state={state}
                            setState={setState}
                            sendOTP={sendOTP}
                            isModalView
                            onSuccess={() => setIsOpenedModal(false)}
                        />
                    </EmptyModal>,
                    document.getElementById('modal-portal')!,
                )}
            <form onSubmit={handleSubmit(onSubmit)}>
                <Space column size={40}>
                    <AuthPageTitle>
                        <FormattedMessage defaultMessage="Create username to login" />
                    </AuthPageTitle>
                    <AuthStepInfo>
                        <FormattedMessage defaultMessage="В дальнейшем авторизация будет происходить по нему." />
                    </AuthStepInfo>
                    <ControlledTextInput
                        name="login"
                        control={control}
                        prefix={<LoginSvg width={32} />}
                        label={intl.formatMessage({ defaultMessage: 'Username' })}
                        maxWidth={600}
                        autoFocus
                    />
                    <AuthButton type="submit" loading={loading}>
                        <FormattedMessage defaultMessage="Продолжить" />
                    </AuthButton>
                </Space>
            </form>
        </>
    )
}

export default Step5
