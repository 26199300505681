import { API } from 'constants/api'
import { Notice } from 'src/interfaces/Notice'
import ApiService from './ApiService'

export interface GetNoticeListSuccessPayload {
    notices: Notice[]
    filter: 'new' | 'all'
    page: number
    pageCount: number
}

const NoticeService = {
    getNoticesList: (filter: string, limit: number, offset: number): Promise<GetNoticeListSuccessPayload> => {
        return ApiService.request(`${API.NOTICES}?filter=${filter}&limit=${limit}&offset=${offset}`)
    },

    markAsRead: (id: number): Promise<void> => {
        return ApiService.request(`${API.NOTICES}/markAsRead?ids=${id}`, {})
    },

    markAllAsRead: (): Promise<void> => {
        return ApiService.request(`${API.NOTICES}/markAsReadAll`, {})
    },
}

export default NoticeService
