import { AxiosError } from 'axios'
import { FormattedMessage } from 'react-intl'
import { ToastProps } from 'capricorn-ui/lib/Toast'
import { v4 as uuidv4 } from 'uuid'
import Bugsnag from '@bugsnag/js'
import React, { useState } from 'react'
import styled from 'astroturf/react'

export const NoticeStore = () => {
    const [notices, setNotices] = useState<Array<ToastProps & { id: string }>>([])

    const hideNotice = (id: string) => {
        const newArr = [...notices]
        newArr.splice(
            newArr.findIndex((i) => i.id === id),
            1,
        )
        setNotices(newArr)
    }

    const showNotice = (props: ToastProps, duration: number | 'infinity' = 3000) => {
        const id = uuidv4()

        setNotices([
            ...notices,
            {
                ...props,
                id,
            },
        ])

        if (duration !== 'infinity') {
            setTimeout(() => {
                hideNotice(id)
            }, duration)
        }
    }

    return {
        opened: notices,
        hide: hideNotice,
        show: showNotice,
        showVerificationRequired: () => {
            showNotice(
                {
                    title: (
                        <FormattedMessage defaultMessage="Данная функция недоступна в демо режиме. Вы всегда можете продолжить верификацию, нажав кнопку «Пройти верификацию» в правой верхней части экрана." />
                    ),
                    skin: 'error',
                },
                5000,
            )
        },
        showCommonError: (e?: AxiosError) => {
            if (STAGE !== 'production') {
                console.error('[ERROR]', typeof e, e)
            }
            if (e?.response?.status === 502) {
                showNotice({
                    title: <FormattedMessage defaultMessage="На сервере ведутся технические работы" />,
                    message: <FormattedMessage defaultMessage="Пожалуйста, попробуйте немного позже." />,
                })

                return
            }
            const error: Error & { body?: unknown } = new Error(
                `Notice: Something went wrong | ${e?.response?.data?.error || e || 'undefined'}`,
            )
            error.body = e
            Bugsnag.notify(error, (event) => {
                event.addMetadata('Error info', {
                    e,
                    response: e?.response,
                })
            })
            showNotice(
                {
                    title: <FormattedMessage defaultMessage="Что-то пошло не так" />,
                    message: (
                        <>
                            {e?.response?.data?.error ? (
                                <NoticeServerError>{e?.response?.data?.error}</NoticeServerError>
                            ) : (
                                <FormattedMessage defaultMessage="Пожалуйста, попробуйте немного позже." />
                            )}
                        </>
                    ),
                },
                e ? 'infinity' : 4000,
            )
        },
        hideLast: () => setNotices(notices.filter((_, index) => index !== notices.length - 1)),
        hideAll: () => setNotices([]),
    }
}

const NoticeServerError = styled.div`
    word-break: break-all;
    margin-top: 5px;
    padding: 4px 6px;
    border-radius: 5px;
    background: var(--bg-color-2);
`
