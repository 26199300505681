import { FormattedMessage } from 'react-intl'
import { ROUTE } from 'constants/routes'
import { Space, Spinner } from 'capricorn-ui'
import { secondsToDigitsTime } from 'src/utils'
import { useNavigate } from 'react-router-dom'
import { useStore } from 'src/store'
import BackSpace from 'assets/icons/backspace.svg'
// import FaceId from 'assets/icons/faceid.svg'
import Link from 'containers/Link'
import React, { useEffect, useState } from 'react'
import styled from 'astroturf/react'
import useLocalStorage from 'react-use/esm/useLocalStorage'

const CODE_LENGTH = 4
const TIME_TO_NEXT_ATTEMPTS = 1000 * 10
const MAX_ATTEMPTS = 3

const MobilePinCodePage: React.FC = () => {
    const { notice, auth } = useStore()
    const navigate = useNavigate()
    const [code, setCode] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [lastInvalid, setLastInvalid] = useLocalStorage('pinCodeLastInvalid', 0)
    const [attempts, setAttempts] = useLocalStorage('pinCodeAttempts', 0)
    const [step, setStep] = useState(0)
    const [pinCode, setPinCode] = useLocalStorage('pinCode')
    const [firstStepCode, setFirstStepCode] = useState()

    useEffect(() => {
        if (Date.now() - new Date(lastInvalid).getTime() >= TIME_TO_NEXT_ATTEMPTS) {
            setLastInvalid(0)
            setAttempts(0)
        }
    }, [])

    useEffect(() => {
        if (code.length === 4) {
            if (pinCode) {
                if (attempts === MAX_ATTEMPTS && Date.now() - new Date(lastInvalid).getTime() < TIME_TO_NEXT_ATTEMPTS) {
                    notice.show({
                        title: `Please try in ${secondsToDigitsTime(
                            (TIME_TO_NEXT_ATTEMPTS - (Date.now() - new Date(lastInvalid).getTime())) / 1000,
                        )}`,
                    })
                    setCode('')
                } else {
                    setIsLoading(true)
                    setTimeout(() => {
                        setIsLoading(false)
                        if (pinCode === code) {
                            navigate(ROUTE.HOME)
                            setAttempts(0)
                            setLastInvalid(0)
                        } else {
                            setCode('')
                            notice.show({
                                title: 'Incorrect code',
                                message:
                                    MAX_ATTEMPTS - attempts - 1 === 0
                                        ? `Try again in ${secondsToDigitsTime(TIME_TO_NEXT_ATTEMPTS / 1000)}`
                                        : `You have ${MAX_ATTEMPTS - attempts - 1} attempts left`,
                            })
                            setAttempts(attempts + 1)
                            setLastInvalid(Date.now)
                        }
                    }, 3500)
                }
            } else {
                if (step === 0) {
                    setStep(1)
                    setFirstStepCode(code)
                    setCode('')
                } else {
                    if (code === firstStepCode) {
                        setPinCode(code)
                        navigate(ROUTE.HOME)
                    } else {
                        setStep(0)
                        setFirstStepCode('')
                        setCode('')
                        notice.show({
                            title: 'Invalid code',
                            message: 'Please try again',
                        })
                    }
                }
            }
        }
    }, [code.length === 4])

    const onFaceId = () => {}

    return (
        <Wrap>
            <Link style={{ alignSelf: 'flex-end' }} href="mailto:support@practika.net">
                <FormattedMessage defaultMessage="Support" />
            </Link>
            <Space align="center" size={32} column>
                <Welcome>
                    {pinCode ? (
                        <>
                            <FormattedMessage
                                defaultMessage="Hello, {username}!"
                                values={{ username: auth.user?.login }}
                            />{' '}
                            <br />
                            <FormattedMessage defaultMessage="Enter your PIN to sign in." />
                        </>
                    ) : (
                        <>
                            {step === 0 ? (
                                <FormattedMessage defaultMessage="Set pin code to enter the application" />
                            ) : (
                                <FormattedMessage defaultMessage="Re-enter pin code" />
                            )}
                        </>
                    )}
                </Welcome>
                {isLoading ? (
                    <Spinner size={29} />
                ) : (
                    <Progress size={22}>
                        {[...Array(CODE_LENGTH).keys()].map((index) => (
                            <ProgressDot active={index + 1 <= code.length} key={index} />
                        ))}
                    </Progress>
                )}
            </Space>
            <Space style={{ marginBottom: 20 }} justify="center" size={26} wrap>
                {[...Array(9).keys()].map((num) => (
                    <Num onClick={() => setCode(`${code}${num + 1}`)} key={num + 1}>
                        {num + 1}
                    </Num>
                ))}
                <Num onClick={onFaceId} noBg>
                    {/*<FaceId width={28} />*/}
                </Num>
                <Num onClick={() => setCode(`${code}0`)}>0</Num>
                <Num onClick={() => setCode(code.slice(0, code.length - 1))} noBg>
                    <BackSpace width={28} />
                </Num>
            </Space>
        </Wrap>
    )
}

const Num = styled.div<{ noBg?: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;
    border-radius: 80px;
    background: rgba(0, 0, 0, 0.3);
    font-weight: 700;
    font-size: 30px;
    color: #fff;

    svg {
        color: #2281ff;
    }

    &.noBg {
        background: transparent;
    }
`

const ProgressDot = styled.div<{ active?: boolean }>`
    width: 13px;
    height: 13px;
    border-radius: 13px;
    background-color: #000;
    transition: 0.25s background-color;

    &.active {
        background-color: #2281ff;
    }
`

const Progress = styled(Space)`
    padding: 10px 0;
`

const Welcome = styled.div`
    text-align: center;
    font-size: 17px;
    color: #fff;
`

const Wrap = styled.div`
    position: relative;
    display: flex;
    gap: 10px;
    height: 100vh;
    overflow: hidden;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background: #1d2534;
    padding: 28px;
    box-sizing: border-box;
    line-height: 1.2;
    color: #fff;
`

export default MobilePinCodePage
