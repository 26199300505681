const currencies: Record<
    string,
    {
        title: string
        fullTitle: string
        name: string
        symbol: string
    }
> = {
    usd: {
        title: 'US Dollar',
        name: 'USD',
        symbol: '$',
    },
    gbp: {
        title: 'British Pound',
        name: 'GBP',
        symbol: '£',
    },
    eur: {
        title: 'Euro',
        name: 'EUR',
        symbol: '€',
    },
    rub: {
        title: 'Ruble',
        name: 'RUB',
        symbol: '₽',
    },
}

export const currency2Symbol: Record<string, string> = {
    USD: '$',
    EUR: '€',
    GBP: '£',
    RUB: '₽',
}

export const currenciesList = ['GBP', 'EUR', 'USD', 'RUB']

export default currencies
