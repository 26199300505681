import { AnimatePresence } from 'framer-motion'
import { LocationSensorState } from 'react-use/lib/useLocation'
import { ROUTE } from 'src/constants/routes'
import { Route, Routes } from 'react-router-dom'
import HistoryCardTransfersTab from 'components/tabs/HistoryCardTransfersTab'
import MobileHomePage from 'components/pages/MobileHomePage'
import MobilePinCodePage from 'components/pages/MobilePinCodePage'
import MobileProfilePage from 'components/pages/MobileProfilePage'
import NotFoundPage from 'components/pages/NotFoundPage'
import PrivateCardTemplate from 'components/templates/PrivateCardTemplate'
import PrivateTemplate from 'components/templates/PrivateTemplate'
import React, { FC, Suspense, lazy } from 'react'
import ScreenLoader from 'components/atoms/ScreenLoader'
import SettingsMainAccountTab from 'components/tabs/settings/SettingsMainAccountTab'

const PrivateVerificationTemplate = lazy(() => import('components/templates/PrivateVerificationTemplate'))
const DashboardPage = lazy(() => import('components/pages/DashboardPage'))
const AccountTopUpPage = lazy(() => import('components/pages/AccountTopUpPage'))
const AccountPage = lazy(() => import('components/pages/AccountPage'))
const CardPage = lazy(() => import('components/pages/CardPage'))
const RequireAuth = lazy(() => import('components/RequireAuth'))
const HistoryMassTransfersTab = lazy(() => import('components/tabs/HistoryMassTransferTab'))
const HistorySingleTransfersTab = lazy(() => import('components/tabs/HistorySingleTransfersTab'))
const SecurityAuthTab = lazy(() => import('components/tabs/security/SecurityAuthTab'))
const SettingsPasswordsTab = lazy(() => import('components/tabs/settings/SettingsPasswordTab'))
const SettingsPersonalDataTab = lazy(() => import('components/tabs/settings/SettingsPersonalDataTab'))
const CardTransferTab = lazy(() => import('components/tabs/transfers/CardTransferTab'))
const InternalTransferTab = lazy(() => import('components/tabs/transfers/InternalTransferTab'))
const CryptoTransferTab = lazy(() => import('components/tabs/transfers/CryptoTransferTab'))
const TransferBankTab = lazy(() => import('components/tabs/transfers/TransferBankTab'))
const TransferMassTab = lazy(() => import('components/tabs/transfers/TransferMassTab'))
const WalletTransferTab = lazy(() => import('components/tabs/transfers/WalletTransferTab'))
const VerificationPage = lazy(() => import('./components/pages/VerificationPage'))
const CardIssuePage = lazy(() => import('./components/pages/CardIssuePage'))
const RegistrationPage = lazy(() => import('./components/pages/RegistrationPage'))
const AgentsDashboardPage = lazy(() => import('components/pages/agents/AgentsDashboardPage'))
const SettingsPage = lazy(() => import('./components/pages/SettingsPage'))
const SecurityPage = lazy(() => import('./components/pages/SecurityPage'))
const SupportPage = lazy(() => import('./components/pages/SupportPage'))
const TicketPage = lazy(() => import('./components/pages/TicketPage'))
const NoticePage = lazy(() => import('./components/pages/NoticePage'))
const ForgotPasswordPage = lazy(() => import('./components/pages/ForgotPasswordPage'))
const ConfirmEmailPage = lazy(() => import('./components/pages/ConfirmEmailPage'))
const TransfersPage = lazy(() => import('./components/pages/TransfersPage'))
const HistoryOperationsPage = lazy(() => import('components/pages/HistoryPage'))
const ExchangePage = lazy(() => import('./components/pages/ExchangePage'))
const TemplatesDraftsPage = lazy(() => import('components/pages/TemplatesDraftsPage'))
const FeesPage = lazy(() => import('./components/pages/FeesPage'))
const LoginPage = lazy(() => import('./components/pages/LoginPage'))
const LogoutPage = lazy(() => import('./components/pages/LogoutPage'))
const SecurityLoginHistoryTab = lazy(() => import('./components/tabs/security/SecurityLoginHistoryTab'))
const SecurityIpWhiteListTab = lazy(() => import('./components/tabs/security/SecurityIpWhiteListTab'))
const SecurityAPITokensTab = lazy(() => import('./components/tabs/security/SecurityAPITokens'))
const SecurityNoticesTab = lazy(() => import('./components/tabs/security/SecurityNoticesTab'))

{
    /* eslint-disable react/no-children-prop */
}

export const AppRoutes: FC<{ location: Location | LocationSensorState }> = ({ location }) => {
    return (
        <AnimatePresence mode="wait">
            <Suspense fallback={<ScreenLoader />}>
                <Routes location={location}>
                    <Route path="v" element={<PrivateVerificationTemplate />}>
                        <Route path={ROUTE.VERIFICATION} element={<RequireAuth children={<VerificationPage />} />} />
                    </Route>
                    <Route path="c" element={<PrivateCardTemplate />}>
                        <Route path={ROUTE.CARDS_ISSUE} element={<RequireAuth children={<CardIssuePage />} />} />
                    </Route>
                    <Route path="s" element={<PrivateTemplate />}>
                        <Route path={ROUTE.MOBILE_PROFILE} element={<RequireAuth children={<MobileProfilePage />} />} />
                        <Route path={ROUTE.TOP_UP} element={<RequireAuth children={<AccountTopUpPage />} />} />
                        <Route path={ROUTE.FEES} element={<RequireAuth children={<FeesPage />} />} />
                        <Route path={ROUTE.HOME} element={<RequireAuth children={<DashboardPage />} />} />
                        <Route path={`${ROUTE.HOME}/:id`} element={<RequireAuth children={<AccountPage />} />} />
                        <Route path={`${ROUTE.CARDS}/:id/*`} element={<RequireAuth children={<CardPage />} />} />
                        <Route path={`${ROUTE.TRANSFERS}/*`} element={<RequireAuth children={<TransfersPage />} />}>
                            <Route path="internal" element={<InternalTransferTab />} />
                            <Route path="bank" element={<TransferBankTab />} />
                            <Route path="card" element={<CardTransferTab />} />
                            <Route path="crypto" element={<CryptoTransferTab />} />
                            <Route path="wallet" element={<WalletTransferTab />} />
                            <Route path="mass" element={<TransferMassTab />} />
                        </Route>
                        <Route
                            path={`${ROUTE.HISTORY_OPERATIONS}/*`}
                            element={<RequireAuth children={<HistoryOperationsPage />} />}
                        >
                            <Route>
                                <Route path="transfers" element={<HistorySingleTransfersTab />} />
                                <Route path="mass" element={<HistoryMassTransfersTab />} />
                                <Route path="cards" element={<HistoryCardTransfersTab />} />
                            </Route>
                        </Route>
                        <Route path={`${ROUTE.SETTINGS}/*`} element={<RequireAuth children={<SettingsPage />} />}>
                            <Route>
                                <Route path="personal-data" element={<SettingsPersonalDataTab />} />
                                <Route path="password" element={<SettingsPasswordsTab />} />
                                <Route path="main-account" element={<SettingsMainAccountTab />} />
                            </Route>
                        </Route>
                        <Route path={`${ROUTE.SECURITY}/*`} element={<RequireAuth children={<SecurityPage />} />}>
                            <Route>
                                <Route path="auth" element={<SecurityAuthTab />} />
                                <Route path="login-history" element={<SecurityLoginHistoryTab />} />
                                <Route path="ip-white-list" element={<SecurityIpWhiteListTab />} />
                                <Route path="tokens" element={<SecurityAPITokensTab />} />
                                <Route path="notices" element={<SecurityNoticesTab />} />
                            </Route>
                        </Route>
                        <Route path={ROUTE.EXCHANGE} element={<RequireAuth children={<ExchangePage />} />} />
                        <Route
                            path={ROUTE.TEMPLATES}
                            element={<RequireAuth children={<TemplatesDraftsPage view="template" />} />}
                        />
                        <Route
                            path={ROUTE.DRAFTS}
                            element={<RequireAuth children={<TemplatesDraftsPage view="draft" />} />}
                        />
                        <Route path={ROUTE.SUPPORT} element={<RequireAuth children={<SupportPage />} />} />
                        <Route
                            path={`${ROUTE.SUPPORT}/:ticketId`}
                            element={<RequireAuth children={<TicketPage />} />}
                        />
                        <Route path={ROUTE.NOTICES} element={<RequireAuth children={<NoticePage />} />} />
                        {/*  AGENTS  */}
                        <Route
                            path={ROUTE.AGENTS_DASHBOARD}
                            element={<RequireAuth children={<AgentsDashboardPage />} />}
                        />
                    </Route>
                    <Route path={ROUTE.SIGN_OUT} element={<LogoutPage />} />
                    <Route path={ROUTE.MOBILE_HOME} element={<MobileHomePage />} />
                    <Route path={ROUTE.MOBILE_PIN_CODE} element={<MobilePinCodePage />} />
                    <Route path={ROUTE.SIGN_IN} element={<LoginPage />} />
                    <Route path={ROUTE.SIGN_UP} element={<RegistrationPage />} />
                    <Route path={ROUTE.EMAIL_VERIFICATION} element={<ConfirmEmailPage />} />
                    <Route path={ROUTE.FORGOT} element={<ForgotPasswordPage />} />
                    <Route path="/" element={<RequireAuth navigateToHome />} />
                    <Route path="/index.html" element={<RequireAuth navigateToHome />} />
                    <Route path="*" element={<NotFoundPage />} />
                </Routes>
            </Suspense>
        </AnimatePresence>
    )
}
