import * as React from 'react'
import { BankCard } from 'src/interfaces/BankCard'
import { FormattedMessage, useIntl } from 'react-intl'
import { ROUTE } from 'constants/routes'
import { TransferType } from 'src/modules/operations/transfers/types'
import Link from 'containers/Link'
import Skeleton from 'react-loading-skeleton'
import Spoiler from 'components/molecules/Spoiler'
import styled from 'astroturf/react'
import useCardListQuery from 'src/queries/useCardListQuery'

interface Props {
    cardid: number
    type?: TransferType
    onlyCardName?: boolean
    withLink?: boolean
}

const CardRequisites: React.FC<Props> = ({ cardid, type, withLink, onlyCardName }) => {
    const cards = useCardListQuery({ enabled: !!onlyCardName })
    const card = cards.isSuccess && (cards.data?.list.find((item) => item.cardid === cardid) as BankCard)
    const intl = useIntl()

    const onToggle = () => {
        if (!cards.isSuccess) {
            cards.refetch()
        }
    }

    return onlyCardName ? (
        cards.isLoading ? (
            <Skeleton width={100} />
        ) : withLink ? (
            <Link to={`${ROUTE.CARDS}/${cardid}`}>{card?.cardname}</Link>
        ) : (
            `${card?.cardname} (ID: ${cardid})`
        )
    ) : (
        <Spoiler
            view="text"
            onToggle={onToggle}
            title={
                <Link>
                    Practika{' '}
                    {type === 'TopUp1'
                        ? `(${intl.formatMessage({ defaultMessage: 'Card top up' })})`
                        : `(${intl.formatMessage({ defaultMessage: 'Card issue' })})`}
                </Link>
            }
        >
            <Requisites>
                <b>
                    <FormattedMessage defaultMessage="Card ID" />:
                </b>{' '}
                {cardid}
                <br />
                <b>
                    <FormattedMessage defaultMessage="Card name" />:
                </b>{' '}
                {cards.isLoading ? (
                    <Skeleton width={100} />
                ) : (
                    <Link to={`${ROUTE.CARDS}/${cardid}`}>{card?.cardname}</Link>
                )}
            </Requisites>
        </Spoiler>
    )
}

const Requisites = styled.div`
    font-size: 13px;
    line-height: 170%;

    b {
        font-weight: 600;
    }
`

export default CardRequisites
