import * as React from 'react'
import * as yup from 'yup'
import { AuthPageTitle, AuthStepInfo } from '../../templates/AuthTemplate'
import { Button, Link, Space } from 'capricorn-ui'
import { ForgotPasswordChildProps } from '../ForgotPasswordPage'
import { FormattedMessage, useIntl } from 'react-intl'
import { ROUTE } from 'src/constants/routes'
import { Tip } from 'capricorn-ui'
import { useLocation, useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { useWindowSize } from 'react-use'
import AdaptiveButtonGroup from 'components/atoms/AdaptiveButtonGroup'
import ControlledPhoneInput from '../../../containers/ControlledPhoneInput'
import styled from 'astroturf/react'
import useFormEnhanced from 'src/hooks/useFormEnhanced'

const schema = () =>
    yup.object().shape({
        phone: yup.string().min(12).required(),
    })

const Step1: React.FC<ForgotPasswordChildProps & { sendOTP: (phone: string) => void }> = ({ state, sendOTP }) => {
    const navigate = useNavigate()
    const intl = useIntl()
    const windowWidth = useWindowSize().width
    const location = useLocation()

    const { control, handleSubmit } = useFormEnhanced({
        schema,
        defaultValues: state,
    })

    const [loading, setLoading] = useState(false)

    const toAuth = () => {
        if (ENV === 'ios') {
            navigate(ROUTE.SIGN_IN, { state: { backgroundLocation: location } })
        } else {
            navigate(ROUTE.SIGN_IN)
        }
    }

    const onSubmit = async ({ phone }: { phone: string }) => {
        setLoading(true)
        await sendOTP(phone)
        setLoading(false)
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Space size={windowWidth < 500 ? 24 : 40} column>
                <AuthPageTitle>
                    <FormattedMessage defaultMessage="Вы забыли пароль?" />
                </AuthPageTitle>
                <AuthStepInfo>
                    <FormattedMessage defaultMessage="Введите телефон и мы вышлем на него код восстановления." />
                </AuthStepInfo>
                <ControlledPhoneInput
                    name="phone"
                    label={intl.formatMessage({ defaultMessage: 'Номер телефона' })}
                    control={control}
                    maxWidth={600}
                    autoFocus={windowWidth > 600}
                    inputMode="tel"
                />
                <div>
                    <Tip
                        closeOnBlur
                        title={
                            <Link bold>
                                <FormattedMessage defaultMessage="Forgot your phone number" />
                            </Link>
                        }
                    >
                        <TipContent>
                            <FormattedMessage
                                defaultMessage="Свяжитесь с тех. поддержкой <b>support@practika.net</b>"
                                values={{ b: (word: string) => <b>{word}</b> }}
                            />
                        </TipContent>
                    </Tip>
                </div>
                <AdaptiveButtonGroup>
                    <Button skin="border" onClick={toAuth}>
                        <FormattedMessage defaultMessage="Back" />
                    </Button>
                    <Button type="submit" loading={loading}>
                        <FormattedMessage defaultMessage="Get code" />
                    </Button>
                </AdaptiveButtonGroup>
            </Space>
        </form>
    )
}

const TipContent = styled.div`
    font-size: 12px;

    ol {
        margin: 10px 0 0 0;
        padding: 0 0 0 15px;
    }
`

export default Step1
