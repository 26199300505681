import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { ROUTE } from 'src/constants/routes'
import { useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { useOutsideClick } from 'src/hooks'
import { useStore } from 'src/store'
import { useWindowSize } from 'react-use'
import Delimiter from 'components/atoms/Delimiter'
import Link from 'src/containers/Link'
import LogoSvg from 'src/assets/icons/logo-no-slogan.svg'
import SidebarNav from '../organisms/SidebarNav'
import ThemeSwitcher from 'components/molecules/ThemeSwitcher'
import styled from 'astroturf/react'

const Sidebar: React.FC = () => {
    const { auth } = useStore()
    const { pathname } = useLocation()
    const windowWidth = useWindowSize().width
    const { isOpenedMenu, setIsOpenedMenu } = useStore().common
    const ref = useRef(null)

    useEffect(() => {
        setIsOpenedMenu(false)
    }, [pathname])

    useOutsideClick(ref, (event) => {
        if (event?.target?.parentElement?.id !== 'burger-menu-icon') {
            setIsOpenedMenu(false)
        }
    })

    return windowWidth > 600 ? (
        <Container opened={isOpenedMenu} ref={ref}>
            {windowWidth > 768 && (
                <LinkStyled to={ROUTE.HOME}>
                    <LogoSvgStyled />
                </LinkStyled>
            )}
            <div style={{ width: '100%' }}>
                <SidebarNavStyled />
                {auth.user?.role === 'agent' && (
                    <>
                        <Delimiter />
                        <AgentsLink to={ROUTE.AGENTS_DASHBOARD}>
                            ← {'  '}
                            <FormattedMessage defaultMessage="Agents dashboard" />
                        </AgentsLink>
                    </>
                )}
            </div>
            {windowWidth < 768 && (
                <>
                    <div style={{ flex: 1 }} />
                    <ThemeSwitcherStyled />
                </>
            )}
            {/*<SwitchLangStyled*/}
            {/*    dropdownProps={{*/}
            {/*        position: windowWidth > 768 ? 'bottom-end' : 'top',*/}
            {/*    }}*/}
            {/*/>*/}
        </Container>
    ) : (
        <SidebarNavStyled />
    )
}

const AgentsLink = styled(Link)`
    margin: 20px 23px;
    white-space: pre-wrap;
`

const ThemeSwitcherStyled = styled(ThemeSwitcher)`
    margin-bottom: 20px;
`

const Container = styled.div<{ opened?: boolean }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 240px;
    min-width: 240px;
    max-height: 100vh;
    overflow: auto;
    box-sizing: border-box;
    padding: 16px 0 32px;
    background: var(--bg-color-1);
    box-shadow: 10px 0 -2px 5px rgba(0, 0, 0, 0.1);

    @media (max-width: 768px) {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        z-index: 10;
        padding: 80px 0 40px;
        width: 280px;
        min-width: 280px;
        transform: translateX(-100%);
        opacity: 0;
        transition:
            0.25s transform,
            0.25s opacity;

        &.opened {
            transform: translateX(0);
            opacity: 1;
        }
    }
`

const SidebarNavStyled = styled(SidebarNav)`
    width: 100%;
    box-sizing: border-box;
    flex: 1;
`

const LogoSvgStyled = styled(LogoSvg)`
    width: 125px;
    margin: 0 20px 64px 0;
    color: var(--main-text-color);
`

const LinkStyled = styled(Link)`
    &:hover {
        :global(#logo-point) {
            transform: translateX(10px);
        }
    }
`

export default Sidebar
