import * as React from 'react'
import { Surface } from 'capricorn-ui'
import ChooseMainAccount from 'components/organisms/ChooseMainAccoint'
import styled from 'astroturf/react'

const SettingsMainAccountTab: React.FC = () => {
    return (
        <SurfaceStyled noSurfacing>
            <ChooseMainAccount />
        </SurfaceStyled>
    )
}

const SurfaceStyled = styled(Surface)`
    padding: 40px;

    @media (max-width: 600px) {
        padding: 20px 0 0 0;
        background: transparent;
        box-shadow: none;
    }
`

export default SettingsMainAccountTab
