import { ControllerProps } from 'containers/types'
import { FieldValues } from 'react-hook-form/dist/types'
import { useController } from 'react-hook-form'
import { useWindowSize } from 'react-use'
import FormatService from 'services/FormatService'
import React from 'react'
import TextInput, { TextInputProps } from 'capricorn-ui/esm/TextInput'

type CapricornProps = Omit<TextInputProps, 'name' | 'onChange' | 'value' | 'error' | 'label'>

interface Props<FieldsType extends FieldValues> extends ControllerProps<FieldsType>, CapricornProps {
    label?: string
    uppercase?: boolean
    tipToPlaceholderOnMobile?: boolean
    noSpaces?: boolean
}

const ControlledTextInput = <FieldsType extends FieldValues>({
    control,
    tipToPlaceholderOnMobile,
    fieldTip,
    placeholder,
    name,
    view,
    uppercase,
    textInputStyles,
    rightLabel,
    noSpaces,
    type,
    ...rest
}: Props<FieldsType>) => {
    const windowWidth = useWindowSize().width
    const { field, fieldState } = useController({ name, control })

    const handleChange = (value: string) => {
        const newValue = noSpaces ? value.replace(/\s/g, '') : value

        if (type === 'money') {
            if (/^\d*\.?\d*$/.test(value) && value !== '00') {
                field.onChange(newValue)
            }
        } else {
            field.onChange(newValue)
        }

        if (uppercase) {
            setTimeout(() => {
                field.onChange(newValue.toUpperCase())
            }, 50)
        }
    }

    const onBlur = () => {
        field.onBlur()
        if (type === 'money') {
            field.onChange(FormatService.money(field.value))
        }
    }

    const onFocus = () => {
        if (type === 'money') {
            field.onChange(field.value && FormatService.money2Number(field.value))
        }
    }

    return (
        <TextInput
            type={type === 'money' ? 'text' : type}
            onBlur={onBlur}
            onFocus={onFocus}
            view={windowWidth <= 600 && type !== 'code' ? 'underscore' : view}
            staticLabel={windowWidth <= 600}
            error={fieldState.error?.message}
            onChange={handleChange}
            value={field.value}
            data-cy={name}
            fullWidth={type !== 'code' && !rest.maxWidth}
            style={{ flex: 1, minWidth: 300 }}
            fieldTip={windowWidth <= 600 && tipToPlaceholderOnMobile ? undefined : fieldTip}
            placeholder={
                windowWidth <= 600 && tipToPlaceholderOnMobile
                    ? fieldTip
                    : placeholder
                      ? placeholder
                      : windowWidth <= 600 && type !== 'code'
                        ? `Enter ${rest.label?.replace('*', '').toLowerCase()}`
                        : undefined
            }
            textInputStyles={{
                ...textInputStyles,
                fontSize: textInputStyles?.fontSize || (windowWidth <= 600 ? 17 : undefined),
            }}
            rightLabel={windowWidth <= 600 ? undefined : rightLabel}
            {...rest}
        />
    )
}

export default ControlledTextInput
