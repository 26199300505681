import { FormattedMessage, useIntl } from 'react-intl'
import { ROUTE } from 'src/constants/routes'
import { useNavigate } from 'react-router-dom'
import { useOutsideClick } from 'src/hooks'
import { useStore } from 'src/store'
import { useWindowSize } from 'react-use'
import AdaptiveDropdown from 'containers/AdaptiveDropdown'
import AvatarSvg from 'src/assets/icons/avatar.svg'
import ChevronDownSvg from 'src/assets/icons/chevron-down.svg'
import Copy from 'components/atoms/Copy'
import FormatService from 'services/FormatService'
import IconBlock from '../templates/IconBlock'
import Link from 'containers/Link'
import MoneySvg from 'src/assets/icons/money.svg'
import PowerSvg from 'src/assets/icons/power.svg'
import ProcessSvg from 'src/assets/icons/clock.svg'
import React, { useRef, useState } from 'react'
import RoundIcon from 'components/atoms/RoundIcon'
import SecuritySvg from 'src/assets/icons/security.svg'
import SettingsSvg from 'src/assets/icons/settings.svg'
import Skeleton from 'react-loading-skeleton'
import TickBoldSvg from 'src/assets/icons/tick-bold.svg'
import TickSvg from 'src/assets/icons/tick-thin.svg'
import currencies from 'constants/currencies'
import styled from 'astroturf/react'
import useAccountListQuery from 'src/queries/useAccountListQuery'

const UserDropDown: React.FC = () => {
    const windowWidth = useWindowSize().width
    const intl = useIntl()
    const navigate = useNavigate()
    const [active, setActive] = useState(false)
    const accounts = useAccountListQuery({
        enabled: active,
    })
    const { user } = useStore().auth

    const ref = useRef(null)

    const toggleContent = () => {
        setActive((active) => !active)
    }

    useOutsideClick(ref, () => windowWidth > 600 && setActive(false))

    const userEmail = user?.email || user?.phone

    const navOnClick = () => {
        setActive(false)
    }

    return (
        <Container active={active} ref={ref}>
            <ButtonContent onClick={toggleContent}>
                <AvatarIconBlock>
                    <AvatarSvg width={20} />
                </AvatarIconBlock>
                <Block>
                    <UserName>
                        {user?.applicationInfo?.companyOrUserName || user?.login}
                        <ChevronDownStyledSvg width={11} />
                    </UserName>
                    <UserEmail>{userEmail}</UserEmail>
                </Block>
            </ButtonContent>
            <DropDownStyled
                onChange={setActive}
                position="bottom-end"
                noArrow
                maxHeight="auto"
                active={active}
                noPadding
            >
                <DropDownContent>
                    <DropDownIcon onClick={() => navigate(ROUTE.SETTINGS_PERSONAL_DATA)}>
                        <AvatarSvg width={28} />
                    </DropDownIcon>
                    <UserName onClick={() => navigate(ROUTE.SETTINGS_PERSONAL_DATA)} center>
                        {user?.applicationInfo?.companyOrUserName || user?.login}
                        {user?.verificationStatus === 'verified' && (
                            <VerifiedLabel>
                                <TickBoldSvg width={8} />
                            </VerifiedLabel>
                        )}
                    </UserName>
                    <UserEmail>{userEmail}</UserEmail>
                    <UserAccounts>
                        {accounts.isLoading && <Skeleton count={3} height={24} />}
                        {accounts.data?.map((account) => (
                            <AccountItem key={account.id}>
                                <AccountCurrency size={22}>
                                    {currencies[account.currency.toLowerCase()]?.symbol}
                                </AccountCurrency>
                                <AmountValue>
                                    {FormatService.money(account.amount).split('.')[0]}.
                                    <AmountValueDecimal>
                                        {FormatService.money(account.amount).split('.')[1]}
                                    </AmountValueDecimal>
                                    <AmountCurrency>{currencies[account.currency.toLowerCase()]?.name}</AmountCurrency>
                                </AmountValue>
                                {account.ibanStatus === 'exists' ? (
                                    <IBAN isActive title="Copy">
                                        <Copy
                                            successCopiedText={
                                                <Copied>{intl.formatMessage({ defaultMessage: 'Copied' })}</Copied>
                                            }
                                            textToCopy={account.ibans[account.ibans.length - 1].address}
                                            noIcon
                                        >
                                            <TickSvg width={9} /> IBAN
                                        </Copy>
                                    </IBAN>
                                ) : (
                                    account.ibanStatus === 'requested' && (
                                        <IBAN noIcon title="In process">
                                            <ProcessSvg width={9} /> IBAN
                                        </IBAN>
                                    )
                                )}
                            </AccountItem>
                        ))}
                    </UserAccounts>
                    <Nav onClick={navOnClick}>
                        <li>
                            <NavLink to={ROUTE.SETTINGS_PERSONAL_DATA}>
                                <SettingsSvg width={20} height={20} />
                                <FormattedMessage defaultMessage="Settings" />
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to={ROUTE.SECURITY_AUTH}>
                                <SecuritySvg width={20} height={20} />
                                <FormattedMessage defaultMessage="Security" />
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to={`${ROUTE.FEES}?defaultTab=business`}>
                                <MoneySvg width={20} height={20} />
                                <FormattedMessage defaultMessage="Fees" />
                            </NavLink>
                        </li>
                        <LogoutListItem>
                            <NavLink to={ROUTE.SIGN_OUT}>
                                <PowerSvg width={20} height={20} />
                                <FormattedMessage defaultMessage="Sign out" />
                            </NavLink>
                        </LogoutListItem>
                    </Nav>
                </DropDownContent>
            </DropDownStyled>
        </Container>
    )
}

const Copied = styled.span`
    font-size: 12px;
    color: var(--main-text-color);
`

const ChevronDownStyledSvg = styled(ChevronDownSvg)``

const VerifiedLabel = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: -19px;
    margin-left: 6px;
    width: 13px;
    height: 13px;
    background: var(--color-primary);
    border-radius: 100%;

    svg {
        color: #fff;
    }
`

const IBAN = styled.div<{ isActive?: boolean }>`
    display: flex;
    align-items: center;
    font-size: 8px;
    font-weight: 600;
    color: var(--blue-to-white);
    border: 1px solid var(--bg-color-6);
    border-radius: 8px;
    padding: 3px 5px;

    &.isActive {
        cursor: pointer;

        svg {
            color: #49ca1c;
        }
    }

    svg {
        margin-right: 3px;
        color: var(--blue-to-white);
    }
`

const DropDownContent = styled.div`
    padding: 30px 25px 30px 30px;

    @media (max-width: 500px) {
        padding: 20px;
    }
`

const UserAccounts = styled.div`
    padding: 23px 0 28px;
    border-bottom: 1px solid var(--light-bg-color);
`

const AccountItem = styled.div`
    display: flex;
    align-items: center;
    margin-top: 9px;
    line-height: 1;
`

const AccountCurrency = styled(RoundIcon)`
    font-size: 12px;
    margin-right: 10px;
`

const NavLink = styled(Link)`
    svg {
        height: 15px;
    }
`

const Block = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 10px;
`

const UserName = styled.div<{ center?: boolean }>`
    display: flex;
    align-items: center;
    margin-bottom: 3px;
    line-height: 1;
    font-size: 15px;
    font-weight: 600;
    transition: 0.25s color;
    cursor: pointer;

    &.center {
        justify-content: center;
    }

    ${ChevronDownStyledSvg} {
        margin-left: 10px;
        color: #2281ff;
        transform-origin: center;
        transform: scaleY(1);
        transition: 0.25s transform;
    }

    @media (max-width: 500px) {
        justify-content: flex-start !important;
        font-size: 20px;
    }
`

const UserEmail = styled.div`
    line-height: 1;
    font-size: 11px;
    text-align: center;
    color: var(--main-second-text-color);

    @media (max-width: 500px) {
        text-align: left;
        margin-top: 7px;
        font-size: 14px;
    }
`

const DropDownStyled = styled(AdaptiveDropdown)`
    width: 267px;
    margin-left: -40px;

    ${UserName} {
        font-size: 16px;
    }

    ${UserEmail} {
        margin-top: 8px;
        font-size: 13px;
    }

    @media (max-width: 768px) {
        width: 100%;
        margin-top: -15px;
        margin-left: 0;
    }
`

const Container = styled.div<{ active?: boolean }>`
    position: relative;
    z-index: 4;

    &.active {
        ${UserName} ${ChevronDownStyledSvg} {
            transform: scaleY(-1);
        }
    }

    @media (max-width: 768px) {
        position: static;
    }
`

const Nav = styled.ul`
    list-style: none;
    padding: 0;
    margin: 20px 0 0 0;
    font-size: 14px;

    li {
        margin: 20px 0;

        &:last-child {
            margin-bottom: 0 !important;
        }

        a {
            color: var(--main-text-color);
        }

        &:hover {
            color: #2281ff !important;

            a {
                color: #2281ff !important;
            }
        }
    }

    @media (max-width: 600px) {
        font-size: 16px;
    }
`

const DropDownIcon = styled(IconBlock)`
    width: 66px;
    height: 66px;
    margin: 0 auto 20px;
    cursor: pointer;

    @media (max-width: 500px) {
        display: none;
    }
`

const LogoutListItem = styled.li`
    border-top: 1px solid var(--light-bg-color);
    margin: 25px 0 !important;
    padding-top: 18px;

    a {
        color: #ff5c21 !important;
    }

    &:hover a {
        color: #ff5c21 !important;
    }
`

const AvatarIconBlock = styled(IconBlock)`
    @media (max-width: 500px) {
        width: 22px;
        height: 22px;
        background: none !important;
        color: #2281ff !important;

        svg {
            width: 100%;
        }
    }
`

const ButtonContent = styled.div`
    display: flex;
    cursor: pointer;
    user-select: none;

    &:hover {
        ${UserName} {
            color: #2281ff;
        }

        ${AvatarIconBlock} {
            background: #2281ff;
            color: #fff;
        }
    }

    @media (max-width: 900px) {
        ${Block} {
            display: none;
        }
    }
`

const AmountValue = styled.div`
    font-size: 12px;
    font-weight: bold;
    flex: 1;
`

const AmountValueDecimal = styled.span`
    margin-left: 2px;
    font-weight: 400;
`

const AmountCurrency = styled.span`
    margin-left: 4px;
    font-weight: 300;
    color: var(--second-text-color);
`

export default UserDropDown
