import * as React from 'react'
import { HTMLProps } from 'react'
import styled from 'astroturf/react'

const IconBlock: React.FC<HTMLProps<HTMLDivElement>> = ({ children, ...rest }) => (
    <Container {...rest}>{children}</Container>
)

const Container = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background: var(--bg-color-2);
    color: var(--blue-to-white);
    transition:
        0.25s background,
        0.25s color;

    &:hover {
        background: #2281ff;
        color: #fff;
    }
`

export default IconBlock
